//css
import "./Login.css";
//components
import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Loader from "../common/Loader";
import { loginUser } from "../../service/authService";
import Image from "react-bootstrap/Image";
//images
import showPassword from "../../assets/images/showPassword.png";
import dontShowPassword from "../../assets/images/dontShowPassword.png";
import sprayzappLogo from "../../assets/images/sparyzapp-logo-dark.png";

function Login(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrorMsg] = useState("");
  const [passwordType, setPasswordType] = useState("password");

  async function initiateLogin() {
    setLoading(true);
    let resultSet = null;
    var userData = { userName: username, password: password };
    var response = await loginUser(userData);
    setLoading(false);
    if (response.status === 200) {
      resultSet = response.data.response;
      if (resultSet.roleCode === "SADM" || resultSet.roleCode === "ADM") {
        props.onLogin(resultSet);
      }
      else {
        setErrorMsg("You do not have permission to access the admin app.");
      }
    } else {
      setErrorMsg("Username or password incorrect.");
    }
  }

  const isEmptyString = (strValue) => {
    if (!strValue.trim().length) {
      return false;
    } else {
      return true;
    }
  };

  async function getCredentials(e) {
    e.preventDefault();
    var flag = true;
    // if (isEmptyString(username) && isEmptyString(password)) {
    //   flag = true;
    // }
    if (!isEmptyString(username)) {
      flag=false;
      setUsernameError(true);
    }
    if (!isEmptyString(password)) {
      flag=false;
      setPasswordError(true);
    }
    if (flag) {
      initiateLogin();
    }
    else{
      setErrorMsg("Please enter valid credentials.");
    }
  }

  return (
    <div className="background-image-container">
      <div className="login_window">
        <div className="login-head">
          <div className="login-heading">
            <img
              alt="demo"
              src={sprayzappLogo}
              className="center-cropped-image"
            />
        </div>
        </div>
        <div className="login-form">
          <Form.Label className="login-headline">Username</Form.Label>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Username"
              id="username"
              className="input_item"
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
                setUsernameError(false);
                setErrorMsg("");
              }}
              style={{ borderColor: usernameError ? "red" : "" }}
            />
          </InputGroup>
          <Form.Label className="login-headline">Password</Form.Label>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Password"
              type={passwordType}
              className="input_item"
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
                setPasswordError(false);
                setErrorMsg("");
              }}
              style={{ borderColor: passwordError ? "red" : "" }}
            />
            <Image
              className="password-visible-btn"
              src={passwordType === "" ? showPassword : dontShowPassword}
              height="60%"
              style={{ cursor: "pointer" }}
              onClick={(e) =>
                passwordType === ""
                  ? setPasswordType("password")
                  : setPasswordType("")
              }
            ></Image>
          </InputGroup>
          {errormsg === "" ? (
            ""
          ) : (
            <div className="credential_message" id="credential_error">
              {errormsg}
            </div>
          )}
          <a href="/forgotpassword" className="forgot-password-label">
            Forgot your password?
          </a>
          <Button
            variant="primary"
            className="login-btn"
            size="lg"
            active
            onClick={getCredentials}
          >
            {loading ? (
              <Loader
                loading={loading}
                loader={"loader  loader-login"}
                loaderColor={"#ffffff"}
                size={4}
              />
            ) : (
              <div className="login-btn-label">LOG IN</div>
            )}
          </Button>{" "}
        </div>
      </div>
    </div>
  );
}

export default Login;
