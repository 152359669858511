import axios from "axios";
import { ORG_ID } from "../components/common/constant";
import { authFetch } from "./authProvider";

const SERVICE_URL = "account-service/v1/organizations/" + ORG_ID;
const SERVICE_URL_V2 = "account-service-new/v2/organizations/" + ORG_ID;

export const addAccount = async (data) => {
  let url = SERVICE_URL + "/account";
  return await authFetch("post", url, data)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const addBasicAccount = async (data) => {
  let url = SERVICE_URL + "/accounts/0";
  return await authFetch("patch", url, data)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};



export const getAccount = async (accountId) => {
  let url = SERVICE_URL + "/accounts/" + accountId;
  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const getAllAccounts = async () => {
  let url = SERVICE_URL + "/accounts";
  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const getAllAccountsSummary = async () => {
  let url = SERVICE_URL + "/accounts-summary";
  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const getAllAccountsSummaryV2 = async () => {
  let url = SERVICE_URL_V2 + "/accounts/summary";
  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const updateAccount = async (data) => {
  console.log("data update :",data);
  let url = SERVICE_URL + "/accounts/" + data.accountId;
  return await authFetch("patch", url, data)
    .then(function(response) {
      console.log(response);
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const deleteAccounts = async (accounts) => {
  let url = SERVICE_URL + "/accounts";
  let idsForDelete = accounts;
  console.log("delete", url, { ids: idsForDelete });
  return await authFetch("delete", url, { ids: idsForDelete })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const getFleetNumbers = async (accountId) => {
  let url = SERVICE_URL + "/accounts/" + accountId + "/fleet_numbers";
  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const getAccountLocations = async (accountId) => {
  let url = SERVICE_URL_V2 + "/accounts/" + accountId + "/locations";
  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const deleteAccountLocation = async (accountId, locationNumber) => {
  let url = SERVICE_URL_V2 + "/accounts/" + accountId + "/locations/" + locationNumber;
  return await authFetch("delete", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const updateAccountLocation = async (data) => {
  let url = SERVICE_URL_V2 + "/accounts/" + data.accountId + "/locations/" + data.locationNumber;
  return await authFetch("put", url, data)
    .then(function(response) {
      console.log(response);
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const createAccountLocation = async (data) => {
  let url = SERVICE_URL_V2 + "/accounts/" + data.accountId + "/locations";
  return await authFetch("post", url, data)
    .then(function(response) {
      console.log(response);
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const getAllAccountFleets = async (accountId) => {
  let url = SERVICE_URL_V2 + "/accounts/" + accountId + "/fleets";
  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const createAccountFleet = async (accountId, data) => {
  let url = SERVICE_URL_V2 + "/accounts/" + accountId + "/fleets";
  return await authFetch("post", url, data)
    .then(function(response) {
      console.log(response);
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};
export const updateAccountFleets = async(accountId, locationNumber, fleets)=> {
  let url = SERVICE_URL_V2 + "/accounts/" + accountId + "/locations/" + locationNumber + "/fleets";
  return await authFetch("put", url, {"accountFleets":fleets})
    .then(function(response) {
      console.log(response);
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const updateAccountFleet = async (accountId, data) => {
  let url = SERVICE_URL_V2 + "/accounts/" + accountId + "/fleets/" + data.accountFleetId;
  return await authFetch("put", url, data)
    .then(function(response) {
      console.log(response);
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const deleteAccountFleet = async(accountId, accountFleetId)=>{
  let url = SERVICE_URL_V2 + "/accounts/" + accountId + "/fleets/" + accountFleetId;
  console.log(url);
  return await authFetch("delete", url)
    .then(function(response) {
      console.log(response);
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
}

export const deleteAccountFleets = async(accountId, accountFleetIds)=>{
  let url = SERVICE_URL_V2 + "/accounts/" + accountId + "/fleets";
  console.log(url);
  return await authFetch("delete", url, {"fleetIds": accountFleetIds})
    .then(function(response) {
      console.log(response);
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
}

export const getAccountWashMiscCharges = async (accountId, locationNumber) => {
  let url = SERVICE_URL_V2 + "/accounts/" + accountId + "/locations/" + locationNumber + "/wash-misc-charges";
  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });  
};

export const createAccountWashType = async (accountId, locationNumber, payload) => {
  let url = SERVICE_URL_V2 + "/accounts/" + accountId + "/locations/" + locationNumber + "/WashTypeSplInstruction";
  return await authFetch("post", url, payload)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });  
};

export const updateAccountWashType = async (accountId, locationNumber,washTypeId, payload) => {
  let url = SERVICE_URL_V2 + "/accounts/" + accountId + "/locations/" + locationNumber + "/wash-charges/" + washTypeId;
  return await authFetch("put", url, payload)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });  
};

export const deleteAccountWashTypes = async (accountId, locationNumber, payload) => {
  let url = SERVICE_URL_V2 + "/accounts/" + accountId + "/locations/" + locationNumber + "/wash-charges";
  return await authFetch("delete", url, {"washTypeIds": payload})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });  
};

export const createAccountMiscType = async (accountId, locationNumber, payload) => {
  let url = SERVICE_URL_V2 + "/accounts/" + accountId + "/locations/" + locationNumber + "/miscellaneous-charges";
  return await authFetch("post", url, payload)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });  
};

export const updateAccountMiscType = async (accountId, locationNumber,miscTypeId, payload) => {
  let url = SERVICE_URL_V2 + "/accounts/" + accountId + "/locations/" + locationNumber + "/miscellaneous-charges/" + miscTypeId;
  return await authFetch("put", url, payload)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });  
};

export const deleteAccountMiscTypes = async (accountId, locationNumber, payload) => {
  let url = SERVICE_URL_V2 + "/accounts/" + accountId + "/locations/" + locationNumber + "/miscellaneous-charges";
  return await authFetch("delete", url, {"washTypeIds": payload})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });  
};

export const uploadFleetCSVFile = async (file,acccontId, locationNumber = 1) => {
  let getSignedurlApiUrl =
  SERVICE_URL_V2 + "/accounts/" + acccontId + "/locations/" + locationNumber + "/fleet-upload-url";
  return await authFetch("get", getSignedurlApiUrl, {})
    .then(async function(response) {
      if (response.status === 200) {
        console.log("response: ",response.data.url);
        return await axios({
          method: "PUT",
          url: response.data.url,
          data: file,
          headers: { "Content-Type": "multipart/form-data" },
        });
      } else {
        console.log("Failed getSignedurlApiUrl: ",response);
        return {};
      }
    })
    .then(function(response) {
      console.log('Upload Response: ',response);
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};
