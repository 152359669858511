import jwt from "jsonwebtoken";
import { updateToken } from "./authService";

let token = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH"))
  ? JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH"))
  : null;

export const getExpirationDate = (jwtToken) => {
  if (!jwtToken) {
    return null;
  }
  var decoded = jwt.decode(jwtToken);
  return decoded.exp * 1000;
};

const isExpired = (exp) => {
  if (!exp) {
    return false;
  }

  return Date.now() > exp;
};

export const getToken = async () => {
  if (!token) {
    return null;
  }
  if (isExpired(getExpirationDate(token.accessToken))) {
    const response = await updateToken(token.refreshToken);
    let updatedToken = null;
    if (response.status === 200) {
      updatedToken = {
        accessToken: response.data.token,
        refreshToken: token.refreshToken,
      };
    } else {
      localStorage.removeItem("user");
    }
    setToken(updatedToken);
    window.location.reload();
  }

  return token.accessToken;
};

export const isLoggedIn = () => {
  return !!token;
};

let observers = [];

export const subscribe = (observer) => {
  observers.push(observer);
};

export const unsubscribe = (observer) => {
  observers = observers.filter((_observer) => _observer !== observer);
};

const notify = () => {
  const isLogged = isLoggedIn();
  observers.forEach((observer) => observer(isLogged));
};

export const setToken = (newToken) => {
  if (newToken) {
    localStorage.setItem("REACT_TOKEN_AUTH", JSON.stringify(newToken));
  } else {
    localStorage.removeItem("REACT_TOKEN_AUTH");
  }
  token = newToken;
  notify();
};
