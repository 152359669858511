import React, { Component } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../service/authProvider";

function ProtectedRoute({ children }) {
  //Save the current location they were
  // trying to go to when they were redirected.
  let location = useLocation();

  const isAuthenticated = useAuth();

  if (!isAuthenticated) {
    // Redirect them to the /login page,
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

export default ProtectedRoute;
