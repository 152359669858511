import React, { useState, useEffect } from "react";
import { Row, Button, Form, Col, Table, FormCheck, Image, InputGroup, FormControl } from "react-bootstrap";
import "./Account.css";
import "../common/Common.css";
import asc from "../../assets/images/asc.png";
import desc from "../../assets/images/desc.png";
import searchIcon from "../../assets/images/search.png";
import Pagination from "../common/Pagination";
import FleetListTableRow from "./FleetListTableRow";
import DialogBoxModal from "../modal/DialogBoxModal";
import { toast } from "react-toastify";
import { getLoaderToastSettings, getLoaderToastMessage } from "../common/ToastManager";
import { createAccountFleet, deleteAccountFleets, updateAccountFleet, updateAccountFleets, uploadFleetCSVFile } from "../../service/accountService";
import { searchObjects } from "../common/helper";
import ImportModal from "../modal/ImportModal";
import AddOrEditAccountFleetModal from "./AddOrEditAccountFleet";

function FleetList(props) {
    const [locationFleetList, setLocationFleetlist] = useState([]);
    const [fleetList, setFleetlist] = useState([]);
    const [tempFleetList, setTempFleetlist] = useState([]);
    const [isPreSelectAllChecked, setIsPreSelectAllChecked] = useState(false);
    const [paginatedFleetList, setPaginatedFleetList] = useState([]);
    const itemsPerpage = 25;
    const [itemOffset, setItemOffset] = useState(0);
    const [endOffset, setEndOffset] = useState(itemsPerpage);
    const [sortingType, setSortingType] = useState("");
    const [washTypeList, setWashtypeList] = useState([]);
    const [selectedFleet, setSelectedFleet] = useState({});
    const [selectedFleets, setSelectedFleets] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [dialogBox, setDialogBox] = useState({
        isDialogboxOpen: false,
        dialogBoxMessage: "",
        isConfirmationDialogBox: true,
        dialogBoxIcon: "delete",
        dialogboxName: "",
    });
    const toastLoader = (toastMessage) => toast.loading(toastMessage, getLoaderToastSettings());
    const toastLoaderUpdate = (id, toastMessage, type) => toast.update(id, getLoaderToastMessage(toastMessage, type));
    const [isAddorEditAccountFleetModalOpen, setIsAddorEditAccountFleetModalOpen] = useState(false);
    const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
    const [accountId , setAccountId] = useState(0);
    const [locationNumber , setLocationNumber] = useState(0);
    const [preSelectedFleets, setPreSelectedFleets] = useState([]);
    const [filterKeyword, setFilterKeyWord] = useState('');
    //import modal
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const [importModalMessage] = useState("");
    const [importModalName] = useState("Import");
    const [importModalLoading, setImportModalLoading] = useState(false);
    const [importResponseMessage, setImportResponseMessage] = useState("");
    const [responseViewer, setResponseViewer] = useState(false);
    const [icon, setIcon] = useState("");

    useEffect(() => {
        setLocationFleetlist(props.fleetList);
        setAccountId(props.accountId);
        if(props.fleetList.fleets.length > 0){
            setFleetlist(props.fleetList.fleets);
            setTempFleetlist(props.fleetList.fleets);
        }
        setLocationNumber(props.locationNumber);
    }, [props.fleetList]);

    useEffect(()=>{
        if(filterKeyword.length>0){
            filterData(filterKeyword);
        }
    },[tempFleetList]);

    useEffect(() => {
        setWashtypeList(props.washTypeList);
    }, [props.washTypeList]);
    
    function filterData(keyWord){
        const filtered = searchObjects(tempFleetList, ["accountFleetId","accountId","washTypeId"], keyWord);
        setFleetlist(filtered);
    }

    function openIsAddorEditAccountFleetModal() {
        setIsAddorEditAccountFleetModalOpen(true);
    }

    function closeIsAddorEditAccountFleetModal() {
        setIsAddorEditAccountFleetModalOpen(false);
    }

    const  handleEditFleet = async(fleet) => {
        setIsEditModeEnabled(true);
        setSelectedFleet(fleet);
        openIsAddorEditAccountFleetModal();
    }

    const handleAddFleet = async()=>{
        setIsEditModeEnabled(false);
        setSelectedFleet({});
        openIsAddorEditAccountFleetModal();
    }    

    function openImportModal() {
        setIsImportModalOpen(true);
        setResponseViewer(false);
    }

    function closeImportModal() {
        setIsImportModalOpen(false);
    }

    async function initializeUploadFile(file) {
        setImportModalLoading(true);
        var response = await uploadFleetCSVFile(file,accountId, locationNumber);
        if (response.status === 200) {
            setImportResponseMessage("File upload successfully");
            setResponseViewer(true);
            setIcon("success");
        } else {
            setImportResponseMessage("Error Occured while uploading the file");
            setResponseViewer(true);
            setIcon("failed");
        }
        setImportModalLoading(false);
    }
    
    const handleDelete = () => {
        setDialogBox({
            isConfirmationDialogBox: true,
            dialogBoxMessage: "Are you sure you want to delete selected fleets?",
            dialogBoxIcon: "delete",
            isDialogboxOpen: true,
        });
    }

    const handleSave = async () => {
        let toastId = toastLoader("Please wait...");
        const response = await updateAccountFleets(accountId, locationFleetList.locationNumber, preSelectedFleets);
        if(response.status === 200){
            toastLoaderUpdate(toastId, "Fleet updated successfully!", "success");
            setPreSelectedFleets([]);
        }
        else{
            toastLoaderUpdate(toastId, "Fleet updation failed!", "error");
        }
    };

    async function getWashTypeName(washTypeId) {
        const washType = washTypeList.find(washType => washType.id === washTypeId);
        return washType ? washType.name : null;
    }

    const refreshFleetsList = async () => {
        await props.refreshList(props.accountId);
    
        props.toastSuccess("Fleets reloaded");
    }
    
    const handleUpdate = async(fleetInfo) => {
        let toastId = toastLoader("Please wait...");
        const data = {
            accountFleetId: fleetInfo.accountFleetId,
            washTypeId: parseInt(fleetInfo.washTypeId),
            vehicleNumber: fleetInfo.vehicleNumber,
            locationNumber: fleetInfo.locationNumber,
            isPreSelected: fleetInfo.isPreSelected,
        }
        const response = await updateAccountFleet(selectedFleet.accountId ,data);
        if(response.status === 200){
            toastLoaderUpdate(toastId, "Fleet updated successfully!", "success");
            const washTypeName = await getWashTypeName(data.washTypeId);
            const updatedFleet = {
                "accountFleetId": selectedFleet.accountFleetId,
                "accountId": selectedFleet.accountId,
                "washTypeId": data.washTypeId,
                "vehicleNumber": data.vehicleNumber,
                "isPreSelected": data.isPreSelected,
                "washTypeName": washTypeName
            }
            console.log(updatedFleet);
            const updatedData = fleetList.map(item => 
                item.accountFleetId === fleetInfo.accountFleetId ? updatedFleet : item
            );
            setFleetlist(updatedData);
            closeIsAddorEditAccountFleetModal();
        }
        else{
            toastLoaderUpdate(toastId, "Fleet updation failed!", "error");
        }
    }

    const handleCreateFleet = async(fleetInfo) => {
        let toastId = toastLoader("Please wait...");
        const data = {
            washTypeId: parseInt(fleetInfo.washTypeId),
            vehicleNumber: fleetInfo.vehicleNumber,
            locationNumber: fleetInfo.locationNumber,
            isPreSelected: fleetInfo.isPreSelected,
        }
        const response = await createAccountFleet(accountId ,data);
        if(response.status === 201){
            toastLoaderUpdate(toastId, "Fleet created successfully!", "success");
            const washTypeName = await getWashTypeName(data.washTypeId);
            const updatedFleet = {
                "accountFleetId": response.data.accountFleetId,
                "accountId": accountId,
                "washTypeId": data.washTypeId,
                "vehicleNumber": data.vehicleNumber,
                "isPreSelected": data.isPreSelected,
                "washTypeName": washTypeName
            }
            console.log(updatedFleet);
            setFleetlist(prevSelected => [...prevSelected, updatedFleet]);
            setTempFleetlist(prevSelected => [...prevSelected, updatedFleet]);
            closeIsAddorEditAccountFleetModal();
        }
        else{
            toastLoaderUpdate(toastId, "Fleet creation failed!", "error");
        }
    }

    const deleteFleets = async () => {
        setDialogBox({ isDialogboxOpen: false });
        let toastId = toastLoader("Please wait...");
    
        try {
                const response = await deleteAccountFleets(accountId, selectedFleets);
                if (response.status === 200) {
                    toastLoaderUpdate(toastId, "Fleet deleted successfully!", "success");
                    const updatedFleetList = tempFleetList.filter(fleet => !selectedFleets.includes(fleet.accountFleetId));
                    setFleetlist(updatedFleetList);
                    setTempFleetlist(updatedFleetList);
                } else {
                    toastLoaderUpdate(toastId, "Fleet detetion failed!", "error");
                }
        } catch (error) {
            toastLoaderUpdate(toastId, "Fleet delete failed!", "error");
            console.error("Error deleting fleet:", error);
        }
    };

    useEffect(() => {
        setSelectAll(paginatedFleetList.length > 1 && 
            selectedFleets.length > 1 && 
            paginatedFleetList.length === selectedFleets.length);
    }, [selectedFleets]);

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setSelectedFleets(!selectAll ? paginatedFleetList.map(fleet => fleet.accountFleetId) : []);
    };

    function clearSelectedItems(){
        setIsPreSelectAllChecked(false);
        setSelectedFleets([]);
        setPreSelectedFleets([]);
    }

    const handlePreSelect = (fleet) => {
        setPreSelectedFleets(prevSelected => {
            const isFleetInArray = prevSelected.some(item => item.accountFleetId === fleet.accountFleetId);
            if (isFleetInArray) {
                return prevSelected.map(item => 
                    item.accountFleetId === fleet.accountFleetId ? fleet : item
                );
            }
            return [...prevSelected, fleet];
        });
        
    }

    useEffect(() => {
        let preSelectedCount = 0;
        if(paginatedFleetList.length>0){
            paginatedFleetList.forEach(item => {
                if(item.isPreSelected) {
                    preSelectedCount++;
                }
            });
            if(paginatedFleetList.length == preSelectedCount){
                setIsPreSelectAllChecked(true);
            }
            else{
                setIsPreSelectAllChecked(false);
            }
        }
    }, [preSelectedFleets]);

    const handleSelectRow = (accountFleetId, isChecked) => {
        if (isChecked) {
            setSelectedFleets(prevSelected => [...prevSelected, accountFleetId]);
        } else {
            setSelectedFleets(prevSelected => prevSelected.filter(id => id !== accountFleetId));
        }
    };

    function setFleetInfoList() {
        return paginatedFleetList.map((fleet, index) => {
                return (<FleetListTableRow
                    key={index}
                    fleet = {fleet}
                    index = {index}
                    washTypes = {washTypeList}
                    isSelected={selectedFleets.includes(fleet.accountFleetId)}
                    onRowSelect={handleSelectRow}
                    handleUpdate={handleEditFleet}
                    locationNumber={locationFleetList.locationNumber}
                    preSelectUpdated={handlePreSelect}
                />)
        });
    }

    function sortField(sortingField) {
        switch (sortingField) {
            case "accFleettypeField":
                fleetList.sort(function (a, b) {
                    var nameA = a.washtypeId; // ignore upper and lowercase
                    var nameB = b.washtypeId; // ignore upper and lowercase
                    if (sortingType === "accFleettypeASC") {
                        return nameA < nameB ? 1 : -1;
                    } else {
                        return nameA < nameB ? -1 : 1;
                    }
                });
                break;
            case "accVehicleNumberField":
                fleetList.sort(function (a, b) {
                    var nameA = a.vehicleNumber.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.vehicleNumber.toUpperCase(); // ignore upper and lowercase
                    if (sortingType === "accVehicleNumberASC") {
                        return nameA < nameB ? 1 : -1;
                    } else {
                        return nameA < nameB ? -1 : 1;
                    }
                });
                break;
            case "accPreSelectedFleetField":
                fleetList.sort(function (a, b) {
                    var nameA = a.isPreSelected; // ignore upper and lowercase
                    var nameB = b.isPreSelected; // ignore upper and lowercase
                    if (sortingType === "accPreSelectedFleetASC") {
                        return nameA < nameB ? 1 : -1;
                    } else {
                        return nameA < nameB ? -1 : 1;
                    }
                });
                break;
            default:
                break;
        }
        setPaginatedFleetList(fleetList.slice(itemOffset, endOffset))
    }

    function tableFieldsHeading(labelName, fieldName) {
        return (
            <div className="table-fields-heading">
                <label className="table-fields-labels">{labelName}</label>
                {fieldName === null ? null : (
                    <div className="sorting-div">
                        <Image
                            src={asc}
                            className="sorting-btns"
                            style={{ cursor: "pointer" }}
                            onClick={() => (
                                setSortingType(fieldName + "ASC"),
                                sortField(fieldName + "Field")
                            )}
                        ></Image>
                        <Image
                            src={desc}
                            className="sorting-btns"
                            style={{ cursor: "pointer" }}
                            onClick={() => (
                                setSortingType(fieldName + "DSC"),
                                sortField(fieldName + "Field")
                            )}
                        ></Image>
                    </div>
                )}
            </div>
        );
    }

    function closeConfirmationDialogBox() {
        setDialogBox({
            isDialogboxOpen: false,
        });
    }

    return (
        <div>
            <Row style={{padding: "0px 10px"}}>
                <Col sm="5" className="top-buttons-div">
                        <InputGroup
                            className="mb-3"
                            style={{ padding: "0px 0px" }}
                        >
                            <InputGroup.Text
                                id="basic-addon1"
                                style={{ background: "none", borderRight: "none" }}
                            >
                                <Image
                                    src={searchIcon}
                                    height="24px"
                                    width="24px"
                                    style={{ cursor: "pointer" }}
                                ></Image>
                            </InputGroup.Text>
                            <FormControl
                                style={{ borderLeft: "none" }}
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                onChange={(e) => {
                                    setFilterKeyWord(e.target.value);
                                    filterData(e.target.value);
                                }}
                            />
                        </InputGroup>
                </Col>
                <Col sm="7" className="top-buttons-div">
                            <Button
                                className="user-button custom-button-width"
                                onClick={handleAddFleet}
                            >
                                <label className="labels">Add</label>
                            </Button>

                            <Button
                                className="user-button custom-button-width"
                                onClick={handleDelete}
                                disabled = {selectedFleets.length > 0 ? false : true}
                            >
                                <label className="labels">Delete</label>
                            </Button>

                            <Button
                                className="user-button custom-button-width"
                                onClick={() => openImportModal()}
                            >
                                <label className="labels">Upload</label>
                            </Button>
                            <Button
                                className="user-button custom-button-width"
                                onClick={handleSave}
                                disabled={preSelectedFleets.length > 0 ? false : true}
                            >
                                <label className="labels">Save</label>
                            </Button>
                </Col>
            </Row>
            <Row  style={{padding: "0px 10px"}}>
                <Col style={{paddingBottom: '0.6rem', display: "flex", justifyContent: "end"}} className="col-right-space ">
                    <Button
                        className="user-button"
                        style={{width: "11.7rem"}}
                        onClick={async () => {
                            refreshFleetsList();
                        }}
                    >
                        <label className="labels">Refresh</label>
                    </Button>
                </Col>
            </Row>
            <Row>
                <Row style={{ margin: "auto" }}>
                    <Form>
                        <Table className="table">
                            <thead>
                                <tr className="table-heading">
                                    <th style={{ width: '5%' }} className="table-fields-heading">
                                            <FormCheck
                                                checked={
                                                    selectAll
                                                }
                                                onChange={
                                                    handleSelectAll
                                                }
                                            />
                                    </th>
                                    <th style={{ width: '30%' }} >{tableFieldsHeading("Fleet Type", "accFleettype")}</th>
                                    <th style={{ width: '30%' }}>{tableFieldsHeading("Vehicle Number", "accVehicleNumber")}</th>
                                    <th style={{ width: '25%' }} className="table-fields-heading">
                                            <FormCheck
                                                checked={
                                                    isPreSelectAllChecked ? true : false
                                                }
                                                onChange={(e) => {
                                                    setIsPreSelectAllChecked(!isPreSelectAllChecked);
                                                    const updatedPaginatedFleetList = paginatedFleetList.map(item => ({
                                                        ...item,
                                                        isPreSelected: !isPreSelectAllChecked
                                                    }));
                                                    setPaginatedFleetList(updatedPaginatedFleetList);
                                                    setPreSelectedFleets(updatedPaginatedFleetList);
                                                }}
                                            />
                                            {tableFieldsHeading("Pre-Selected", "accPreSelectedFleet")}
                                    </th>
                                    <th style={{ width: '10%' }}>{tableFieldsHeading("", null)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fleetList.length > 0 ? (
                                    setFleetInfoList()
                                ) :  (
                                    <tr>
                                        <td colSpan="4" className="user_view no-data-found">
                                            {"No data found"}
                                        </td>
                                    </tr>
                                ) }
                            </tbody>
                        </Table>
                    </Form>
                </Row>
            </Row>
            <Row style={{padding:"10px"}}>
                {fleetList.length > 0 ? (
                    <Pagination
                        items={fleetList}
                        currentItems={setPaginatedFleetList}
                        itemsPerpage={itemsPerpage}
                        itemOffset={setItemOffset}
                        endOffset={setEndOffset}
                        clearSelectedItems={clearSelectedItems}
                    />
                ) : null}
            </Row>
            <AddOrEditAccountFleetModal
                show={isAddorEditAccountFleetModalOpen}
                onHide={() => closeIsAddorEditAccountFleetModal()}
                handleUpdateFleet={handleUpdate}
                handleCreateFleet={handleCreateFleet}
                washTypes = {washTypeList}
                fleet={selectedFleet}
                locationNumber= {locationFleetList.locationNumber} 
                isEditMode={isEditModeEnabled}
            />
            <ImportModal
                show={isImportModalOpen}
                name={importModalName}
                onHide={() => closeImportModal()}
                message={importModalMessage}
                onEvent={(file) => initializeUploadFile(file)}
                responseViewer={responseViewer}
                responseMessage={importResponseMessage}
                icon={icon}
                fileType=".csv"
                loading={importModalLoading}
            />
            <DialogBoxModal
                show={dialogBox.isDialogboxOpen}
                name={dialogBox.dialogboxName}
                onHide={() => closeConfirmationDialogBox()}
                onEvent={() => {
                        deleteFleets();
                }
                }
                message={dialogBox.dialogBoxMessage}
                icon={dialogBox.dialogBoxIcon}
                isConfirmationDialogBox={dialogBox.isConfirmationDialogBox}
                buttonName="Delete"
            />
        </div>
    )
}
export default FleetList
