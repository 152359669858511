import React, { useEffect, useState } from "react";
import { Modal, Row, Button, Form, FormCheck, Col } from "react-bootstrap";
import Loader from "../common/Loader";
import './AddOrEditModal.css';
import { WASH_FREQUENCY } from "../common/constant";

function AddOrEditAccountWashTypeModal(props) {
    const [washTypeInfo, setWashTypeInfo] = useState({});
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [washTypes, setWashTypes] = useState([]);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

    useEffect(() => {
        setWashTypes(props.washTypes);
        if (props.isEditMode && props.selectedWashType) {
            setWashTypeInfo({
                washTypeId: props.selectedWashType.washTypeId || 0,
                washTypeName: props.selectedWashType.washTypeName || '',
                specialRate: props.selectedWashType.specialRate || 0,
                washFrequency: props.selectedWashType.washFrequency || '',
                locationNumber: props.locationNumber,
                instructions: props.selectedWashType.instructions || '',
            });
        } else {
            setWashTypeInfo({
                washTypeId: 0,
                washTypeName: '',
                specialRate: 0,
                washFrequency: '',
                locationNumber: props.locationNumber,
                instructions: '',
            });
        }
        setIsSaveButtonDisabled(false);
    }, [props]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setWashTypeInfo((prev) => ({ ...prev, [name]: value }));
        setIsSaveButtonDisabled(false);
    };

    const validateForm = () => {
        const newErrors = {};
        console.log('washTypeInfo: ',washTypeInfo);
        if (!washTypeInfo.washTypeId) {
            newErrors.washTypeId = 'Wash Type is required';
        }
        if (!washTypeInfo.specialRate) {
            newErrors.specialRate = 'Wash Rate is required';
        }
        if (!washTypeInfo.washFrequency) {
            newErrors.washFrequency = 'Wash Frequency is required';
        }
        setErrors(newErrors);
        const result = Object.keys(newErrors).length === 0;
        setIsFormValid(result);
        return result;
    };

    const handleSave = () => {
        const isValidForm = validateForm();
        if (isValidForm) {
            if(props.isEditMode){
                props.handleUpdateWashType(washTypeInfo);
            } else {
                props.handleCreateAccountWashType(washTypeInfo);
            }
        }
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop="static"
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Loader className="loader-custom" loading={props.loading} />
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.isEditMode ? "Update Account Wash Type" : "Add New Wash Type"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group as={Row} className="mb-3 align-items-center">
                        <Form.Label column sm="4">Wash Type</Form.Label>
                        <Col sm="8">
                            <Form.Select
                                value={washTypeInfo.washTypeId}
                                name="washTypeId"
                                onChange={handleChange}
                                isInvalid={!!errors.washTypeId && !washTypeInfo.washTypeId}
                            >
                                <option>Select</option>
                                {washTypes.map((washType) => (
                                    <option key={washType.id} value={washType.id}>
                                        {washType.name}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {errors.washTypeId}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 align-items-center">
                        <Form.Label column sm="4">Wash Rate</Form.Label>
                        <Col sm="8">
                            <Form.Control
                                type="text"
                                name="specialRate"
                                value={washTypeInfo.specialRate}
                                onChange={handleChange}
                                isInvalid={!!errors.specialRate && !washTypeInfo.specialRate}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.specialRate}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 align-items-center">
                        <Form.Label column sm="4">Wash Frequency</Form.Label>
                        <Col sm="8">
                            <Form.Select
                                name="washFrequency"
                                value={washTypeInfo.washFrequency}
                                isInvalid={!!errors.washFrequency && !washTypeInfo.washFrequency}
                                onChange={handleChange}
                            >
                                <option value="">Select</option>
                                {WASH_FREQUENCY.map(({ value, label }) => (
                                    <option key={value} value={value}>
                                    {label}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                    {errors.washFrequency}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 align-items-center">
                        <Form.Label column sm="4">Instructions</Form.Label>
                        <Col sm="8">
                            <Form.Control
                                type="text"
                                name="instructions"
                                value={washTypeInfo.instructions}
                                onChange={handleChange}
                            />
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer style={{ display: !props.responseViewer ? "" : "none" }}>
                <Row className="w-100 justify-content-center">
                    <Row className="buttons-style row-style">
                        <Button
                            variant="primary"
                            className="input_button"
                            size="lg"
                            active
                            disabled={isSaveButtonDisabled}
                            onClick={(e) => {
                                e.preventDefault();
                                setIsSaveButtonDisabled(true);
                                handleSave();
                            }}
                        >
                            {props.isEditMode ? "Update" : "Save"}
                        </Button>{" "}
                        <Button
                            variant="primary"
                            className="input_button"
                            size="lg"
                            active
                            onClick={()=>{
                                setErrors({});
                                props.onHide()
                            }
                            }
                        >
                            Cancel
                        </Button>{" "}
                    </Row>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}

export default AddOrEditAccountWashTypeModal;