import React, { useState, useEffect } from "react";
import "./Account.css";
import "../common/Common.css";
import { Row, Tab, Tabs } from "react-bootstrap";
import { getWashType } from "../../service/washTypeService";
import AccountWashTypeList from "./AccountWashTypeList";
import AccountMiscTypeList from "./AccountMiscTypeList";
import { getAccountWashMiscCharges } from "../../service/accountService";
import AccountTripMinimum from "./AccountTripMinumum";

function AccountWashesInfo(props) {
    const [accountId, setAccountId] = useState(0);
    const [key, setKey] = useState('');
    const [washTypes, setWashTypes] = useState([]);
    const [locationAccountWashTypes, setLocationAccountWashTypes] = useState([]);

    const fetchAccountWashInformation = async () => {
        if(accountId){
            var result = await getAccountWashMiscCharges(accountId,0);
            if (result.status === 200) {
                const locations = result.data.accountWashMiscCharges[0].locations.length > 0 ? result.data.accountWashMiscCharges[0].locations : [];
                setLocationAccountWashTypes(locations);
                if(locations.length > 0 && !key){
                    setKey(locations[0].locationNumber ? locations[0].locationNumber : '');
                }
            }
        }
    };
    useEffect(() => {
        fetchAccountWashInformation();
    }, [accountId,props.tabTrigger]);

    useEffect(() => {
        fetchWashtypeData();
        setAccountId(props.accountId);
    }, [props.accountId,props.tabTrigger]);

    const fetchWashtypeData = async () => {
        var result = await getWashType();
        if (result.status === 200) {
            setWashTypes(result.data.Washtypes ? result.data.Washtypes : []);
        }
    };

    return (
        <>
            { locationAccountWashTypes.length > 0 ? (
                <Tabs 
                    className="mb-3 custom-tabs-container"
                    style={{ display: 'flex', flexWrap: 'nowrap' , padding: "10px 10px", marginBottom: '0px !important'}}
                    id="account-location-tab" 
                    activeKey={key} 
                    onSelect={(k) => setKey(k)}
                >
                    {locationAccountWashTypes.map((location, index) => (
                        <Tab key={index} 
                            eventKey={location.locationNumber} 
                            title={location.locationName}
                            className="custom-tab"
                            tabClassName="custom-tab-link"
                        >
                            <Row className="custom-row">
                            {locationAccountWashTypes.some(washType => washType.locationNumber === location.locationNumber) && (
                                <AccountTripMinimum
                                    accountMiscTypes={locationAccountWashTypes.filter(washType => washType.locationNumber === location.locationNumber)[0].miscCharges}
                                    locationNumber={location.locationNumber}
                                    accountId={accountId}
                                />
                            )}
                            </Row>
                            <Row className="custom-row">    
                                {locationAccountWashTypes.some(washType => washType.locationNumber === location.locationNumber) && (
                                    <AccountWashTypeList
                                        accountWashTypes={locationAccountWashTypes.filter(washType => washType.locationNumber === location.locationNumber)[0].washTypeCharges}
                                        locationNumber={location.locationNumber}
                                        washTypes={washTypes}
                                        accountId={accountId}
                                        fetchAccountWashInformation={fetchAccountWashInformation}
                                    />
                                )}
                            </Row>
                            <Row className="custom-row">
                                {locationAccountWashTypes.some(washType => washType.locationNumber === location.locationNumber) && (
                                    <AccountMiscTypeList
                                        accountMiscTypes={locationAccountWashTypes.filter(washType => washType.locationNumber === location.locationNumber)[0].miscCharges}
                                        locationNumber={location.locationNumber}
                                        accountId={accountId}
                                        fetchAccountWashInformation={fetchAccountWashInformation}
                                    />
                                )}
                            </Row>
                        </Tab>
                    ))}
                </Tabs>
                 ): 
                (
                    <Row className="custom-row">
                        <span style={{textAlign: "center"}}>No Data Found</span>
                    </Row>
                )
            } 
                 
        </>
    );
}

export default AccountWashesInfo;