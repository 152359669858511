import React, { useEffect, useState } from "react";

import "./BulkEdit.css";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { getWashType } from "../../service/washTypeService";
import { toast } from "react-toastify";
import {
  getLoaderToastSettings,
  getLoaderToastMessage,
  getToastSettings,
} from "../common/ToastManager";
import {
  getWashDetails,
  validateBulkEdit,
} from "../../service/washInventoryService";
import { formatDateToUTC } from "../common/helper";

function BulkEdit(props) {
  // const washDateError = useFormUpdate(false);
  // const vehicleNumberError = useFormUpdate(false);
  // const washtypeIdError = useFormUpdate(false);
  // const leadError = useFormUpdate(false);

  // const [show, setShow] = useState(false);
  const [washDate, setWashDate] = useState("");
  const [isSaveButtonEnable, setIsSaveButtonEnable] = useState(true);
  const [leadId, setLead] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [washtypeId, setWashtypeId] = useState("");

  const [washtypeList, setWashtypeList] = useState([]);

  const handleClose = () => props.setShow(false);
  const handleShow = () => props.setShow(true);

  const toastError = (toastMessage) =>
    toast.error(toastMessage, getToastSettings());

  let bulkEditData = {};

  const submittingData = (key, value) => {
    if (key === "endTime") value.setHours(23, 59, 59);
    if (key === "bulkEditDate") value.setHours(23, 59, 59);
    bulkEditData[key] = value;
  };

  const createSubmittingData = () => {
    console.log(props);
    if (washDate) submittingData("endTime", washDate);
    if (washDate) submittingData("bulkEditDate", washDate);
    if (washtypeId) submittingData("washTypeId", parseInt(washtypeId));
    if (vehicleNumber) submittingData("vehicleNumber", vehicleNumber);
    if (leadId) submittingData("leadId", parseInt(leadId));
    if (props.ids.length) submittingData("ids", props.ids);
    return bulkEditData;
  };

  const handleFormSubmission = async () => {
    setIsSaveButtonEnable(false);
    const data = createSubmittingData();
    if (data) {
      if ("bulkEditDate" in data && "vehicleNumber" in data && "washTypeId" in data) {
        toastError(
          "You can't Update Date, Fleet Number and Washtype together"
        );
      } else { 
          await props.bulkEdit(data);
      }
      setIsSaveButtonEnable(true);
    }
    }
  useEffect(() => {
    fetchWashtypeData();
  }, []);

  const fetchWashtypeData = async () => {
    var result = await getWashType();
    if (result.status === 200) {
      setWashtypeList(result.data.Washtypes ? result.data.Washtypes : []);
    }
  };

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal
        show={props.show}
        onHide={handleClose}
        className={"modalBulkEdit"}
        border={"none"}
      >
        <Modal.Body>
          <Row className="form-align-center">
            <Form.Group
              as={Col}
              className="mb-3"
              controlId="formPlaintextAccount"
            >
              <Row>
                <Form.Label column sm="6">
                  Date
                </Form.Label>
              </Row>
              <Row>
                <DatePicker
                  //  className={washDateError.state ? "dateRangeError" : "dateRange"}
                  className={"dateRange"}
                  value={washDate === null ? "" : washDate}
                  selected={washDate}
                  onChange={(date) => setWashDate(date)}
                  placeholderText={"Please select a date"}
                />
              </Row>
              <Row>
                <Form.Label column sm="6">
                  Lead
                </Form.Label>
              </Row>
              <Row>
                <Col sm="12">
                  <Form.Select
                    value={leadId}
                    disabled={false}
                    onChange={(e) => {
                      setLead(e.target.value);
                    }}
                  >
                    <option value="">Select Lead</option>
                    {props.leadList.map((obj) => {
                      return (
                        <option key={obj.leadId} value={obj.leadId}>
                          {obj.leadName}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Form.Label column sm="6">
                  Wash Type
                </Form.Label>
              </Row>
              <Row>
                <Col sm="12">
                  <Form.Select
                    value={washtypeId}
                    disabled={false}
                    onChange={(e) => {
                      setWashtypeId(e.target.value);
                    }}
                  >
                    <option value="">Select Wash Type</option>
                    {washtypeList.map((obj) => {
                      return (
                        <option key={obj.id} value={obj.id}>
                          {obj.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Form.Label column sm="6">
                  Fleet Number
                </Form.Label>
              </Row>
              <Row>
                <Col sm="12">
                  <Form.Control
                    type="text"
                    placeholder="Fleet Number"
                    value={vehicleNumber}
                    style={{
                      // borderColor: vehicleNumberError.state ? "red" : "",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      // vehicleNumberError.update(false);
                      setVehicleNumber(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="modalButton" disabled = {!isSaveButtonEnable} onClick={handleFormSubmission}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function useFormUpdate(initialState) {
  const [state, setstate] = useState(initialState);

  function update(value) {
    setstate(value);
  }
  return {
    state,
    update,
  };
}

export default BulkEdit;
