import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  FormCheck,
  Image,
} from "react-bootstrap";
import Loader from "../common/Loader";
import { getAllAccountsSummaryV2 } from "../../service/accountService";
import {
  deleteWashDetails,
  getWashDetails,
  postWashDetail,
  updateWashDetail,
  bulkUpdateWashDetail
} from "../../service/washInventoryService";
import { listAllLead } from "../../service/userService";
import TableHeading from "../common/TableHeading";
import Moment from "moment";
import Pagination from "../common/Pagination";
import DialogBoxModal from "../modal/DialogBoxModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ACTIVEROWCOLOR, DELIMITER } from "../common/constant";
import AddNewWashInventory from "./AddNewWashInventory";
import { customViewFormatDate, formatDate } from "../common/helper";
import BulkEdit from "./BulkEdit";
import { CSVLink } from "react-csv";

function WashDetails(props) {
  const itemsPerpage = 25;
  const [itemOffset, setItemOffset] = useState(0);
  const [endOffset, setEndOffset] = useState(itemsPerpage);
  const [dateRange, setDateRange] = useState([null, null]);
  const [dateFrom, dateTo] = dateRange;
  const [filterByLead, setFilterByLead] = useState("0");
  const [accountId, setAccountId] = useState("0");
  const [leadList, setLeadList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [filterByAccount, setFilterByAccount] = useState('');
  const [accountLocationNumber, setAccountLocationNumber] = useState(0);
  const [washDetails, setWashDetails] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [currentWashDetails, setCurrentWashDetails] = useState([]);
  const [dateError, setDateError] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [event, setEvent] = useState("");
  const [isUpdateWashInventory, setIsUpdateWashInventory] = useState(false);
  const [isAddNewWashInventory, setIsAddNewWashInventory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedWashInventory, setSelectedWashInventory] = useState({});
  const [isLoadPagination, setIsLoadPagination] = useState(true);
  const [openBulkEditPopup, setOpenBulkEditPopup] = useState(false);

  const [currentSortingField, setCurrentSortingField] = useState("");
  const [sortingType, setSortingType] = useState("");
  const [accountOptions, setAccountOptions] = useState([]);

  const fieldNamesAndLabels = {
    date: "Date",
    account: "Account Name",
    lead: "Lead",
    washType: "Wash Type",
    quantity: "Quantity",
    fleetNumber: "Fleet Number",
  };

  const [dialogBox, setDialogBox] = useState({
    isDialogboxOpen: false,
    dialogBoxMessage: "",
    isConfirmationDialogBox: true,
    dialogBoxIcon: "delete",
    dialogboxName: "",
  });

  function validateForm() {
    let validationStatus = true;
    setDateError(false);

    if (dateFrom == null || dateTo == null) {
      validationStatus = false;
      setDateError(true);
    }
    return validationStatus;
  }

  async function deleteWashDetailsData() {
    let toastId = props.toastLoader("Please wait...");
    setDialogBox({ isDialogboxOpen: false });
    var response = await deleteWashDetails(selectedItems);
    if (response.status === 200) {
      props.toastLoaderUpdate(
        toastId,
        "Wash details successfully deleted!",
        "success"
      );
      fetchWashDetails();
    } else {
      props.toastLoaderUpdate(
        toastId,
        "Wash details  deletion failed!",
        "error"
      );
    }
  }

  async function fetchWashDetails() {
    setWashDetails([]);
    setLoading(true);

    setCurrentSortingField("dateField");
    setSortingType("dateASC");
    
    createOrUpdateWashInventory(false, null);
    let validationResult = true;
    validationResult = await validateForm();
    if (!validationResult) {
    } else {
      let response = await getWashDetails(
        accountId,
        accountLocationNumber,
        filterByLead,
        new Date(dateFrom.setHours(0,0,0)),
        new Date(dateTo.setHours(23,59,59))
      );
      if (response.status === 200) {
        if(accountLocationNumber>0){
          const filteredData = response.data.washInventory.filter(item => item.locationNumber === parseInt(accountLocationNumber));
          setWashDetails(filteredData);
        }
        else{
          setWashDetails(response.data.washInventory);
        }
      } else if (response.status === 404) {
        setWashDetails([]);
      }
    }
    sortField(currentSortingField);
    setLoading(false);
  }


  function clearValues() {
    setDateRange([null, null]);
    createOrUpdateWashInventory(false, null);
    setFilterByLead("0");
    setAccountId("0");
  }


  useEffect(() => {
    fetchListAllLead();
    fetchAccountListSummary();
  }, []);

  useEffect(() => {
    sortField(currentSortingField);
    setIsLoadPagination(false);
    const data = washDetails.map(item => ({
      date: customViewFormatDate(item.endTime),
      "account name": item.locationsCount > 1 ? item.accountName + ' - ' + item.locationName : item.accountName,
      lead: item.leadName,
      washtype: item.washTypeName,
      quantity: item.quantity,
      "fleet number": item.vehicleNumber
    }));
    setCsvData(data);
  }, [washDetails]);

  useEffect(() => {
    setIsLoadPagination(true);
  }, [currentWashDetails]);

  function sortField(sortingField) {
    switch (sortingField) {
      case "dateField":
        washDetails.sort(function(a, b) {
          var valueA = a.endTime.toUpperCase();
          var valueB = b.endTime.toUpperCase();
          if (sortingType === "dateASC") {
            return valueA < valueB ? -1 : 1;
          } else {
            return valueA < valueB ? 1 : -1;
          }
        });
        break;
      case "accountField":
        washDetails.sort(function(a, b) {
          var valueA = a.accountName.toUpperCase();
          var valueB = b.accountName.toUpperCase();
          if (sortingType === "accountASC") {
            return valueA < valueB ? 1 : -1;
          } else {
            return valueA < valueB ? -1 : 1;
          }
        });
        break;
      case "leadField":
        washDetails.sort(function(a, b) {
          var valueA = a.leadName.toUpperCase();
          var valueB = b.leadName.toUpperCase();
          if (sortingType === "leadASC") {
            return valueA < valueB ? 1 : -1;
          } else {
            return valueA < valueB ? -1 : 1;
          }
        });
        break;
      case "washTypeField":
        washDetails.sort(function(a, b) {
          var valueA = a.washTypeName.toUpperCase();
          var valueB = b.washTypeName.toUpperCase();
          if (sortingType === "washTypeASC") {
            return valueA < valueB ? 1 : -1;
          } else {
            return valueA < valueB ? -1 : 1;
          }
        });
        break;
      case "quantityField":
        washDetails.sort(function(a, b) {
          var valueA = a.quantity;
          var valueB = b.quantity;
          if (sortingType === "quantityASC") {
            return valueA < valueB ? 1 : -1;
          } else {
            return valueA < valueB ? -1 : 1;
          }
        });
        break;
      case "fleetNumberField":
        washDetails.sort(function(a, b) {
          var valueA = a.vehicleNumber.toUpperCase();
          var valueB = b.vehicleNumber.toUpperCase();
          if (sortingType === "fleetNumberASC") {
            return valueA < valueB ? 1 : -1;
          } else {
            return valueA < valueB ? -1 : 1;
          }
        });
        break;
      default:
        break;
    }
    setSelectedItems([]);
    setCurrentWashDetails(washDetails.slice(itemOffset, endOffset));
  }
  
  const fetchListAllLead = async () => {
    let response = await listAllLead();
    if (response.status === 200) {
      setLeadList(response.data ? response.data.response : []);
    }
  };

  function getAccountSelectionOptionsWithLocation(accountSummary){
    const options = []
    accountSummary.forEach((account) => {
      account.locations.forEach((location) => {
          let option = {
              value: account.accountId + DELIMITER + account.name + DELIMITER + location.locationNumber,
              label: account.name + (account.locations.length > 1 ? " - " + location.name : ""),
          };
          options.push(option);
      });
    });
    return (options);
  }

  const fetchAccountListSummary = async () => {
    let response = await getAllAccountsSummaryV2();
    if (response.status === 200) {
      const options = getAccountSelectionOptionsWithLocation(response.data.accountSummary);
      setAccountOptions(options);
      setAccountList(response.data ? response.data.response : []);
    }
  };

  function listWashDetailsInTable() {
    return currentWashDetails.map((obj) => {
      return isUpdateWashInventory && selectedItems[0] === obj.id ? (
        <tr className="washinventory-view" key={obj.id}>
          {addNewWashInventory()}
        </tr>
      ) : (
        <tr
          key={obj.id}
          className="washinventory-table-row"
          style={{
            backgroundColor: selectedItems.includes(obj.id)
              ? ACTIVEROWCOLOR
              : "",
          }}
          onClick={() => {
            createOrUpdateWashInventory(false, null);
            selectedItems.length > 1
              ? setSelectedItems([obj.id])
              : selectedItems[0] === obj.id
              ? setSelectedItems([])
              : setSelectedItems([obj.id]);
          }}
        >
          <td>
            <FormCheck
              name={obj.id}
              checked={selectedItems.includes(obj.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedItems([...selectedItems, obj.id]);
                  createOrUpdateWashInventory(false, null);
                } else {
                  let updatedList = selectedItems.filter(function(item) {
                    return item !== obj.id;
                  });
                  setSelectedItems(updatedList);
                  createOrUpdateWashInventory(false, null);
                }
              }}
            />
          </td>
          <td className="table-cells-content">
            {customViewFormatDate(obj.endTime)}
            {/* {Moment(obj.endTime).format("dddd - MMMM DD,YYYY")} */}
          </td>
          <td className="table-cells-content">{obj.locationsCount > 1 ? obj.accountName + ' - ' + obj.locationName : obj.accountName}</td>
          <td className="table-cells-content">{obj.leadName}</td>
          <td className="table-cells-content">{obj.washTypeName}</td>
          <td className="table-cells-content">{obj.quantity}</td>
          <td className="table-cells-content">{obj.vehicleNumber}</td>
        </tr>
      );
    });
  }

  function addNewWashInventory() {
    return (
      <AddNewWashInventory
        onEvent={(data) => upsertWashInventory(data)}
        event={event}
        washDetail={selectedWashInventory}
        onCancelNewWashInventory={() =>
          createOrUpdateWashInventory(false, null)
        }
        accountOptions={accountOptions}
      ></AddNewWashInventory>
    );
  }

  function upsertWashInventory(data) {
    if (event === "insert") {
      createWashDetail(data);
    } else if (event === "update") {
      data.startTime.setHours(23,59,59);
      data.endTime.setHours(23,59,59);
      editWashDetails(data);
    }
  }

  async function createWashDetail(data) {
    let toastId = props.toastLoader("Please wait...");
    var response = await postWashDetail(data);
    if (response.status === 201) {
      props.toastLoaderUpdate(
        toastId,
        "Wash Details successfully saved!",
        "success"
      );
      fetchWashDetails();
      createOrUpdateWashInventory(false, null);
    } else {
      if (response.data.error.errorCode === "allFleetsExist") {
        props.toastLoaderUpdate(
          toastId,
          "Fleet already exists !",
          "error"
        );
      }
      else {
        props.toastLoaderUpdate(
          toastId,
          "Wash Details creation failed!",
          "error"
        );
      }
    }
  }

  async function editWashDetails(data) {
    let toastId = props.toastLoader("Please wait...");
    var response = await updateWashDetail(data);
    console.log("response: ",response);
    if (response.status === 200) {
      props.toastLoaderUpdate(
        toastId,
        "Wash details was successfully edited!",
        "success"
      );
      setSelectedItems([]);
      createOrUpdateWashInventory(false, null);
      fetchWashDetails();
    }
    else if(response.status === 500){
      props.toastLoaderUpdate(toastId, response.data.response, "error");
    } 
    else {
      props.toastLoaderUpdate(toastId, "Wash details update failed!", "error");
    }
  }

  async function bulkEditWashDetails(data) {
    let toastId = props.toastLoader("Please wait...");
    var response = await bulkUpdateWashDetail(data);
    if (response.status === 200) {
      props.toastLoaderUpdate(
        toastId,
        "Wash details was successfully edited!",
        "success"
      );
      setSelectedItems([]);
      setOpenBulkEditPopup(false);
      createOrUpdateWashInventory(false, null);
      fetchWashDetails();
    } else {
      props.toastLoaderUpdate(toastId, "Wash details update failed!", "error");
    }
  }

  function createOrUpdateWashInventory(trueOrFalse, event) {
    if (event === "insert") {
      setSelectedWashInventory({});
      setSelectedItems([]);
      setIsAddNewWashInventory(trueOrFalse);
    } else if (event === "update") {
      washDetails.forEach((washDetail) => {
        if (washDetail.id === selectedItems[0]) {
          setSelectedWashInventory(washDetail);
        }
      });
      setIsUpdateWashInventory(trueOrFalse);
    } else {
      setIsAddNewWashInventory(trueOrFalse);
      setIsUpdateWashInventory(trueOrFalse);
    }
    setEvent(event);
  }

  function openConfirmationModal() {
    let washDetailsData = [];
    washDetails.forEach((washDetails) => {
      selectedItems.forEach((item) => {
        if (washDetails.id === item) {
          washDetailsData.push(washDetails);
        }
      });
    });
    setSelectedWashInventory(washDetailsData);

    setDialogBox({
      isConfirmationDialogBox: true,
      dialogBoxMessage: "Are you sure you want to delete this record?",
      dialogBoxIcon: "delete",
      isDialogboxOpen: true,
    });
  }

  function closeConfirmationDialogBox() {
    setDialogBox({
      isDialogboxOpen: false,
    });
    setSelectedWashInventory([]);
    setWashDetails([]);
    fetchWashDetails();
  }

  return (
    <div style={{ position: "relative", marginTop: "4%" }}>
      <Row>
        <Col sm={3}>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextdateRange"
          >
            <Row>
              <Form.Label column sm="12">
                Date Range
              </Form.Label>
            </Row>
            <Row>
              <DatePicker
                className={dateError ? "dateRangeError" : "dateRange"}
                value={dateFrom === null ? "From - To" : dateRange}
                selectsRange={true}
                startDate={dateFrom}
                endDate={dateTo}
                onChange={(update) => {
                  setDateError(false);
                  setDateRange(update);
                }}
              />
            </Row>
          </Form.Group>
        </Col>
        <Col sm={2}>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextFilterByLead"
          >
            <Row>
              <Form.Label column sm="12">
                Filter By
              </Form.Label>
            </Row>
            <Row>
              <Form.Select
                value={filterByLead}
                disabled={false}
                onChange={(e) => {
                  setFilterByLead(e.target.value);
                }}
              >
                <option value="0">Select lead</option>
                {leadList.map((obj) => {
                  return (
                    <option key={obj.leadId} value={obj.leadId}>
                      {obj.leadName}
                    </option>
                  );
                })}
              </Form.Select>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextFilterByAccount"
          >
            <Row>
              <Form.Label column sm="12">
                Filter By
              </Form.Label>
            </Row>
            <Row>
              <Form.Select
                value={filterByAccount}
                disabled={false}
                onChange={(e) => {
                  setFilterByAccount(e.target.value);
                  if(e.target.value === '0'){
                    setAccountId(0);
                    setAccountLocationNumber(0);
                  }
                  else{
                    let data = e.target.value.split(DELIMITER);
                    setAccountId(data[0]);
                    setAccountLocationNumber(data[2]);
                  }
                }}
              >
                <option value="0">Select account</option>
                {accountOptions.map((obj,index) => {
                  return (
                    <option key={index} value={obj.value}>
                      {obj.label}
                    </option>
                  );
                })}
              </Form.Select>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={4} style={{ paddingTop: "3%" }}>
          <div className="washInventory-buttons-div">
            <Button className="user-button" onClick={clearValues}>
              <label className="labels">Clear</label>
            </Button>
            <Button className="user-button" onClick={fetchWashDetails}>
              <label className="labels">View</label>
            </Button>
            <CSVLink
              data={csvData}
              className="user-button"
              style={{ width: "100px", display: washDetails.length > 0 ? "block" : "none" }}
              onClick={() => {
                let toastId = props.toastLoader("Please wait...");
                if (csvData.length > 0) {
                  console.log('washDetails: ',csvData);
                  props.toastLoaderUpdate(toastId, "Data downlaoded successfully!", "success");
                  return true;
                }
                else {
                  props.toastLoaderUpdate(toastId, "No data found!", "error");
                  return false;
                }
              }}
              filename={"wash_inventory_details_" + formatDate(dateFrom) + "_to_" + formatDate(dateTo)}
            >
              <label className="labels">Download</label>
            </CSVLink>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={9}></Col>
        <Col sm={3}>
          <div className="washInventory-buttons-div">
            <Button
              className="user-button"
              disabled={selectedItems.length === 1 ? false : true}
              onClick={() => createOrUpdateWashInventory(true, "update")}
            >
              <label className="labels">Edit</label>
            </Button>
            <Button
              className="user-button"
              disabled={selectedItems.length > 1 ? false : true}
              onClick={() => setOpenBulkEditPopup(!openBulkEditPopup)}
            >
              <label className="labels">Bulk Edit</label>
            </Button>
            <Button
              className="user-button"
              disabled={selectedItems.length > 0 ? false : true}
              onClick={() => openConfirmationModal()}
            >
              <label className="labels">Delete</label>
            </Button>
            <Button
              className="user-button"
              onClick={() => createOrUpdateWashInventory(true, "insert")}
            >
              <label className="labels">Add</label>
            </Button>
          </div>
        </Col>
      </Row>

      <Row style={{ marginTop: "2%",maxHeight: "60vh",overflowY: "scroll" }}>
        <Form>
          <Table className="table">
            <thead>
              <tr className="table-heading">
                <th>
                  <div className="table-fields-heading">
                    <FormCheck
                      checked={
                        selectedItems.length === currentWashDetails.length &&
                        currentWashDetails.length > 0
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          let newList = [];
                          currentWashDetails.forEach((item) =>
                            newList.push(item.id)
                          );
                          setSelectedItems(newList);
                        } else {
                          setSelectedItems([]);
                        }
                      }}
                    />
                  </div>
                </th>
                {Object.entries(fieldNamesAndLabels).map(
                  ([fieldName, labelIs]) => {
                    return (
                      <th key={fieldName}>
                        <TableHeading
                          labelIs={labelIs}
                          fieldName={fieldName}
                          setSortingType={setSortingType}
                          setCurrentSortingField={setCurrentSortingField}
                          sortField={sortField}
                          sortingType={sortingType}
                          currentSortingField={currentSortingField}
                        />
                      </th>
                    );
                  }
                )}
              </tr>
            </thead>
            <tbody>
              {washDetails.length > 0 ? (
                listWashDetailsInTable()
              ) : (
                <tr>
                  <td colSpan="7" className="user_view no-data-found">
                    {loading ? (
                      <Loader loading={loading} />
                    ) : isAddNewWashInventory ? null : (
                      "No data found"
                    )}
                  </td>
                </tr>
              )}
              {isAddNewWashInventory ? <tr>{addNewWashInventory()}</tr> : null}
            </tbody>
          </Table>
        </Form>
      </Row>
      <DialogBoxModal
        show={dialogBox.isDialogboxOpen}
        name={dialogBox.dialogboxName}
        onHide={() => closeConfirmationDialogBox()}
        onEvent={() => deleteWashDetailsData()}
        message={dialogBox.dialogBoxMessage}
        icon={dialogBox.dialogBoxIcon}
        isConfirmationDialogBox={dialogBox.isConfirmationDialogBox}
        buttonName="Delete"
      />
      {washDetails.length > 0 && isLoadPagination ? (
        <Pagination
          items={washDetails}
          currentItems={setCurrentWashDetails}
          itemsPerpage={itemsPerpage}
          itemOffset={setItemOffset}
          endOffset={setEndOffset}
          clearSelectedItems={setSelectedItems}
        />
      ) : null}
      {openBulkEditPopup && <BulkEdit 
      show = {openBulkEditPopup} 
      setShow = {setOpenBulkEditPopup}
      leadList = {leadList}
      ids = {selectedItems}
      
      bulkEdit = {bulkEditWashDetails}>
      </BulkEdit>}

    </div>
  );
}

export default WashDetails;
