import moment from "moment";

export function isValidPhoneNumber(phoneNumber) {
  if (!phoneNumber || phoneNumber.length < 10 || phoneNumber.length > 13) {
    return false;
  }
  return true;
}

export function isValidMinimumCharge(minimumCharge) {
  if (!minimumCharge || minimumCharge.length > 5) {
    return false;
  }
  return true;
}

export function isEmptyString(data) {
  data = data.toString();
  if (!(data.trim()).length) {
    return false;
  } else {
    return true;
  }
}

export function isValidZipcode(zipcode) {
  if (!zipcode || zipcode.length !== 5) {
    return false;
  }
  return true;
}

export function isValidEmail(email) {
  if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
    return true;
  }
  return false;
}

export function isPreviousDate(date){
  let today = moment(new Date()).format("YYYY-MM-DD");
  return moment(today).isAfter(date, 'day'); 
}

export function isValidNewInvoiceDate(date){
  // let today = moment(new Date()).format("YYYY-MM-DD");
  let tomorrow  = moment(new Date()).add(1,'days').format("YYYY-MM-DD");

  return moment(tomorrow).isAfter(date, 'day'); 
}