import { ORG_ID } from "../components/common/constant";
import { authFetch } from "./authProvider";

const SERVICE_URL = "account-service/v1/organizations/" + ORG_ID;


export const getAccountMiscellaneous = async (accountId) => {
  let url = SERVICE_URL + "/accounts/" + accountId + "/miscellaneous";
  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const addAccountMiscellaneous = async (accountId,washtypeId,specialRate) => {
  let url = SERVICE_URL + "/accounts/" + accountId + "/miscellaneous";
  return await authFetch("post", url, { "washtypeId": washtypeId, "specialRate": specialRate })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};


export const editAccountMiscellaneous = async (accountId, washtypeId, oldWashtypeId,specialRate) => {
  let url = SERVICE_URL + "/accounts/" + accountId + "/miscellaneous/" + oldWashtypeId;
  return await authFetch("patch", url, { "newWashtypeId": washtypeId, "specialRate": specialRate })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const deleteMiscellaneousAccounts = async (accountId, miscellaneousId) => {
  let url = SERVICE_URL + "/accounts/" + accountId + "/miscellaneous/" + miscellaneousId;
  return await authFetch("delete", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};