import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';

function AccountLocationCard(props) {
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col md={12}>
                        <h5>{props.accountLocationAddress.locationName}</h5>
                        <p>{props.accountLocationAddress.addressLine1}</p>
                        <p>{props.accountLocationAddress.addressLine2}</p>
                        <p>{props.accountLocationAddress.city}, {props.accountLocationAddress.state} {props.accountLocationAddress.zip}</p>
                        <p><strong>Instructions:</strong> {props.accountLocationAddress.instruction}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button className="user-button" onClick={()=>(props.handleEditLocation(props.accountLocationAddress))} disabled={!props.accountLocationAddress.accountId}>
                            <label className="labels">Edit</label>
                        </Button>

                        <Button className="user-button" onClick={()=>(props.handleDelete(props.accountLocationAddress))} disabled={!props.accountLocationAddress.accountId || props.numberOfLocations <= 1}>
                            <label className="labels">Delete</label>
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

export default AccountLocationCard;
