import { ORG_ID } from "../components/common/constant";
import { authFetch } from "./authProvider";
import { formatDateToUTC } from "../components/common/helper";

const SERVICE_URL = "account-invoice-service/v1/organizations/" + ORG_ID;
const SERVICE_URL_V2 = "invoice-service/v2/organizations/" + ORG_ID;

export const invoiceSummary = async (
  invoiceType,
  accountId,
  startDate,
  endDate
) => {
  let url = 
    SERVICE_URL_V2 + "/accounts/" + accountId + "/invoices-status/" + invoiceType + "/fromDate/" + startDate + "/toDate/" + endDate;

  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return error.response;
    });
};

export const isRefreshTokenValid = async (invoiceType) => {
  let url = SERVICE_URL + "/checkRefreshToken";
  return await authFetch("get", url, {})
    .then(function(response) {
      console.log(response);
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return error.response;
    });
};

export const invoiceItemPreviewV2 = async (invoiceData) => {
  let url =
    SERVICE_URL_V2 +
    "/accounts/" +
    invoiceData.accountId +
    "/invoices/" +
    invoiceData.invoiceNumber +
    "/preview";
  const headers = {
    "Accept": "application/pdf",
  };
  return await authFetch("get", url, {}, headers, "arraybuffer", "binary")
    .then(function(response) {
      console.log(response);
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return error.response;
    });
};

export const invoiceStatusUpdate = async (invoiceData) => {
  let url =
    SERVICE_URL_V2 +
    "/accounts/" +
    invoiceData.accountId +
    "/invoices/update-status";
  return await authFetch("post", url, {
    invoiceNumbers: invoiceData.invoiceNumbersToUpdate,
    invoiceStatus: invoiceData.invoiceStatus,
  })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return error.response;
    });
};

export const invoiceRegenerate = async (invoiceData) => {
  let url =
    SERVICE_URL +
    "/accounts/" +
    invoiceData.accountId +
    "/invoices/" +
    invoiceData.invoiceNumber +
    "/regenerate";
  return await authFetch("post", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const invoiceRegenerateV2 = async (invoiceData) => {
  let url =
    SERVICE_URL_V2 +
    "/accounts/" +
    invoiceData.accountId +
    "/invoices/" +
    invoiceData.invoiceNumber +
    "/regenerate";
  return await authFetch("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const invoiceOndemand = async (accountId, washInventoryData) => {
  let url =
    SERVICE_URL_V2 +
    "/accounts/" +
      accountId +
    "/invoice-ondemand";
  return await authFetch("post", url, washInventoryData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const invoiceSend = async (invoiceData) => {
  let url =
    "quickbook-integration-service/v1/organizations/" +
    ORG_ID +
    "/accounts/" +
    invoiceData.accountId +
    "/invoices/" +
    invoiceData.invoiceNumber +
    "/toQuickBook";
  return await authFetch("post", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const invoiceSendV2 = async (invoiceData) => {
  let url =
    "quickbook-integration-service/v2/organizations/" + ORG_ID +
    "/accounts/" +
    invoiceData.accountId +
    "/invoices/" +
    invoiceData.invoiceNumber +
    "/toQuickBook";
  return await authFetch("post", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const attachPdfService = async (invoiceData) => {
  let url =
    "quickbook-integration-service/v1/organizations/" +
    ORG_ID +
    "/accounts/" +
    invoiceData.accountId +
    "/invoices/" +
    invoiceData.invoiceNumber +
    "/attach-pdf";
  return await authFetch("post", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};
