import { login } from "./authProvider";
import axios from "../components/common/axios";
import { ORG_ID } from "../components/common/constant";

const SERVICE_URL = "auth-service/v2/organizations/" + ORG_ID;

export const loginUser = async (userData) => {
  return await axios
    .post(SERVICE_URL + "/login", userData)
    .then(function (response) {
      let userData = response.data.response;
      localStorage.setItem("user", JSON.stringify(userData));
      let session = {
        refreshToken: userData.refreshToken,
        accessToken: userData.token,
      };
      login(session);
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return {};
    });
};

export const updateToken = async (refreshToken) => {
  let employeeId = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user")).employeeId
    : null;
  let data = {
    refreshToken: refreshToken,
    employeeId: employeeId,
  };
  return await axios
    .post(SERVICE_URL + "/renew-token", data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return {};
    });
};
