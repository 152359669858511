import React, { useState, useEffect } from "react";
import {
  Row,
  Button,
  Form,
  Col,
  Table,
  FormCheck,
  Image,
} from "react-bootstrap";
import Loader from "../common/Loader";
import {
  isEmptyString,
  isValidEmail,
  isValidPhoneNumber,
} from "../common/formValidator";
import "./Account.css";
import "../common/Common.css";
import asc from "../../assets/images/asc.png";
import desc from "../../assets/images/desc.png";
import tick from "../../assets/images/tick.png";
import cancel from "../../assets/images/cancel.png";
import required from "../../assets/images/success.png";
import notRequired from "../../assets/images/cancelRed.png";
import { ACTIVEROWCOLOR } from "../common/constant";

function AccountContactInfo(props) {
  const [contactTitle, setContactTitle] = useState("");
  const [contactTitleError, setContactTitleError] = useState(false);

  const [contactName, setContactName] = useState("");
  const [contactNameError, setContactNameError] = useState(false);

  const [contactEmail, setContactEmail] = useState("");
  const [contactEmailError, setContactEmailError] = useState(false);

  const [contactNumber, setContactNumber] = useState("");
  const [contactNumberError, setContactNumberError] = useState(false);

  const [isSmsRequired, setIsSmsRequired] = useState(false);

  const [isEmailRequired, setIsEmailRequired] = useState(false);

  const [contactInfo, setContactInfo] = useState([]);

  const [isUpdateContact, setIsUpdateContact] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [sortingType, setSortingType] = useState("");

  const [loading, setLoading] = useState(props.isLoading);

  const [isAddContact, setIsAddContact] = useState(false);

  const [selectedContact, setSelectedContact] = useState({});

  const [contactIndexId, setContactIndexId] = useState("");

  const [counter, setCounter] = useState(0);

  const [accountInfo, setAccountInfo] = useState();





  //modal response viewer
  const validationStatus = useFormUpdate(false);

  useEffect(() => {
    console.log("props.contcats", props.contactInfo);
    setContactInfo(
      props.contactInfo
        ? getContactInfoWithCustomIndexId(props.contactInfo)
        : []
    );
    setContactTitleError(false);
    setContactEmailError(false);
    setContactNameError(false);
    setContactNumberError(false);
    setLoading(false);
    setCounter(props.contactInfo.length);
  }, [props.contactInfo]);

  useEffect(() => {
    if (validationStatus.state) {
      props.onSave("basicinfo");
      validationStatus.update(false);
    }
  }, [validationStatus.state]);

  function getContactInfoWithCustomIndexId(contacts) {
    let newList = [];
    contacts.forEach((contact, index) => {
      contact["contactIndexId"] = index + 1;
      newList.push(contact);
    });
    return newList;
  }

  function validateContactInfoForm() {
    var result = true;
    if (!isEmptyString(contactTitle)) {
      setContactTitleError(true);
      result = false;
    }
    if (!isEmptyString(contactName)) {
      setContactNameError(true);
      result = false;
    }
    if (!isValidEmail(contactEmail)) {
      setContactEmailError(true);
      result = false;
    }
    if (!isValidPhoneNumber(contactNumber)) {
      setContactNumberError(true);
      result = false;
    }
    return result;
  }

  useEffect(() => {
    if (isUpdateContact) {
      setContactIndexId(selectedContact ? selectedContact.contactIndexId : "");
      setContactTitle(selectedContact ? selectedContact.contactTitle : "");
      setContactName(selectedContact ? selectedContact.contactName : "");
      setContactEmail(selectedContact ? selectedContact.contactEmail : "");
      setContactNumber(selectedContact ? selectedContact.contactNumber : "");
      setIsEmailRequired(
        selectedContact ? selectedContact.isEmailRequired : false
      );
      setIsSmsRequired(
        selectedContact ? selectedContact.isSmsRequired : false
      );
    }
  }, [selectedContact]);


  function addNewContact() {
    return (
      <React.Fragment>
        <td></td>
        <td>
          <Form.Group as={Row} controlId="formPlaintextContactName">
            <Col sm="9">
              <Form.Control
                type="text"
                value={contactTitle}
                style={{ borderColor: contactTitleError ? "red" : "" }}
                onChange={(e) => {
                  setContactTitle(e.target.value);
                  setContactTitleError(false);
                }}
              />
            </Col>
          </Form.Group>
        </td>
        <td>
          <Form.Group as={Row} controlId="formPlaintextContactName">
            <Col sm="9">
              <Form.Control
                type="text"
                value={contactName}
                style={{ borderColor: contactNameError ? "red" : "" }}
                onChange={(e) => {
                  setContactName(e.target.value);
                  setContactNameError(false);
                }}
              />
            </Col>
          </Form.Group>
        </td>
        <td>
          <Form.Group as={Row} controlId="formPlaintextContactEmail">
            <Col sm="9">
              <Form.Control
                type="text"
                value={contactEmail}
                style={{ borderColor: contactEmailError ? "red" : "" }}
                onChange={(e) => {
                  setContactEmail(e.target.value.trim());
                  setContactEmailError(false);
                }}
              />
            </Col>
          </Form.Group>
        </td>
        <td>
          <Form.Group as={Row} controlId="formPlaintextContactNumber">
            <Col sm="9">
              <Form.Control
                type="text"
                value={contactNumber}
                style={{ borderColor: contactNumberError ? "red" : "" }}
                onChange={(e) => {
                  setContactNumber(e.target.value);
                  setContactNumberError(false);
                }}
              />
            </Col>
          </Form.Group>
        </td>
        <td>
          <div className="button-cell">
            <Image
              src={isSmsRequired ? required : notRequired}
              className="buttons"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsSmsRequired((prev) => !prev);
              }}
            />
          </div>
        </td>
        <td>
          <div className="button-cell">
            <Image
              src={isEmailRequired ? required : notRequired}
              className="buttons"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsEmailRequired((prev) => !prev);
              }}
            />
          </div>
        </td>
        <td>
          <div className="button-cell">
            <Image
              className="buttons"
              style={{ cursor: "pointer" }}
              src={tick}
              onClick={(e) => {
                let newContact = {
                  contactTitle,
                  contactName,
                  contactEmail,
                  contactNumber,
                  isEmailRequired,
                  isSmsRequired,
                  contactIndexId: isUpdateContact
                    ? contactIndexId
                    : contactInfo.length + 1,
                };
                let newContactList = [];
                if (validateContactInfoForm()) {
                  if (isUpdateContact) {
                    contactInfo.forEach((contact, index) => {
                      if (contact.contactIndexId === selectedItems[0]) {
                        if (contact.id) {
                          newContact["id"] = contact.id;
                        }
                        newContactList.push(newContact);
                      } else {
                        newContactList.push(contact);
                      }
                    });
                    setContactInfo(newContactList);
                    setSelectedItems([]);
                  } else {
                    setContactInfo([...contactInfo, newContact]);
                    newContactList = [...contactInfo, newContact];
                  }
                  props.onUpdate(newContactList);
                  clearAddContactFields();
                }
              }}
            />
            <Image
              className="buttons"
              style={{ cursor: "pointer" }}
              src={cancel}
              onClick={(e) => {
                clearAddContactFields();
              }}
            />
          </div>
        </td>
      </React.Fragment>
    );
  }

  function clearAddContactFields() {
    setIsAddContact(false);
    setIsUpdateContact(false);
    setContactTitle("");
    setContactName("");
    setContactEmail("");
    setContactNumber("");
    setIsEmailRequired(false);
    setIsSmsRequired(false);
    setSelectedContact({});
  }

  function setContactInfoList() {
    return contactInfo.map((obj, index) => {
      return isUpdateContact && selectedItems[0] === obj.contactIndexId ? (
        <tr className="acc-table-row" key={obj.contactIndexId}>
          {addNewContact()}
        </tr>
      ) : (
        <tr
          key={obj.contactIndexId}
          className="acc-table-row"
          style={{
            backgroundColor: selectedItems.includes(obj.contactIndexId)
              ? ACTIVEROWCOLOR
              : "",
          }}
          onClick={() => {
            setSelectedItems([obj.contactIndexId]);
            clearAddContactFields();
            selectedItems.length > 1
              ? setSelectedItems([obj.contactIndexId])
              : selectedItems[0] === obj.contactIndexId
              ? setSelectedItems([])
              : setSelectedItems([obj.contactIndexId]);
          }}
        >
          <td>
            <FormCheck
              name={obj.contactIndexId}
              checked={selectedItems.includes(obj.contactIndexId)}
              onChange={(e) => {
                let updatedList = [];
                if (e.target.checked) {
                  updatedList = [...selectedItems, obj.contactIndexId];
                } else {
                  updatedList = selectedItems.filter(function(item) {
                    return item !== obj.contactIndexId;
                  });
                }
                setSelectedItems(updatedList);
              }}
            />
          </td>
          <td>{obj.contactTitle}</td>
          <td>{obj.contactName}</td>
          <td>{obj.contactEmail}</td>
          <td>{obj.contactNumber}</td>
          <td>
            <div className="button-cell">
              <Image
                src={obj.isSmsRequired ? required : notRequired}
                className="buttons"
              />
            </div>
          </td>
          <td>
            <div className="button-cell">
              <Image
                src={obj.isEmailRequired ? required : notRequired}
                className="buttons"
              />
            </div>
          </td>
        </tr>
      );
    });
  }

  function sortField(sortingField) {
    switch (sortingField) {
      case "contactTitleField":
        contactInfo.sort(function(a, b) {
          var nameA = a.contactTitle; // ignore upper and lowercase
          var nameB = b.contactTitle; // ignore upper and lowercase
          if (sortingType === "contactTitleASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "contactNameField":
        contactInfo.sort(function(a, b) {
          var nameA = a.contactName.toUpperCase(); // ignore upper and lowercase
          var nameB = b.contactName.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "contactNameASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
      case "contactEmailField":
        contactInfo.sort(function(a, b) {
          var nameA = a.contactEmail.toUpperCase(); // ignore upper and lowercase
          var nameB = b.contactEmail.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "contactEmailASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "contactNumberField":
        contactInfo.sort(function(a, b) {
          var nameA = a.contactNumber; // ignore upper and lowercase
          var nameB = b.contactNumber; // ignore upper and lowercase
          if (sortingType === "contactNumberASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      default:
        break;
    }
    setSelectedContact([]);
    setSelectedItems([]);
  }

  function tableFieldsHeading(labelName, fieldName) {
    return (
      <div className="table-fields-heading">
        <label className="table-fields-labels">{labelName}</label>
        {fieldName === null ? null : (
          <div className="sorting-div">
            <Image
              src={asc}
              className="sorting-btns"
              style={{ cursor: "pointer" }}
              onClick={() => (
                setSortingType(fieldName + "ASC"),
                sortField(fieldName + "Field")
              )}
            ></Image>
            <Image
              src={desc}
              className="sorting-btns"
              style={{ cursor: "pointer" }}
              onClick={() => (
                setSortingType(fieldName + "DSC"),
                sortField(fieldName + "Field")
              )}
            ></Image>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <Row>


        <Col sm="8" className="top-buttons-div align-items-end">
          <Row style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Col lg="2">
              <Button
                className="user-button custom-button-width"
                disabled={isUpdateContact || counter > 4}
                onClick={(e) => {
                  setIsAddContact(true);
                  setSelectedItems([]);
                }}
              >
                <label className="labels">Add</label>
              </Button>
            </Col>
            <Col lg="2">
              <Button
                className="user-button custom-button-width"
                disabled={selectedItems.length !== 1}
                onClick={(e) => {
                  contactInfo.forEach((contact, index) => {
                    if (contact.contactIndexId === selectedItems[0]) {
                      setSelectedContact(contact);
                    }
                  });
                  setIsUpdateContact(true);
                }}
              >
                <label className="labels">Edit</label>
              </Button>
            </Col>
            <Col lg="2">
              <Button
                className="user-button custom-button-width"
                disabled={selectedItems.length === 0}
                onClick={async (e) => {
                  let newContactList = [];
                  contactInfo.forEach((contact, index) => {
                    if (!selectedItems.includes(contact.contactIndexId)) {
                      newContactList.push(contact);
                    }
                  });
                  setContactInfo(newContactList);
                  setSelectedItems([]);
                  props.onUpdate(newContactList);
                }}
              >
                <label className="labels">Delete</label>
              </Button>
            </Col>
            <Col lg="2">
              <Button
                className="user-button custom-button-width"
                onClick={(e) => {
                  e.preventDefault();
                  props.onUpdate(contactInfo, accountInfo);
                  validationStatus.update(true);
                }}
              >
                <label className="labels">Save</label>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Row style={{ margin: "auto" }}>
          <Form>
            <Table className="table">
              <thead>
                <tr className="table-heading">
                  <th>
                    <div className="table-fields-heading">
                      <FormCheck
                        checked={
                          selectedItems.length === contactInfo.length &&
                          contactInfo.length > 0
                        }
                        onChange={(e) => {
                          let newList = [];
                          if (e.target.checked) {
                            contactInfo.forEach((item, index) =>
                              newList.push(item.contactIndexId)
                            );
                          }
                          setSelectedItems(newList);
                        }}
                      />
                    </div>
                  </th>
                  <th>{tableFieldsHeading("Contact Title", "contactTitle")}</th>
                  <th>{tableFieldsHeading("Contact Name", "contactName")}</th>
                  <th>{tableFieldsHeading("Contact Email", "contactEmail")}</th>
                  <th>
                    {tableFieldsHeading("Contact Number", "contactNumber")}
                  </th>
                  <th>{tableFieldsHeading("SMS", null)}</th>
                  <th>{tableFieldsHeading("Email", null)}</th>
                  <th>{tableFieldsHeading("", null)}</th>
                  {isAddContact || isUpdateContact ? <th></th> : null}
                </tr>
              </thead>
              <tbody>
                {contactInfo.length > 0 ? (
                  setContactInfoList()
                ) : !isAddContact ? (
                  <tr>
                    <td colSpan="4" className="user_view no-data-found">
                      {loading ? <Loader loading={loading} /> : "No data found"}
                    </td>
                  </tr>
                ) : null}
                {isAddContact ? <tr>{addNewContact()}</tr> : null}
              </tbody>
            </Table>
          </Form>
        </Row>
      </Row>
    </div>
  );
}
function useFormUpdate(initialState) {
  const [state, setstate] = useState(initialState);

  function update(value) {
    setstate(value !== null ? value : "");
  }
  return {
    state,
    update,
  };
}

export default AccountContactInfo;
