import React, { useEffect, useState } from "react";
import { Modal, Row, Button, Form, FormCheck, Col } from "react-bootstrap";
import Loader from "../common/Loader";
import './AddOrEditModal.css';

function AddOrEditAccountMiscTypeModal(props) {
    const [miscTypeInfo, setMiscTypeInfo] = useState({});
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [miscTypes, setMiscTypes] = useState([]);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
    useEffect(() => {
        setMiscTypes(props.miscTypes);
        if (props.isEditMode && props.selectedMiscType) {
            setMiscTypeInfo({
                washTypeId: props.selectedMiscType.washTypeId || 0,
                washTypeName: props.selectedMiscType.washTypeName || '',
                specialRate: props.selectedMiscType.specialRate || 0,
                locationNumber: props.locationNumber,
            });
        } else {
            setMiscTypeInfo({
                washTypeId: 0,
                washTypeName: '',
                specialRate: 0,
                locationNumber: props.locationNumber,
            });
        }
        setIsSaveButtonDisabled(false);
    }, [props]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMiscTypeInfo((prev) => ({ ...prev, [name]: value }));
        setIsSaveButtonDisabled(false);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!miscTypeInfo.washTypeId) {
            newErrors.washTypeId = 'Wash Type is required';
        }
        if (!miscTypeInfo.specialRate) {
            newErrors.specialRate = 'Rate is required';
        }
        setErrors(newErrors);
        const result = Object.keys(newErrors).length === 0;
        setIsFormValid(result);
        return result;
    };

    const handleSave = () => {
        console.log("Saving wash type info:", miscTypeInfo);
        const isValidForm = validateForm();
        if (isValidForm) {
            if(props.isEditMode){
                props.handleUpdateMiscType(miscTypeInfo);
            } else {
                props.handleCreateAccountMiscType(miscTypeInfo);
            }
        }
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop="static"
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Loader className="loader-custom" loading={props.loading} />
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.isEditMode ? "Update Account Miscellaneous Type" : "Add New Miscellaneous Type"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group as={Row} className="mb-3 align-items-center">
                        <Form.Label column sm="4">Miscellaneous Type</Form.Label>
                        <Col sm="8">
                            <Form.Select
                                value={miscTypeInfo.washTypeId}
                                name="washTypeId"
                                onChange={handleChange}
                                isInvalid={!!errors.washTypeId && !miscTypeInfo.washTypeId}
                            >
                                <option>Select</option>
                                {miscTypes.map((washType) => (
                                    <option key={washType.id} value={washType.id}>
                                        {washType.name}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {errors.washTypeId}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 align-items-center">
                        <Form.Label column sm="4">Miscellaneous Rate</Form.Label>
                        <Col sm="8">
                            <Form.Control
                                type="text"
                                name="specialRate"
                                value={miscTypeInfo.specialRate}
                                onChange={handleChange}
                                onBlur={validateForm}
                                isInvalid={!!errors.specialRate && !miscTypeInfo.specialRate}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.specialRate}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer style={{ display: !props.responseViewer ? "" : "none" }}>
                <Row className="w-100 justify-content-center">
                    <Row className="buttons-style row-style">
                        <Button
                            variant="primary"
                            className="input_button"
                            size="lg"
                            active
                            disabled={isSaveButtonDisabled}
                            onClick={(e) => {
                                e.preventDefault();
                                handleSave();
                                setIsSaveButtonDisabled(true);
                            }}
                        >
                            {props.isEditMode ? "Update" : "Save"}
                        </Button>{" "}
                        <Button
                            variant="primary"
                            className="input_button"
                            size="lg"
                            active
                            onClick={()=>{
                                setErrors({});
                                props.onHide()
                            }
                            }
                        >
                            Cancel
                        </Button>{" "}
                    </Row>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}

export default AddOrEditAccountMiscTypeModal;