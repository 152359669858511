import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "../users/AddNewUser";
import { ORG_ID, UNIT_OF_MEASUREMENT } from "../common/constant";
import tick from "../../assets/images/tick.png";
import cancel from "../../assets/images/cancel.png";
import Image from "react-bootstrap/Image";

function AddNewWashType(props) {
  const [isInsertEvent] = useState(props.event === "insert" ? true : false);
  let selectedWashtype = props.washtype ? props.washtype : {};
  // const id = useFormUpdate(selectedWashtype.id ? selectedWashtype.id : "");
  const code = useFormUpdate(
    selectedWashtype.code ? selectedWashtype.code : ""
  );
  const name = useFormUpdate(
    selectedWashtype.name ? selectedWashtype.name : ""
  );
  const description = useFormUpdate(
    selectedWashtype.description ? selectedWashtype.description : ""
  );
  const unitOfMeasurement = useFormUpdate(
    selectedWashtype.unitOfMeasurement
      ? selectedWashtype.unitOfMeasurement
      : "quantity"
  );
  const defaultRate = useFormUpdate(
    selectedWashtype.rate ? selectedWashtype.rate.toFixed(2) : ""
  );
  const quickbookReferenceId = useFormUpdate(
    selectedWashtype.quickbookReferenceId ? selectedWashtype.quickbookReferenceId : ""
  );
  const washtypeList = useFormUpdate("");
  const codeError = useFormUpdate(false);
  const nameError = useFormUpdate(false);
  const descriptionError = useFormUpdate(false);
  const defaultRateError = useFormUpdate(false);
  const quickbookReferenceIdError = useFormUpdate(false);
  const isWashtypeCodeExist = useFormUpdate(true);
  useEffect(() => {
    washtypeList.update(props.washtypeList);
  }, []);

  function validateForm() {
    let validationStatus = true;
    codeError.update(false);
    nameError.update(false);
    descriptionError.update(false);
    defaultRateError.update(false);
    quickbookReferenceIdError.update(false)

    if (!code.state) {
      validationStatus = false;
      codeError.update(true);
    }
    if (!name.state) {
      validationStatus = false;
      nameError.update(true);
    }
    if (!description.state) {
      validationStatus = false;
      descriptionError.update(true);
    }
    if (!defaultRate.state) {
      validationStatus = false;
      defaultRateError.update(true);
    }
    if(!quickbookReferenceId.state){
      validationStatus = false;
      quickbookReferenceIdError.update(true)
    }
    return validationStatus;
  }

  function isWashtypeCodeAlreadyExist(code) {
    return washtypeList.state.some(function (item) {
      return item.code === code;
    });
  }

  async function upsertWashtype(e) {
    e.preventDefault();
    let validationResult = true;
    validationResult = await validateForm();
    if (!validationResult) {
    } else {
      if (props.event === "update") {
        props.onEvent({
          id: props.washtype.id.toString(),
          name: name.state,
          description: description.state,
          rate: defaultRate.state,
          unitOfMeasurement: unitOfMeasurement.state,
          miscellaneous: props.isMiscType,
          quickbookReferenceId: quickbookReferenceId.state.toString(),
          code: code.state
        });
      } else {
        props.onEvent({
          name: name.state,
          description: description.state,
          rate: defaultRate.state,
          code: code.state,
          unitOfMeasurement: unitOfMeasurement.state,
          miscellaneous: props.isMiscType,
          quickbookReferenceId: quickbookReferenceId.state.toString()
        });
      }
    }
  }

  return (
    <React.Fragment>
      <td></td>
      <td>
        <Form.Group as={Row} controlId="formPlaintextCode">
          <Col>
            <Form.Control
              type="text"
              placeholder="code"
              value={code.state}
              style={{ borderColor: codeError.state ? "red" : "" }}
              onChange={(e) => {
                code.update(e.target.value);
                codeError.update(false);
              }}
              disabled={isInsertEvent ? false : true}
              onBlur={(e) => {
                if (isWashtypeCodeAlreadyExist(e.target.value)) {
                  props.toastMessage('Washtype code already exist.','error');
                  isWashtypeCodeExist.update(true)
                  codeError.update(true);
                }
                else {
                  isWashtypeCodeExist.update(false);
                }
              }}
            />
          </Col>
        </Form.Group>
      </td>

      <td>
        <Form.Group as={Row} controlId="formPlaintextName">
          <Col sm="9">
            <Form.Control
              type="text"
              required
              value={name.state}
              placeholder="Wash Type"
              style={{ borderColor: nameError.state ? "red" : "" }}
              onChange={(e) => {
                name.update(e.target.value);
                nameError.update(false);
              }}
            />
          </Col>
        </Form.Group>
      </td>

      <td>
        <Form.Group as={Row} controlId="formPlaintextDescription">
          <Col sm="9">
            <Form.Control
              type="text"
              value={description.state}
              placeholder="Description"
              style={{
                borderColor: descriptionError.state ? "red" : "",
              }}
              onChange={(e) => {description.update(e.target.value);
                descriptionError.update(false);
              }}
            />
          </Col>
        </Form.Group>
      </td>
      <td>
        <Form.Group as={Row} controlId="formPlaintextUnitOfMeasurement">
          <Form.Select
            value={unitOfMeasurement.state}
            style={{
              width: "90%",
            }}
            onChange={(e) => {
              unitOfMeasurement.update(e.target.value);
            }}
          >
            {UNIT_OF_MEASUREMENT.map((unitOfMeasure) => {
              return (
                <option key={unitOfMeasure} value={unitOfMeasure}>
                  {unitOfMeasure}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </td>

      <td>
        <Form.Group as={Row} controlId="formPlaintextRate">
          <Col sm="9">
            <Form.Control
              type="text"
              value={"$" + defaultRate.state}
              placeholder="Rate"
              style={{
                borderColor: defaultRateError.state ? "red" : "",
              }}
              onChange={(e) =>
                {
                  defaultRateError.update(false);
                  defaultRate.update(e.target.value == "$" || e.target.value == "" ? e.target.value.substring(1)
                  : Number(e.target.value.substring(1)) ? e.target.value.substring(1) : null);
                 ;
                  }
              }
            />
          </Col>
        </Form.Group>
      </td>
      <td>
        <Form.Group as={Row} controlId="formPlaintextQuickbookReferenceId">
          <Col sm="9">
            <Form.Control
              type="text"
              value={quickbookReferenceId.state}
              placeholder="QB-RefId"
              style={{
                borderColor: quickbookReferenceIdError.state ? "red" : "",
              }}
              onChange={(e) =>
                {
                  quickbookReferenceIdError.update(false);
                  quickbookReferenceId.update(!isNaN(+e.target.value) ? e.target.value : 0);
                 ;
                  }
              }
            />
          </Col>
        </Form.Group>
      </td>

      <td>
        <div className="button-cell">
          <Image
            className="buttons"
            style={{ cursor: "pointer",display: isInsertEvent ? isWashtypeCodeExist.state ? 'none' : 'block' : 'block'}}
            src={tick}
            onClick={(e) => upsertWashtype(e)}
          />
          <Image
            className="buttons"
            style={{ cursor: "pointer" }}
            src={cancel}
            onClick={props.onCancelNewWashType}
          />
        </div>
      </td>
    </React.Fragment>
  );
}
function useFormUpdate(initialState) {
  const [state, setstate] = useState(initialState);

  function update(value) {
    setstate(value);
  }
  return {
    state,
    update,
  };
}
export default AddNewWashType;
