import React, { useState, useEffect } from "react";
import { getAllAccountFleets } from "../../service/accountService";
import "./Account.css";
import "../common/Common.css";
import { Row, Tab, Tabs } from "react-bootstrap";
import FleetList from "./FleetList";
import { getWashType } from "../../service/washTypeService";

function AccountFleetsInfo(props) {
    const [accountId, setAccountId] = useState(0);
    const [accountFleetList, setAccountFleetList] = useState([]);
    const [key, setKey] = useState('');
    const [washTypeList, setWashtypeList] = useState([]);

    const fetchGetAllAccountFleet = async (accountId) => {
        if(accountId){
            var result = await getAllAccountFleets(accountId);
            if (result.status === 200) {
                const fleetList = result.data.accountFleets ? result.data.accountFleets : [];
                if(fleetList.length > 0){
                    if (key === '') {
                        setKey(fleetList[0].locationNumber ? fleetList[0].locationNumber : '');
                    }
                }
                setAccountFleetList(fleetList);
            }
        }
    };

    useEffect(() => {
        setAccountFleetList([]);
        fetchWashtypeData();
        setAccountId(props.accountId);
        fetchGetAllAccountFleet(props.accountId);
    }, [props.accountId,props.tabTrigger]);

    const fetchWashtypeData = async () => {
        var result = await getWashType();
        if (result.status === 200) {
            setWashtypeList(result.data.Washtypes ? result.data.Washtypes : []);
        }
    };

    return (
        <>
            { accountFleetList.length > 0 ? (
                <Tabs 
                    className="mb-3 custom-tabs-container"
                    style={{ display: 'flex', flexWrap: 'nowrap' , padding: "10px 10px", marginBottom: '0px !important'}}
                    id="account-location-tab" 
                    activeKey={key} 
                    onSelect={(k) => setKey(k)}
                >
                    {accountFleetList.map((locationFleetList, index) => (
                        <Tab key={index} 
                            eventKey={locationFleetList.locationNumber} 
                            title={locationFleetList.locationName}
                            className="custom-tab"
                            tabClassName="custom-tab-link"
                        >
                            <FleetList
                                toastSuccess={props.toastSuccess}
                                fleetList = {locationFleetList}  
                                washTypeList = {washTypeList}   
                                accountId = {accountId} 
                                refreshList = {fetchGetAllAccountFleet}   
                                locationNumber = {locationFleetList.locationNumber}                       
                            />
                        </Tab>
                    ))}
                </Tabs>
                 ): 
                (
                    <Row className="custom-row">
                        <span style={{textAlign: "center"}}>No Data Found</span>
                    </Row>
                )
            } 
        </>
    );
}

export default AccountFleetsInfo;