import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import "./Pagination.css";
import { Col, Form, Row } from "react-bootstrap";

function Pagination(props) {
  // const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [selectedPageSize, setSelectedPageSize] = useState(props.itemsPerpage);
  const pageSizeOptions = [10, 25, 50, 100, 150, 200, 250];
  
  useEffect(() => {
    const endOffset = itemOffset + selectedPageSize;
    props.itemOffset(itemOffset);
    props.endOffset(endOffset);
    props.currentItems(props.items.slice(itemOffset, endOffset));
    props.clearSelectedItems([]);
    setPageCount(Math.ceil(props.items.length / selectedPageSize));
  }, [itemOffset,selectedPageSize,props.items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * selectedPageSize) % props.items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div>
        <Row>
          <Col sg="2" style={{paddingBottom: "10px"}}>
            <Row style={{width: "fit-content"}}>
              <Col>
                <Form.Label>
                    Page Size
                </Form.Label>
              </Col>
              <Col style={{paddingLeft: "0px"}}>
                <Form.Select
                    style={{width: "80px"}}
                    value={selectedPageSize}
                    disabled={false}
                    onChange={(e) => {
                      setItemOffset(0)
                      setSelectedPageSize(parseInt(e.target.value));
                    }}
                >
                  {pageSizeOptions.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </Form.Select>
              </Col>
          </Row>
            
          </Col>
          <Col sg="8">
            <ReactPaginate
                disableInitialCallback="true"
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                breakClassName="page-item page-item-boxes"
                breakLinkClassName="page-link page-item-contents"
                marginPagesDisplayed={1}
                containerClassName="pagination justify-content-center"
                pageClassName="page-item page-item-boxes"
                pageLinkClassName="page-link page-item-contents"
                previousClassName="page-item page-item-boxes"
                previousLinkClassName="page-link page-item-contents"
                nextClassName="page-item page-item-boxes"
                nextLinkClassName="page-link page-item-contents"
                activeClassName="active active-item"
              />
          </Col>
          <Col sg="2">
            <span style={{ display: "flex", flexDirection: "row-reverse", paddingRight: "1%", fontWeight: "600" }}>Total records: {props.items.length} </span>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Pagination;