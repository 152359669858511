import React, { useState, useEffect } from "react";
import { Modal, Row, Button, Form, Tabs, Tab, Col } from "react-bootstrap";
import Loader from "../common/Loader";
import { isValidPhoneNumber, isEmptyString, isValidZipcode, isValidEmail } from "../common/formValidator";
import "./Account.css";
import "../common/Common.css";
import { getDataFieldValue, isAllFormFieldsFilled, isEmpty } from "../common/helper";

function BasicInfo(props) {
    const name = useFormUpdate("");
    const contactPerson = useFormUpdate("");

    const nameError = useFormUpdate(false);
    const contactPersonError = useFormUpdate(false);

    const email = useFormUpdate("");
    const emailError = useFormUpdate(false);

    const contactNumber = useFormUpdate("");
    const contactNumberError = useFormUpdate(false);

    const website = useFormUpdate("");
    const websiteError = useFormUpdate(false);
    const validationStatus = useFormUpdate(false);

    const [basicInfo, setBasicInfo] = useState({});
    const [accountAddress, setAccountAddress] = useState({});
    const [isBasicInfoChanged, setIsBasicInfoChanged] = useState(false);

    useEffect(() => {
        setBasicInfo(props.basicInfo);
        setAccountAddress(props.accountAddress);
        name.update(getDataFieldValue(props.basicInfo, 'name'));
        contactPerson.update(getDataFieldValue(props.basicInfo, 'contactPerson'));
        contactNumber.update(getDataFieldValue(props.basicInfo, 'contactNumber'));
        email.update(getDataFieldValue(props.accountAddress, 'email'));
        website.update(getDataFieldValue(props.accountAddress, 'website'));
        
        nameError.update(false);
        contactPersonError.update(false);
        emailError.update(false);
        websiteError.update(false);
        contactNumberError.update(false);

    }, [props.basicInfo, props.accountAddress]);

    useEffect(() => {
        props.onUpdate(basicInfo, accountAddress);
    }, [isBasicInfoChanged])

    function setValidationStatus() {
        let fields = [name, contactPerson, email, contactNumber];
        validationStatus.update(isAllFormFieldsFilled(fields));
    }

    function updateBasicInfo(fieldName, value) {
        setBasicInfo(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    }

    function updateAccountAddress(fieldName, value) {
        setAccountAddress(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    }

    return (
        <div className='info-section'>
            <Row>
                <Col>
                    <Row>
                        <div style={{ position: "relative", height: "32px" }}>
                            <div className="top-buttons-div">
                                {/* <Button
                                    className="user-button"
                                    onClick={(e) => {

                                    }
                                    }
                                >
                                    <label className="labels">Cancel</label>
                                </Button> */}
                                <Button
                                    className="user-button"
                                    disabled={!validationStatus.state}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.onSave("address");
                                    }
                                    }
                                >
                                    <label className="labels">Save</label>
                                </Button>
                            </div>
                        </div>
                    </Row>
                    <Row className="page-top-margin">
                        <Row>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextFirstname"
                            >
                                <Row>
                                    <Form.Label column sm="4">
                                        Company Name
                                    </Form.Label>
                                </Row>
                                <Row>
                                    <Col sm="9">
                                        <Form.Control
                                            type="text"
                                            className="input-field"
                                            required
                                            autoComplete="nope"
                                            value={name.state}
                                            //placeholder="Name"
                                            style={{ borderColor: nameError.state ? "red" : "" }}
                                            onBlur={(e) => {
                                                if (!isEmptyString(e.target.value)) {
                                                    nameError.update(true);
                                                    validationStatus.update(false);
                                                }
                                                else {
                                                    updateBasicInfo("name", e.target.value)
                                                    setValidationStatus();
                                                    setIsBasicInfoChanged(!isBasicInfoChanged);
                                                }
                                            }}
                                            onChange={(e) => {
                                                nameError.update(false);
                                                name.update(e.target.value)
                                            }
                                            }
                                        />
                                    </Col>
                                </Row>


                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextContactPerson"
                            >
                                <Row>
                                    <Form.Label column sm="4">
                                        Company Contact Person
                                    </Form.Label>
                                </Row>
                                <Row>
                                    <Col sm="9">
                                        <Form.Control
                                            type="text"
                                            autoComplete="nope"
                                            className="input-field"
                                            value={contactPerson.state}
                                            //placeholder="Contact Person"
                                            style={{
                                                borderColor: contactPersonError.state ? "red" : "",
                                            }}
                                            onBlur={(e) => {
                                                if (!isEmptyString(e.target.value)) {
                                                    contactPersonError.update(true);
                                                    validationStatus.update(false);
                                                }
                                                else {
                                                    updateBasicInfo("contactPerson", e.target.value)
                                                    setValidationStatus();
                                                    setIsBasicInfoChanged(!isBasicInfoChanged);
                                                }
                                            }}
                                            onChange={(e) => {
                                                contactPersonError.update(false);
                                                contactPerson.update(e.target.value)
                                            }
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextEmail"
                            >
                                <Row>
                                    <Form.Label column sm="4">
                                        Email
                                    </Form.Label>
                                </Row>
                                <Row>
                                    <Col sm="9">
                                        <Form.Control
                                            type="text"
                                            autoComplete="nope"
                                            className="input-field"
                                            value={email.state}
                                            //placeholder="Email"
                                            style={{
                                                borderColor: emailError.state ? "red" : "",
                                            }}
                                            onBlur={(e) => {
                                                if (!isValidEmail(e.target.value)) {
                                                    emailError.update(true);
                                                    validationStatus.update(false);
                                                }
                                                else {
                                                    updateAccountAddress("email", e.target.value)
                                                    setValidationStatus();
                                                    setIsBasicInfoChanged(!isBasicInfoChanged);
                                                }
                                            }}
                                            onChange={(e) => {
                                                emailError.update(false);
                                                email.update(e.target.value.trim())
                                            }
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextContactNumber"
                            >
                                <Row>
                                    <Form.Label column sm="4">
                                        Contact Number
                                    </Form.Label>
                                </Row>
                                <Row>
                                    <Col sm="9">
                                        <Form.Control
                                            type="text"
                                            className="input-field"
                                            value={contactNumber.state}
                                            autoComplete="nope"
                                            //placeholder="Contact Number"
                                            style={{
                                                borderColor: contactNumberError.state ? "red" : "",
                                            }}
                                            onBlur={(e) => {
                                                if (!isValidPhoneNumber(e.target.value)) {
                                                    contactNumberError.update(true);
                                                    validationStatus.update(false);
                                                }
                                                else {
                                                    updateBasicInfo("contactNumber", e.target.value)
                                                    setValidationStatus();
                                                    setIsBasicInfoChanged(!isBasicInfoChanged);
                                                }
                                            }}
                                            onChange={(e) => {
                                                contactNumberError.update(false);
                                                if (e.target.value.length <= 10 && !isNaN(+e.target.value)) {
                                                    contactNumber.update(e.target.value)
                                                }
                                            }
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextwebsite"
                            >
                                <Row>
                                    <Form.Label column sm="4">
                                        Website<span style={{ color: "#8C8C8C", fontSize: "14px" }}>(optional)</span>
                                    </Form.Label>
                                </Row>
                                <Row>
                                    <Col sm="9">
                                        <Form.Control
                                            type="text"
                                            className="input-field"
                                            value={website.state}
                                            autoComplete="nope"
                                            //placeholder="Website"
                                            style={{
                                                borderColor: websiteError.state ? "red" : "",
                                            }}
                                            onBlur={(e) => {
                                                // if (!isEmptyString(e.target.value)) {
                                                //     // websiteError.update(true);
                                                //     // validationStatus.update(false);
                                                // }
                                                // else {
                                                    updateAccountAddress("website", e.target.value)
                                                    setValidationStatus();
                                                    setIsBasicInfoChanged(!isBasicInfoChanged);
                                                // }
                                            }}
                                            onChange={(e) => {
                                                websiteError.update(false);
                                                website.update(e.target.value)
                                            }
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
function useFormUpdate(initialState) {
    const [state, setstate] = useState(initialState);

    function update(value) {
        setstate(value !== null ? value : "");
    }
    return {
        state,
        update,
    };
}
export default BasicInfo
