import React, { useState, useEffect } from "react";
import "./Account.css";
import "../common/Common.css";
import { createAccountLocation, deleteAccountLocation, getAccountLocations, updateAccountLocation } from "../../service/accountService";
import { Button, Col, Row } from "react-bootstrap";
import AccountLocationCard from "./AccountLocationCard";
import AccountLocationModal from "./AccountLocationModal";
import { toast } from "react-toastify";
import { getLoaderToastSettings, getLoaderToastMessage } from "../common/ToastManager";
import DialogBoxModal from "../modal/DialogBoxModal";

function AccountLocationsInfo(props) {
    const [accountLocations, setAccountLocations] = useState([]);
    const [accountLocationAddress, setAccountLocationAddress] = useState({});
    const [accountId, setAccountId] = useState(0);
    const [numberOfLocations, setNumberOfLocations] = useState(0);
    const toastLoader = (toastMessage) => toast.loading(toastMessage, getLoaderToastSettings());
    const toastLoaderUpdate = (id, toastMessage, type) => toast.update(id, getLoaderToastMessage(toastMessage, type));
    const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
    const [isAddOrEditAccountLocationModalOpen, setIsAddOrEditAccountLocationModalOpen] = useState(false);
    const [dialogBox, setDialogBox] = useState({
        isDialogboxOpen: false,
        dialogBoxMessage: "",
        isConfirmationDialogBox: true,
        dialogBoxIcon: "delete",
        dialogboxName: "",
        data: ""
    });

    const fetchAccountLocations = async (accountId) => {
        if(accountId){
            var result = await getAccountLocations(accountId);
            if (result.status === 200) {
                const accountAddressLocations = result.data.accountLocations;
                setNumberOfLocations(accountAddressLocations.length);
                setAccountLocations(accountAddressLocations);
            }
        }
    };

    useEffect(() => {
        setAccountLocations([]);
        setAccountId(props.accountId);
        fetchAccountLocations(props.accountId);
    }, [props.accountId,props.tabTrigger]);

    const handleDelete = (accountLocationAddress) => {
        setDialogBox({
            isConfirmationDialogBox: true,
            dialogBoxMessage: "Are you sure you want to delete this location?",
            dialogBoxIcon: "delete",
            isDialogboxOpen: true,
            data: accountLocationAddress
        });
    };
    const deleteLocation = async (accountLocationAddress)=>{
        setDialogBox({ isDialogboxOpen: false });
        let toastId = toastLoader("Please wait...");        
        const response = await deleteAccountLocation(parseInt(accountLocationAddress.accountId), parseInt(accountLocationAddress.locationNumber));
        if(response.status === 200){
            toastLoaderUpdate(toastId, "Location deleted successfully!", "success");
            fetchAccountLocations(props.accountId);
        }
        else{
            toastLoaderUpdate(toastId, "Location delete failed!", "error");
        }
    }

    function closeConfirmationDialogBox() {
        setDialogBox({
          isDialogboxOpen: false,
        });
    }
    
    function openIsAddOrEditAccountLocationModal() {
        setIsAddOrEditAccountLocationModalOpen(true);
    }

    function closeIsAddOrEditAccountLocationModal() {
        setIsAddOrEditAccountLocationModalOpen(false);
    }

    const  handleEditLocation = async(locationData) => {
        openIsAddOrEditAccountLocationModal();
        setIsEditModeEnabled(true);
        setAccountLocationAddress(locationData);
    }

    const handleAddLocation = async()=>{
        setIsEditModeEnabled(false);
        openIsAddOrEditAccountLocationModal();
    } 

    const handleSave = async (locationData) => {
        
        let toastId = toastLoader("Please wait...");
        if(isEditModeEnabled){
            const response = await updateAccountLocation(locationData);
            if(response.status === 200){
                setIsEditModeEnabled(false);
                const updatedAccountLocations = accountLocations.map(location => 
                    location.locationNumber === locationData.locationNumber ? locationData : location
                );
                setAccountLocations(updatedAccountLocations);
                toastLoaderUpdate(toastId, "Location updated successfully!", "success");
            }
            else{
                toastLoaderUpdate(toastId, "Location updation failed!", "error");
            }
        }
        else{
            locationData.accountId = accountId;
            const response = await createAccountLocation(locationData);
            if(response.status === 201){
                toastLoaderUpdate(toastId, "Location Created successfully!", "success");
                fetchAccountLocations(accountId);
            }
            else{
                toastLoaderUpdate(toastId, "Location creation failed!", "error");
            }
        }
    };

    return (
        <div className='info-section' style={{ maxHeight: "80vh", overflowY: "auto" }}>
            <div style={{padding: "30px !important"}}>
                <Col className="top-buttons-div mb-6" style={{padding:'10px'}}>
                    <Button className="user-button" onClick={handleAddLocation} disabled = {accountId ? false : true}>
                        <label className="labels">Add</label>
                    </Button>
                </Col>
            </div>
            {
                accountLocations.length <= 0 ? 
                (
                    <Row className="custom-row">
                        <span style={{textAlign: "center"}}>No Location Found</span>
                    </Row>
                ):
                (
                    accountLocations.map((accountLocation, index) => (
                        <Col md={12} key={index} style={{padding:'10px'}}>
                            <AccountLocationCard 
                                key={index}
                                accountLocationAddress={accountLocation} 
                                handleEditLocation={handleEditLocation}
                                handleDelete={handleDelete} 
                                numberOfLocations={numberOfLocations}
                            />
                        </Col>
                    ))
                )
            }

            <AccountLocationModal
                show={isAddOrEditAccountLocationModalOpen} 
                onHide={() => closeIsAddOrEditAccountLocationModal()}
                handleSave={handleSave}
                accountLocationAddress={accountLocationAddress} 
                isEditModeEnabled={isEditModeEnabled}
            />
            <DialogBoxModal
                show={dialogBox.isDialogboxOpen}
                name={dialogBox.dialogboxName}
                onHide={() => closeConfirmationDialogBox()}
                onEvent={() => deleteLocation(dialogBox.data)}
                message={dialogBox.dialogBoxMessage}
                icon={dialogBox.dialogBoxIcon}
                isConfirmationDialogBox={dialogBox.isConfirmationDialogBox}
                buttonName="Delete"
            />
        </div>
    )
}

export default AccountLocationsInfo