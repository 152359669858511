import { Modal, Button } from "react-bootstrap";
import React, { useRef } from "react";
import "./DialogBoxModal.css";
import Loader from "../common/Loader";

function PdfViewModal(props) {
  const downloadPdf = () => {
    const link = document.createElement("a");
    link.href = props.linkToPdfData;
    link.download = "sprayz_invoice_pdf.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      backdrop="static"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        {props.loader ? (
          <Loader loading={true} />
        ) : (
          <div>
            <div className="d-flex align-items-center justify-content-between w-100 my-2">
              <p>
                If you are unable to view the PDF, please download it from here.
              </p>
              <Button variant="outline-primary" onClick={downloadPdf}>
                Download PDF
              </Button>
            </div>
            <iframe
              style={{
                width: "100%",
                height: "600px",
              }}
              src={props.linkToPdfData}
              type="application/pdf"
              title="title"
              name="SS"
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default PdfViewModal;
