import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import Misctypelist from "./Misctypelist";
import Washtypelist from "./Washtypelist";

function Washtype(props) {

  useEffect(() => {
    props.setTab('washtype');
  }, [props]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);
  return (
    <Container className="users_list_view">
      <Tab.Container id="left-user-tabs" defaultActiveKey="washtypes">
        <Row className="page-top-margin">
          <Col sm={2}>
            <Nav
              variant="pills"
              className={
                isMobile
                  ? "flex-row left-side-layout"
                  : "flex-column left-side-layout"
              }
            >
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="washtypes">
                  <label className="label"> Wash Types</label>
                </Nav.Link>
                <Nav.Link className="nav-icon" eventKey="misctypes">
                  <label className="label">Miscellaneous Types</label>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content>
              <Tab.Pane eventKey="washtypes">
                <Washtypelist></Washtypelist>
              </Tab.Pane>
              <Tab.Pane eventKey="misctypes">
                <Misctypelist/>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}

export default Washtype;
