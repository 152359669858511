import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  FormCheck,
  Image,
} from "react-bootstrap";
import asc from "../../assets/images/asc.png";
import desc from "../../assets/images/desc.png";
import { getAllAccountsSummary } from "../../service/accountService";
import { getLeadReport } from "../../service/reportService";
import { listUsers } from "../../service/userService";
import { ACTIVEROWCOLOR } from "../common/constant";
import Loader from "../common/Loader";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { customViewFormatDate, formatDate } from "../common/helper";

function LeadReport(props) {
  const dateFrom = useFormUpdate("");
  const dateTo = useFormUpdate("");
  const filterByTeam = useFormUpdate("");
  const filterByAccount = useFormUpdate("");
  const userList = useFormUpdate([]);
  const accountList = useFormUpdate([]);
  const selectedItems = useFormUpdate([]);
  const loading = useFormUpdate(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [sortingType, setSortingType] = useState("accDateASC");
  const [currentSortingField, setCurrentSortingField] = useState("");

  const [reports, setReports] = useState([]);
  const [downloadReports, setDownloadReports] = useState([]);

  useEffect(() => {
    fetchUserList();
    fetchAccountListSummary();
  }, []);

  useEffect(() => {
    setReportsList();
    let formattedReport = [];
    reports.forEach(item => {
      let obj = {
        "Lead Name": item.leadName,
        "Date of Washing": formatDate(item.washDate),
        "Wash Type": item.name,
        "Quantity": item.numberOfWash
      }
      formattedReport.push(obj);
    });
    setDownloadReports(formattedReport);
  }, [reports]);

  const fetchUserList = async () => {
    let response = await listUsers();
    if (response.status === 200) {
      userList.update(
        response.data
          ? response.data.response.filter(function (item) {
            return item.roleCode === "LEAD";
          })
          : []
      );
    }
  };

  const fetchLeadReport = async () => {

    setCurrentSortingField("accLeadNameField");
    setSortingType("accLeadNameASC");

    let id = props.toastLoader("Please wait...");
    let response = await getLeadReport(filterByAccount.state ? filterByAccount.state : 0, 
      new Date(startDate.setHours(0,0,0)), new Date(endDate.setHours(23,59,59)));
    if (response.status === 200) {
      console.log("fetch acc activity reposrt :", response.data.response);
      setReports(response.data.response);
      props.toastSuccess();
      props.toastLoaderUpdate(id, "Report successfully generated!", "success");
    }
    else if (response.status === 404) {
      props.toastLoaderUpdate(id, "Report not found!", "error");
      setReports([]);
    }
    else {
      props.toastLoaderUpdate(id, "Report generation failed", "error");
      setReports([]);
    }
    sortField(currentSortingField);
  };

  const fetchAccountListSummary = async () => {
    let response = await getAllAccountsSummary();
    if (response.status === 200) {
      accountList.update(response.data ? response.data.response : []);
    }
  };

  function setReportsList() {
    return reports.map((obj, index) => {
      return (
        <tr
          key={index}
          className="acc-table-row"
          style={{
            backgroundColor: selectedItems.state.includes(index) ? ACTIVEROWCOLOR : ""
          }}
          onClick={() => {
            selectedItems.update([index]);
          }}
        >
          <td style={{ paddingTop: "15px" }}>{index + 1}</td>

          <td style={{ paddingTop: "15px" }}>{obj.leadName}</td>
          <td style={{ paddingTop: "15px" }}>{customViewFormatDate(obj.washDate)}</td>
          <td style={{ paddingTop: "15px" }}>{obj.name}</td>
          <td style={{ paddingTop: "15px" }}>{obj.numberOfWash}</td>

        </tr>
      );
    });
  }

  function sortField(sortingField) {
    switch (sortingField) {
      case "accDateOfWashingField":
        reports.sort(function (a, b) {
          var nameA = a.washDate;
          var nameB = b.washDate;
          if (sortingType === "accDateOfWashingASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "accLeadNameField":
        reports.sort(function (a, b) {
          var nameA = a.leadName.toUpperCase(); // ignore upper and lowercase
          var nameB = b.leadName.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "accLeadNameASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "accQuantityField":
        reports.sort(function (a, b) {
          var nameA = a.numberOfWash;// ignore upper and lowercase
          var nameB = b.numberOfWash; // ignore upper and lowercase
          if (sortingType === "accQuantityASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "accWashtypeField":
        reports.sort(function (a, b) {
          var nameA = a.name.toUpperCase();// ignore upper and lowercase
          var nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "accWashtypeASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      default:
        break;
    }
  }

  function tableFieldsHeading(labelName, fieldName) {

    const toggleFilter = () =>{
      if(sortingType.endsWith("ASC")){
        setSortingType(fieldName + "DSC");
      }else{
        setSortingType(fieldName + "ASC");
      }
      setCurrentSortingField(fieldName + "Field");
      sortField(fieldName + "Field")
    }

    const filterIcon = () =>{
      if(fieldName+"Field" === currentSortingField){
        if(sortingType.endsWith("ASC")){
          return asc;
        }else{
          return desc;
        }
      }else{
        return null;
      }
    }

    useEffect(() => {
      sortField(currentSortingField);
    }, [sortingType]);
  


    return (
      <div className="table-fields-heading" onClick={()=>{toggleFilter()}}>
        <label className="table-fields-labels">{labelName}</label>
        {fieldName === null ? null : (
          <div className="sorting-div">
             {filterIcon() && (
            <Image
              src={filterIcon()}
              className="sorting-btns"
              style={{ cursor: "pointer" }}
            ></Image>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div style={{ position: "relative" , marginTop: "4%" }}>
      <Row>
        <Col sm={3} className="col-right-space ">
          <Row>
            <Form.Label column sm="6">
              Date Range
            </Form.Label>
          </Row>
          <Row>
            <DatePicker
              className="input-field date-field-width"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
            />

          </Row>
        </Col>
        <Col sm={7} className="col-right-space">
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextFilterByAccount"
          >
            <Row>
              <Form.Label column sm="6">
                Filter By
              </Form.Label>
            </Row>
            <Row>
              <Col sm="8">
                <Form.Select
                  className="custom-width"
                  value={filterByAccount.state}
                  disabled={false}
                  onChange={(e) => {
                    filterByAccount.update(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  {accountList.state.map((obj) => {
                    return (
                      <option key={obj.id} value={obj.id}>
                        {obj.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={2} className="col-right-space button-margin">
          <Col>
            <Button className="user-button"
              onClick={(e) => {
                setReports([]);
                setDateRange([null, null]);
                filterByAccount.update("");
              }
              }
            >
              <label className="labels">Clear</label>
            </Button>
          </Col>
          <Col>
            <Button
              className="user-button"
              onClick={(e) => {
                fetchLeadReport();
              }}
              disabled={!(startDate && endDate)}

            >
              <label className="labels">View</label>
            </Button>
          </Col>
        </Col>
      </Row>
      <Row className="right-download-button">
        <CSVLink
          data={downloadReports}
          className="user-button"
          style={{ width: "100px", display: downloadReports.length > 0 ? "block" : "none" }}
          onClick={() => {
            if (reports.length > 0) {
              props.toastSuccess("Data downlaoded successfully!");
              return true;
            }
            else {
              props.toastError("No data found!");
              return false;
            }
          }}
          filename={"lead_report_" + formatDate(startDate) + "_to_" + formatDate(endDate)}
        >
          <label className="labels">Download</label>
        </CSVLink>
      </Row>
      <Row style={{ marginTop: "2%",maxHeight: "60vh",overflowY: "scroll"}}>
        <Form>
          <Table className="table">
            <thead>
              <tr className="table-heading">
                <th>#</th>
                <th>{tableFieldsHeading("Lead Name", "accLeadName")}</th>
                <th>{tableFieldsHeading("Date of washing", "accDateOfWashing")}</th>
                <th>{tableFieldsHeading("Wash Type", "accWashtype")}</th>
                <th>{tableFieldsHeading("Quantity", "accQuantity")}</th>
              </tr>
            </thead>
            <tbody>
              {reports.length > 0 ? (
                setReportsList()
              ) : (
                <tr>
                  <td colSpan="6" className="user_view no-data-found">
                    {loading.state ? <Loader loading={loading.state} /> : "No data found"}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Form>
      </Row>
    </div>
  );
}

function useFormUpdate(initialState) {
  const [state, setstate] = useState(initialState);

  function update(value) {
    setstate(value !== null ? value : "");
  }
  return {
    state,
    update,
  };
}
export default LeadReport;