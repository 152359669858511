//css
import "./Home.css";
import React, { Component, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
//images
// import toolbarLogo from "../../assets/images/home_page.jpeg";
import toolbarLogo from "../../assets/images/sprayzapp-home.png";
import { useContext } from "react";
import { UserContext } from "../../AppContext";

function Home(props) {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    props.setTab('home');
  }, [props]);

  return (
    //TODO: Remove this after confirming the new UI.
    // <Container style={{minWidth: "fit-content"}}>
    //   <Row className="banner">
    //     <Col className="landing-page-left-content" sm={6}>
    //       MANAGE, TRACK, GROW and KEEP SPRAYING WITH SPRAYZ APP
    //     </Col>
    //     <Col className="landing-page-right-content" sm={6}>
    //       <img
    //         alt="demo"
    //         src={toolbarLogo}
    //         width="100%"
    //         height="100%"
    //         className="d-inline-block align-top left_home_page"
    //       />
    //     </Col>
    //   </Row>
    //   <Row className="blogs">
    //     <Col sm={4}>
    //       <div className="blog-section">
    //         <div className="heading">Truck Fleet Washing</div>
    //         <p>
    //           Our mobile high-pressure cleaning services are, frankly, the best
    //           in Arizona. Your equipment is your livelihood, and we treat it
    //           with the finest soaps and state-of-the-art soft water systems, as
    //           though it were our own.
    //         </p>
    //       </div>
    //       <Button className="blog-button">Learn More</Button>
    //     </Col>
    //     <Col sm={4}>
    //       <div className="blog-section">
    //         <div className="heading">School Bus Fleet Washing</div>
    //         <p>
    //           Children deserve to be safe. Ace Mobile is proud to partner with
    //           school systems across Arizona to clean and protect their buses
    //           against oil, grime, and other degrading elements, using our
    //           expertise to ensure students receive the sanitized and safe
    //           transportation they deserve.
    //         </p>
    //       </div>
    //       <Button className="blog-button">Learn More</Button>
    //     </Col>
    //     <Col sm={4}>
    //       <div className="blog-section">
    //         <div className="heading">Flat Work Power Washing</div>
    //         <p>
    //           Removing algae, dirt buildup, bird droppings, mold and other grime
    //           can save your vehicles and save you money on repair cost.
    //         </p>
    //       </div>{" "}
    //       <Button className="blog-button">Learn More</Button>
    //     </Col>
    //   </Row>
    // </Container>
    <div className="image-container">
      <img
        alt="demo"
        src={toolbarLogo}
        className="center-cropped-image"
      />
    </div>
  );
}

export default Home;
