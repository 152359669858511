import React, { useState, useEffect } from "react";
import { Modal, Row, Button, Form, Tabs, Tab, Col } from "react-bootstrap";
import Loader from "../common/Loader";
import { isEmptyString, isValidZipcode } from "../common/formValidator";
import "./Account.css";
import "../common/Common.css";
import stateList from '../common/states.json';
import { getDataFieldValue, isAllFormFieldsFilled } from "../common/helper";

function AddressInfo(props) {
    const addressLine1 = useFormUpdate("");
    const addressLine1Error = useFormUpdate(false);

    const addressLine2 = useFormUpdate("");
    const addressLine2Error = useFormUpdate(false);

    const city = useFormUpdate("");
    const cityError = useFormUpdate(false);

    const state = useFormUpdate("");
    const stateError = useFormUpdate(false);

    const zip = useFormUpdate("");
    const zipError = useFormUpdate(false);

    const instruction = useFormUpdate("");
    const instructionError = useFormUpdate(false);
    const validationStatus = useFormUpdate(false);
    const [isAccountAddressChanged, setIsAccountAddressChanged] = useState(false);
    const [accountAddress, setAccountAddress] = useState({});

    useEffect(() => {
        setAccountAddress(props.accountAddress);
        addressLine1.update(getDataFieldValue(props.accountAddress, 'addressLine1'));
        addressLine2.update(getDataFieldValue(props.accountAddress, 'addressLine2'));
        city.update(getDataFieldValue(props.accountAddress, 'city'));
        state.update(getDataFieldValue(props.accountAddress, 'state'));
        zip.update(getDataFieldValue(props.accountAddress, 'zip'));
        instruction.update(getDataFieldValue(props.accountAddress, 'instruction'));
        
        addressLine1Error.update(false);
        addressLine2Error.update(false);
        cityError.update(false);
        stateError.update(false);
        zipError.update(false);
        instructionError.update(false);        
    }, [props.accountAddress]);
    

    function updateAccountAddress(fieldName, value) {
        setAccountAddress(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    }

    useEffect(() => {
        props.onUpdate(accountAddress);
        // if (validationStatus.state) {
            
        // }
    }, [isAccountAddressChanged])

    function setValidationStatus() {
        let fields = [addressLine1, city, zip, state, instruction];
        validationStatus.update(isAllFormFieldsFilled(fields));
    }

    return (
        <div className='info-section'>
            <Row>
                <Col>
                    <Row>
                        <div style={{ position: "relative", height: "32px" }}>
                            <div className="top-buttons-div">
                                {/* <Button
                                    className="user-button"
                                >
                                    <label className="labels">Cancel</label>
                                </Button> */}
                                <Button
                                    className="user-button"
                                    disabled={!validationStatus.state}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.onSave("financial");
                                    }
                                    }
                                >
                                    <label className="labels">Save</label>
                                </Button>
                            </div>
                        </div>
                    </Row>
                    <Row className="page-top-margin">
                        <Row>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextAddressLine1"
                            >
                                <Row>
                                    <Form.Label column sm="4">
                                        Address Line 1
                                    </Form.Label>
                                </Row>
                                <Row>
                                    <Col sm="9">
                                        <Form.Control
                                            type="text"
                                            className="input-field"
                                            required
                                            autoComplete="nope"
                                            value={addressLine1.state}
                                            //placeholder="Address Line 1"
                                            style={{ borderColor: addressLine1Error.state ? "red" : "" }}
                                            onBlur={(e) => {
                                                if (!isEmptyString(e.target.value)) {
                                                    addressLine1Error.update(true);
                                                    validationStatus.update(false);
                                                }
                                                else {
                                                    updateAccountAddress("addressLine1", e.target.value)
                                                    setValidationStatus();
                                                    setIsAccountAddressChanged(!isAccountAddressChanged);
                                                }
                                            }}
                                            onChange={(e) => {
                                                addressLine1Error.update(false);
                                                addressLine1.update(e.target.value)
                                            }
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextAddressLine2"
                            >
                                <Row>
                                    <Form.Label column sm="6">
                                        Address Line 2 <span style={{ color: "#8C8C8C", fontSize: "14px" }}>(optional)</span>
                                    </Form.Label>
                                </Row>
                                <Row>
                                    <Col sm="9">
                                        <Form.Control
                                            type="text"
                                            className="input-field"
                                            autoComplete="nope"
                                            value={addressLine2.state}
                                            //placeholder="Address Line 2"
                                            onBlur={(e) => {
                                                updateAccountAddress("addressLine2", e.target.value)
                                                setIsAccountAddressChanged(!isAccountAddressChanged);
                                        }}
                                        onChange={(e) => {
                                            validationStatus.update(true);
                                            addressLine2.update(e.target.value)
                                        }
                                        }
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Col sm="3">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        value={city.state}
                                        autoComplete="nope"
                                        //placeholder="City"
                                        style={{ borderColor: cityError.state ? "red" : "" }}
                                        onBlur={(e) => {
                                            if (!isEmptyString(e.target.value)) {
                                                cityError.update(true);
                                                validationStatus.update(false);
                                            }
                                            else {
                                                updateAccountAddress("city", e.target.value);
                                                setValidationStatus();
                                                setIsAccountAddressChanged(!isAccountAddressChanged);
                                            }
                                        }}
                                        onChange={(e) => {
                                            cityError.update(false);
                                            city.update(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm="3">
                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select
                                        value={state.state}
                                        style={{
                                            borderColor: stateError.state ? "red" : "",
                                        }}
                                        onChange={(e) => {
                                            if (!isEmptyString(e.target.value)) {
                                                stateError.update(true);
                                                validationStatus.update(false);
                                            }
                                            else {
                                                stateError.update(false)
                                                updateAccountAddress("state", e.target.value);
                                                setValidationStatus();
                                                state.update(e.target.value)
                                                setIsAccountAddressChanged(!isAccountAddressChanged);
                                            }
                                        }
                                        }
                                    >
                                        <option value="">Select</option>
                                        {stateList.map((obj) => {
                                            return (
                                                <option key={obj.stateCode} value={obj.stateCode}>
                                                    {obj.name}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm="3">
                                <Form.Group as={Col} controlId="formGridZip">
                                    <Form.Label>Zip</Form.Label>
                                    <Col sm="9">
                                        <Form.Control
                                            type="text"
                                            required
                                            autoComplete="nope"
                                            value={zip.state}
                                            //placeholder="Zip"
                                            style={{ borderColor: zipError.state ? "red" : "" }}
                                            onBlur={(e) => {
                                                if (!isValidZipcode(e.target.value)) {
                                                    zipError.update(true);
                                                    validationStatus.update(false);
                                                }
                                                else {
                                                    updateAccountAddress("zip", e.target.value);
                                                    setValidationStatus();
                                                    setIsAccountAddressChanged(!isAccountAddressChanged);
                                                }
                                            }}
                                            onChange={(e) => {
                                                zipError.update(false)
                                                if (e.target.value.length <= 5 && !isNaN(+e.target.value)) {
                                                    zip.update(e.target.value)
                                                }
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextinstruction"
                            >
                                <Row>
                                    <Form.Label column sm="4">
                                        Instructions
                                    </Form.Label>
                                </Row>
                                <Row>
                                    <Col sm="9">
                                        <Form.Control
                                            type="text"
                                            autoComplete="nope"
                                            className="input-field"
                                            value={instruction.state}
                                            //placeholder="Instructions"
                                            style={{
                                                borderColor: instructionError.state ? "red" : "",
                                            }}
                                            onBlur={(e) => {
                                                if (!isEmptyString(e.target.value)) {
                                                    instructionError.update(true);
                                                    validationStatus.update(false);
                                                }
                                                else {
                                                    updateAccountAddress("instruction", e.target.value);
                                                    setValidationStatus();
                                                    setIsAccountAddressChanged(!isAccountAddressChanged);
                                                }
                                            }}
                                            onChange={(e) => {
                                                instructionError.update(false);
                                                instruction.update(e.target.value)
                                            }
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
function useFormUpdate(initialState) {
    const [state, setstate] = useState(initialState);

    function update(value) {
        setstate(value !== null ? value : "");
    }
    return {
        state,
        update,
    };
}
export default AddressInfo
