//css
import "./Login.css";
//components
import React from "react";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Loader from "../common/Loader";
import Image from "react-bootstrap/Image";
//images
import showPassword from "../../assets/images/showPassword.png";
import dontShowPassword from "../../assets/images/dontShowPassword.png";
import { resetPassword } from "../../service/userService";
import { toast } from "react-toastify";
import { getToastSettings } from "../common/ToastManager";

function ResetPassword(props) {
    const toastSuccess = (toastMessage) => toast.success(toastMessage, getToastSettings());
    const toastError = (toastMessage) => toast.error(toastMessage, getToastSettings());
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [errormsg, setErrorMsg] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [newPasswordType, setNewPasswordType] = useState("password");

    async function initiateResetPassword() {
        setLoading(true);
        var data = { userName: username, password: password, newPassword: newPassword };
        var response = await resetPassword(data);
        if (response.status === 200) {
           toastSuccess('Password reset successfully.');
           props.onReset();
        } else {
            toastError("Password reset failed.");
        }
        setLoading(false);
    }

    const isEmptyString = (strValue) => {
        if (!strValue.trim().length) {
            return false;
        } else {
            return true;
        }
    };

    async function getCredentials(e) {
        e.preventDefault();
        var flag = false;
        if (isEmptyString(username) && isEmptyString(password) && isEmptyString(newPassword)) {
            flag = true;
        }
        if (flag) {
            initiateResetPassword();
        }
    }
    return (
        <div className="login_window">
            <div className="login-head">
                <div className="login-heading" style={{ fontSize: "24px" }}>Reset your password.</div>
            </div>
            <div className="login-form">
                <Form.Label className="login-headline">Username</Form.Label>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Username"
                        id="username"
                        className="input_item"
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </InputGroup>
                <Form.Label className="login-headline">Current Password</Form.Label>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Password"
                        type={passwordType}
                        className="input_item"
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Image
                        className="password-visible-btn"
                        src={passwordType === "" ? showPassword : dontShowPassword}
                        height="60%"
                        style={{ cursor: "pointer" }}
                        onClick={(e) =>
                            passwordType === ""
                                ? setPasswordType("password")
                                : setPasswordType("")
                        }
                    ></Image>
                </InputGroup>
                <Form.Label className="login-headline">New Password</Form.Label>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Password"
                        type={newPasswordType}
                        className="input_item"
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <Image
                        className="password-visible-btn"
                        src={newPasswordType === "" ? showPassword : dontShowPassword}
                        height="60%"
                        style={{ cursor: "pointer" }}
                        onClick={(e) =>
                            newPasswordType === ""
                                ? setNewPasswordType("password")
                                : setNewPasswordType("")
                        }
                    ></Image>
                </InputGroup>
                {errormsg === "" ? (
                    ""
                ) : (
                    <div className="credential_message" id="credential_error">
                        {errormsg}
                    </div>
                )}
                <Button
                    variant="primary"
                    className="login-btn"
                    size="lg"
                    active
                    disabled={!(username && password && newPassword)}
                    onClick={getCredentials}
                >
                    {loading ? (
                        <Loader
                            loading={loading}
                            loader={"loader  loader-login"}
                            loaderColor={"#ffffff"}
                            size={4}
                        />
                    ) : (
                        <div className="login-btn-label">Reset</div>
                    )}
                </Button>{" "}
            </div>
        </div>
    );
}

export default ResetPassword;