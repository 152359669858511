import React, { useState, useEffect } from "react";
import {
    Row,
    Button,
    Form,
    InputGroup,
    FormControl,
    Image,
    Table,
    FormCheck,
    Col
} from "react-bootstrap";
import DialogBoxModal from "../modal/DialogBoxModal";
import "./Account.css";
import searchIcon from "../../assets/images/search.png";
import asc from "../../assets/images/asc.png";
import desc from "../../assets/images/desc.png";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination";
import { deleteAccounts, getAccount } from "../../service/accountService";
import { getAccountMiscellaneous  } from "../../service/accountMiscellaneous";
import { searchObjects } from "../common/helper";
import { ACTIVEROWCOLOR } from "../common/constant";
import ToolTip from "../common/ToolTip";

function AccountList(props) {
    //pagination
    const itemsPerpage = 20;
    const [itemOffset, setItemOffset] = useState(0);
    const [endOffset, setEndOffset] = useState(itemsPerpage);
    const [currentAccounts, setCurrentAccounts] = useState([]);

    const [sortingType, setSortingType] = useState("");
    const [accounts, setAccounts] = useState(props.accountList);
    const [tempAccountList, setTempAccountList] = useState(props.accountList);
    const [selectedItems, setSelectedItems] = useState([]);
    const [loading, setLoading] = useState(!props.isLoading);
    const [dialogBox, setDialogBox] = useState({});
    const [isLoadPagination, setIsLoadPagination] = useState(true);
    const [hoveredRow, setHoveredRow] = useState(null);

    useEffect(() => {
        setAccounts(props.accountList);
        setTempAccountList(props.accountList);
    }, [props.accountList]);

    useEffect(() => {
        if (props.onUpdate && selectedItems[0]) {
            getAccountInfo(selectedItems[0]);
        }
    }, [props.onUpdate]);

    useEffect(() => {
        setIsLoadPagination(false);
        setCurrentAccounts(accounts.slice(itemOffset, endOffset))
    }, [accounts]);

    useEffect(() => {
        setIsLoadPagination(true);
    }, [currentAccounts]);

    useEffect(() => {
        if (selectedItems[0] && selectedItems.length === 1) {
            props.onSetLoading(true)
            getAccountInfo(selectedItems[0]);
        }
        else {
            props.onSelect(props.createdData);
        }
    }, [selectedItems]);

    async function getAccountInfo(accountId) {
        let miscellaneousChargesInfo = []
        let miscellaneousChargesResponse = await getAccountMiscellaneous(selectedItems[0]);
        if (miscellaneousChargesResponse.status === 200) {
            miscellaneousChargesInfo = miscellaneousChargesResponse.data.response;
        }
        let accountInfo = {}
        let response = await getAccount(selectedItems[0]);
        if (response.status === 200) {
            accountInfo = response.data.response[0];
        }
        props.onSelect(accountInfo, miscellaneousChargesInfo);
        props.onSetLoading(false)

    }

    function closeConfirmationDialogBox() {
        setDialogBox({
            isDialogboxOpen: false,
        });
    }

    async function deleteAccountsFromList() {
        let id = props.toastLoader("Please wait...");
        var response = await deleteAccounts(selectedItems);
        if (response.status === 200) {
            props.toastSuccess();
            setSelectedItems([]);
            props.onSelect([]);
            props.onDelete();
            props.toastLoaderUpdate(id, "Accounts successfully deleted!", "success");
        } else {
            props.toastLoaderUpdate(id, "Accounts deletion failed!", "error");
        }
    }

    function setSelectedItem(isChecked, accountId) {
        let updatedList = [];
        if (isChecked) {
            updatedList = [...selectedItems, accountId];
        } else {
            updatedList = selectedItems.filter(function (item) {
                return item !== accountId;
            });
        }
        setSelectedItems(updatedList);
    }

    function setAccountList() {
        return currentAccounts.map((obj, index) => {
            return (
                <tr
                    key={obj.id + "_" + index}
                    className="acc-table-row"
                    style={{
                        backgroundColor: selectedItems.includes(obj.id) ? ACTIVEROWCOLOR : ""
                    }}
                    onClick={() => {
                        setSelectedItems([obj.id]);
                        props.addNewAccount();
                        selectedItems.length > 1
                            ? setSelectedItems([obj.id])
                            : selectedItems[0] === obj.id
                                ? setSelectedItems([])
                                : setSelectedItems([obj.id]);
                    }}
                    onMouseEnter={() => setHoveredRow(obj.id)}
                    onMouseLeave={() => setHoveredRow(null)}
                >
                    <td style={{ paddingTop: "15px" }}>
                        <FormCheck
                            name={obj.id}
                            checked={selectedItems.includes(obj.id)}
                            onChange={(e) => {
                                // setSelectedItem(e.target.checked, obj.id);
                            }}
                        />
                    </td>

                    <td style={{ paddingTop: "15px" }}>{obj.name}
                        {hoveredRow === obj.id && (
                            <ToolTip message={obj.id} isIconVisible={false}/>
                        )}
                    </td>
                    <td>
                        {!obj.active ? (
                            <Button className="active-button">
                                <label className="labels">Active</label>
                            </Button>
                        ) : (
                            <Button className="inactive-button">
                                <label className="labels">Inactive</label>
                            </Button>
                        )}
                    </td>
                </tr>
            );
        });
    }

    function sortField(sortingField) {
        switch (sortingField) {
            case "accNameField":
                accounts.sort(function (a, b) {
                    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (sortingType === "accNameASC") {
                        return nameA < nameB ? 1 : -1;
                    } else {
                        return nameA < nameB ? -1 : 1;
                    }
                });
                break;
            default:
                break;
        }
        setSelectedItems([]);
        setCurrentAccounts(accounts.slice(itemOffset, endOffset));
    }
    function tableFieldsHeading(labelName, fieldName) {
        return (
            <div className="table-fields-heading">
                <label className="table-fields-labels">{labelName}</label>
                {fieldName === null ? null : (
                    <div className="sorting-div">
                        <Image
                            src={asc}
                            className="sorting-btns"
                            style={{ cursor: "pointer" }}
                            onClick={() => (
                                setSortingType(fieldName + "ASC"),
                                sortField(fieldName + "Field")
                            )}
                        ></Image>
                        <Image
                            src={desc}
                            className="sorting-btns"
                            style={{ cursor: "pointer" }}
                            onClick={() => (
                                setSortingType(fieldName + "DSC"),
                                sortField(fieldName + "Field")
                            )}
                        ></Image>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="account-list-section">
            <Row className="account-list-row">
                <Row className="top-buttons-div account-list-button">
                    <Col lg="7">
                        <Button
                            className="user-button"
                            onClick={(e) => {
                                if (selectedItems.length > 0) {
                                    setDialogBox({
                                        isConfirmationDialogBox: true,
                                        dialogBoxMessage: "Do you want to save the changes?",
                                        dialogBoxIcon: "save",
                                        isDialogboxOpen: true,
                                        buttonName: "Yes",
                                        secondaryButtonName: "No"
                                    });
                                }
                                else {
                                    setSelectedItems([]);
                                    props.addNewAccount();
                                    props.onSelect({});
                                }

                            }}
                        >
                            <label className="labels">Add New Account</label>
                        </Button>
                    </Col>
                    <Col lg="5">
                        <Button
                            className="user-button"
                            disabled={selectedItems.length < 1}
                            onClick={(e) => {
                                setDialogBox({
                                    isConfirmationDialogBox: true,
                                    dialogBoxMessage: "Are you sure you want to delete this Account/s ?",
                                    dialogBoxIcon: "delete",
                                    isDialogboxOpen: true,
                                    buttonName: "Delete"
                                });
                            }}
                        >
                            <label className="labels">Delete</label>
                        </Button>
                    </Col>
                </Row>
                <Row className="row-margin">
                    <Col>
                        <InputGroup
                            className="mb-3"
                            style={{ width: "100%", height: "40px", margin: "auto" }}
                        >
                            <InputGroup.Text
                                id="basic-addon1"
                                style={{ background: "none", borderRight: "none" }}
                            >
                                <Image
                                    src={searchIcon}
                                    height="24px"
                                    width="24px"
                                    style={{ cursor: "pointer" }}
                                ></Image>
                            </InputGroup.Text>
                            <FormControl
                                style={{ borderLeft: "none" }}
                                //placeholder="Search account..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                onChange={(e) => {
                                    const filtered = searchObjects(tempAccountList, ["id", "status"], e.target.value);
                                    setAccounts(filtered);
                                }}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row style={{padding: "0px 0px"}}>
                    <Col lg="auto" style={{ width: "100%",padding:"unset"}}>
                        <Form style={{height:"100vh",overflowY:"scroll",width:"100%" }}>
                            <Table className="table">
                                <thead>
                                    <tr className="table-heading">
                                        <th>
                                            <div className="table-fields-heading">
                                                <FormCheck
                                                    checked={
                                                        selectedItems.length === currentAccounts.length &&
                                                        currentAccounts.length > 0
                                                    }
                                                    onChange={(e) => {
                                                        let newList = [];
                                                        if (e.target.checked) {
                                                            currentAccounts.forEach((item, index) =>
                                                                newList.push(item.id)
                                                            );
                                                        }
                                                        setSelectedItems(newList);
                                                    }}
                                                />
                                            </div>
                                        </th>
                                        <th>{tableFieldsHeading("Name", "accName")}</th>
                                        <th>{tableFieldsHeading("Status", null)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentAccounts.length > 0 ? (
                                        setAccountList()
                                    ) : (
                                        <tr>
                                            <td colSpan="6" className="user_view no-data-found">
                                                {loading ? <Loader loading={loading} /> : "No data found"}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Form>
                        {accounts.length > 0 && isLoadPagination ? (
                            <Pagination
                                items={accounts}
                                currentItems={setCurrentAccounts}
                                itemsPerpage={itemsPerpage}
                                itemOffset={setItemOffset}
                                endOffset={setEndOffset}
                                clearSelectedItems={setSelectedItems}
                            />
                        ) : null}
                    </Col>
                </Row>
            </Row>
            <DialogBoxModal
                show={dialogBox.isDialogboxOpen}
                name={dialogBox.dialogboxName}
                onHide={() => {
                    setSelectedItems([]);
                    props.onSelect({});
                    props.addNewAccount();
                    closeConfirmationDialogBox();
                }}
                onEvent={() => {
                    setDialogBox({ isDialogboxOpen: false });
                    switch (dialogBox.buttonName) {
                        case "Delete":
                            deleteAccountsFromList();
                            break;
                        case "Yes":
                            props.onSave("basicinfo");
                            closeConfirmationDialogBox();
                            setSelectedItems([]);
                            props.onSelect({});
                            break;
                        default:
                            break;
                    }
                    if(dialogBox.secondaryButtonName) {
                        switch (dialogBox.secondaryButtonName) {
                            case "No":
                                setSelectedItems([]);
                                props.onSelect({});
                            default:
                                break;
                        }
                    } 

                }}
                message={dialogBox.dialogBoxMessage}
                icon={dialogBox.dialogBoxIcon}
                isConfirmationDialogBox={dialogBox.isConfirmationDialogBox}
                buttonName={dialogBox.buttonName}
                secondaryButtonName={dialogBox.secondaryButtonName}
            />
        </div>
    );
}

export default AccountList;
