import React, { useState, useEffect } from "react";
import { FormCheck, Image } from "react-bootstrap";
import "./Account.css";
import "../common/Common.css";
import editIcon from "../../assets/images/edit.png";

function FleetListTableRow(props) {
    const [fleet, setFleet] = useState({});
    const [index, setIndex] = useState(0);
    const [isItemChecked, setIsItemChecked] = useState(false);

    useEffect(() => {
        setFleet(props.fleet);
        setIndex(props.index);
        setIsItemChecked(props.isSelected);
    }, [props]);

    return (
            <tr key={'row_'+fleet.accountFleetId}
                className="acc-table-row"
            >
                <td>
                    <FormCheck
                        name={index}
                        checked={isItemChecked}
                        onChange={(e) => {
                            setIsItemChecked(!isItemChecked);
                            props.onRowSelect(fleet.accountFleetId, !isItemChecked);
                        }}
                    />
                </td>
                <td>{fleet.washTypeName}</td>
                <td>
                    {fleet.vehicleNumber}
                </td>
                <td style={{textAlign: "center"}}>
                    <FormCheck
                        name="isPreSelected"
                        checked={fleet.isPreSelected ? fleet.isPreSelected : false}
                        onChange={()=>{
                            fleet.isPreSelected = !fleet.isPreSelected
                            setFleet(prevFleet => ({
                                ...prevFleet,fleet
                            }));
                            props.preSelectUpdated(fleet);
                        }}
                    />
                </td>
                <td>
                    <Image 
                        src={editIcon}
                        onClick={(e) => {props.handleUpdate(fleet)}}
                        style={{width:"25px", margin: "0px 1px",cursor: "pointer"}}
                    />
                </td>
            </tr >
    )
}

export default FleetListTableRow
