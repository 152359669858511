import React, { useState, useEffect } from "react";
import { Row, Button, Form, Col } from "react-bootstrap";
import {
  isEmptyString,
  isValidMinimumCharge,
  isValidNewInvoiceDate,
} from "../common/formValidator";
import "./Account.css";
import "../common/Common.css";
import { FREQUENCY } from "../common/constant";
import { getDataFieldValue, isAllFormFieldsFilled } from "../common/helper";
import ToolTip from "../common/ToolTip";
import { Link } from "react-router-dom";

function AccountFinancialInfo(props) {
  const nameError = useFormUpdate(false);
  const quickbooksId = useFormUpdate("");
  const quickbooksIdError = useFormUpdate(false);
  const invoiceFrequency = useFormUpdate("");
  const invoiceFrequencyError = useFormUpdate(false);
  const lastInvoiceDate = useFormUpdate("");
  const invoiceNextDate = useFormUpdate("");
  const invoiceNextDateError = useFormUpdate(false);
  const minimumCharge = useFormUpdate("");
  const minimumChargeError = useFormUpdate(false);

  const purchaseOrder = useFormUpdate("");
  const purchaseOrderError = useFormUpdate(false);

  const proposedInvoiceFrequency = useFormUpdate("");
  const proposedInvoiceFrequencyError = useFormUpdate(false);
  const proposedInvoiceDate = useFormUpdate("");
  const proposedInvoiceDateError = useFormUpdate(false);

  const [isUpdated, setIsUpdated] = useState(false);

  const [quickBookInfo, setQuickBookInfo] = useState({});
  const validationStatus = useFormUpdate(false);
  const [isFinancialInfoChanged, setIsFinancialInfoChanged] = useState(false);
  const [isChangingBillingCycle, setIsChangingBillingCycle] = useState(false);
  const [isUpdatingQuickBookInfo, setIsUpdatingQuickBookInfo] = useState(false);
  const [updateBillingCycle, setUpdateBillingCycle] = useState(false);
  useEffect(() => {
    console.log("props.quickBookInfo", props.quickBookInfo);
    setQuickBookInfo(props.quickBookInfo ? props.quickBookInfo : {});
    isUpdatingQuickBookInfo && isChangingBillingCycle
      ? setIsChangingBillingCycle(true)
      : getDataFieldValue(props.quickBookInfo, "proposedInvoiceFrequency")
      ? setIsChangingBillingCycle(true)
      : setIsChangingBillingCycle(false);
    isUpdatingQuickBookInfo
      ? setIsUpdatingQuickBookInfo(false)
      : setUpdateBillingCycle(false);
    quickbooksId.update(getDataFieldValue(props.quickBookInfo, "quickbooksId"));
    minimumCharge.update(
      getDataFieldValue(props.quickBookInfo, "minimumCharge")
    );
    purchaseOrder.update(
      getDataFieldValue(props.quickBookInfo, "purchaseOrder")
    );
    invoiceFrequency.update(
      getDataFieldValue(props.quickBookInfo, "invoiceFrequency")
    );
    lastInvoiceDate.update(
      clipDate(getDataFieldValue(props.quickBookInfo, "lastInvoiceDate"))
    );
    invoiceNextDate.update(
      clipDate(getDataFieldValue(props.quickBookInfo, "nextInvoiceDate"))
    );
    proposedInvoiceFrequency.update(
      getDataFieldValue(props.quickBookInfo, "proposedInvoiceFrequency")
    );
    proposedInvoiceDate.update(
      clipDate(getDataFieldValue(props.quickBookInfo, "proposedInvoiceDate"))
    );
    nameError.update(false);
    quickbooksIdError.update(false);
    minimumChargeError.update(false);
    purchaseOrderError.update(false);
    invoiceFrequencyError.update(false);
    invoiceNextDateError.update(false);
  }, [props.quickBookInfo]);

  useEffect(() => {
    setIsUpdatingQuickBookInfo(true);
    props.onUpdate(quickBookInfo);
  }, [isFinancialInfoChanged]);

  function updateQuickBookInfo(fieldName, value) {
    setQuickBookInfo((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  }

  function clipDate(date) {
    return date.substring(0, 10);
  }
  useEffect(() => {
    if(isUpdated){
        setValidationStatus();
    }
  }, [
    quickbooksId.state,
    invoiceFrequency.state,
    invoiceNextDate.state,
    proposedInvoiceDate.state,
    proposedInvoiceFrequency.state,
    minimumCharge.state,
    purchaseOrder.state,
    isChangingBillingCycle,
    isUpdated
  ]);

  function setValidationStatus() {
    let fields = [
      quickbooksId,
      invoiceFrequency,
      invoiceNextDate,
      minimumCharge,
      purchaseOrder,
    ];
    fields = isChangingBillingCycle
      ? [...fields, ...[proposedInvoiceDate, proposedInvoiceFrequency]]
      : fields;
    validationStatus.update(isAllFormFieldsFilled(fields));
  }

  function getInvoicePeriod() {
    let nextInvoiceOn = proposedInvoiceDate.state
      ? new Date(proposedInvoiceDate.state) < new Date(invoiceNextDate.state)
        ? "proposedInvoiceDate"
        : "nextInvoiceDate"
      : "nextInvoiceDate";
    // nextInvoiceOn = isPreviousDate(new Date(getDataFieldValue(props.quickBookInfo, nextInvoiceOn))) ? nextInvoiceOn :
    return lastInvoiceDate.state && invoiceNextDate.state
      ? new Date(getDataFieldValue(props.quickBookInfo, "lastInvoiceDate"))
          .toString()
          .substring(0, 25) +
          " to " +
          new Date(getDataFieldValue(props.quickBookInfo, nextInvoiceOn))
            .toString()
            .substring(0, 25)
      : "";
  }

  function changeBillingCycle() {
    return (
      <Row className="custom-row">
        <Row className="mb-3" style={{ margin: "auto", padding: "0px 0px" }}>
          <Col sm="4">
            <Form.Group as={Col} controlId="formPlaintextProposedInvoiceDate">
              <Form.Label column sm="8">
                New Invoice Date
                <ToolTip message="New billing cycle starts from this date" />
              </Form.Label>
              <Form.Control
                type="date"
                className=""
                disabled={!updateBillingCycle}
                required
                value={proposedInvoiceDate.state}
                style={{
                  borderColor: proposedInvoiceDateError.state ? "red" : "",
                }}
                onBlur={(e) => {
                  if (
                    !isEmptyString(e.target.value) ||
                    isValidNewInvoiceDate(e.target.value)
                  ) {
                    proposedInvoiceDateError.update(true);
                    validationStatus.update(false);
                  } else {
                    updateQuickBookInfo("proposedInvoiceDate", e.target.value);
                    setIsFinancialInfoChanged(!isFinancialInfoChanged);
                    // setValidationStatus();
                  }
                }}
                onChange={(e) => {
                  proposedInvoiceDateError.update(false);
                  console.log(e.target.value);
                  proposedInvoiceDate.update(e.target.value);
                  setIsUpdated(true)
                }}
              />
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group as={Col} controlId="formPlaintextInvoiceFrequency">
              <Form.Label column sm="8">
                New Frequency
                <ToolTip message="New billing cycle invoice Frequency" />
              </Form.Label>
              <Form.Select
                value={proposedInvoiceFrequency.state}
                style={{
                  borderColor: proposedInvoiceFrequencyError.state ? "red" : "",
                }}
                disabled={!updateBillingCycle}
                onChange={(e) => {
                  proposedInvoiceFrequencyError.update(false);
                  if (!isEmptyString(e.target.value)) {
                    proposedInvoiceFrequencyError.update(true);
                    validationStatus.update(false);
                  } else {
                    updateQuickBookInfo(
                      "proposedInvoiceFrequency",
                      e.target.value
                    );
                    proposedInvoiceFrequency.update(e.target.value);
                    setIsFinancialInfoChanged(!isFinancialInfoChanged);
                  }
                  setIsUpdated(true)
                }}
              >
                <option value="">Select</option>
                {FREQUENCY.map((obj) => {
                  return (
                    <option key={obj} value={obj}>
                      {obj}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </Row>
    );
  }

  return (
    <div className="info-section">
      <Row>
        <Col className="top-buttons-div">
          <Row
            className="series-button"
            style={{ width: "75%", justifyContent: "right", float: "right" }}
          >
            <Button
              className="user-button custom-button-width"
              disabled={!validationStatus.state}
              onClick={(e) => {
                e.preventDefault();
                props.onSave("fleet");
                setIsChangingBillingCycle(false);
              }}
            >
              <label className="labels">Save</label>
            </Button>
          </Row>
        </Col>
        <Row className="custom-row">
          <Row className="mb-3" style={{ margin: "auto" }}>
            <Form.Group
              as={Col}
              className="mb-12"
              controlId="formPlaintextQuickbooksID"
            >
              <Form.Label>Quickbook ID</Form.Label>
              <ToolTip message="Quickbook id" />

              <Form.Control
                type="tel"
                className=""
                required
                autoComplete="nope"
                value={quickbooksId.state}
                //placeholder="QuickBook Id"
                style={{ borderColor: quickbooksIdError.state ? "red" : "" }}
                onBlur={(e) => {
                  if (!isEmptyString(e.target.value)) {
                    quickbooksIdError.update(true);
                    validationStatus.update(false);
                  } else {
                    updateQuickBookInfo("quickbooksId", e.target.value);
                    // setValidationStatus();
                    setIsFinancialInfoChanged(!isFinancialInfoChanged);
                  }
                }}
                onChange={(e) => {
                  quickbooksIdError.update(false);
                  quickbooksId.update(e.target.value);
                  setIsUpdated(true)
                }}
              />
            </Form.Group>
          </Row>
        </Row>

        <Row className="custom-row">
          <Row className="mb-3" style={{ margin: "auto" }}>
            <Form.Group
              as={Col}
              className="mb-12"
              controlId="formPlaintextPurchaseOrder"
            >
              <Form.Label>Purchase Order</Form.Label>
              <ToolTip message="Purchase Order" />

              <Form.Control
                type="tel"
                className=""
                required
                autoComplete="nope"
                value={purchaseOrder.state}
                style={{ borderColor: purchaseOrderError.state ? "red" : "" }}
                onBlur={(e) => {
                  if (!isEmptyString(e.target.value)) {
                    purchaseOrderError.update(true);
                    validationStatus.update(false);
                  } else {
                    updateQuickBookInfo("purchaseOrder", e.target.value);
                    setIsFinancialInfoChanged(!isFinancialInfoChanged);
                  }
                }}
                onChange={(e) => {
                  purchaseOrder.update(e.target.value);
                  purchaseOrderError.update(false);
                  setIsUpdated(true)
                }}
              />
            </Form.Group>
          </Row>
        </Row>

        <Row className="custom-row">
          <Row className="mb-3" style={{ margin: "auto", padding: "0px 0px" }}>
            <Col sm="4">
              <Form.Group as={Col} controlId="formPlaintextnextInvoiceDate">
                <Form.Label column sm="8">
                  Invoice Date
                  <ToolTip message="Next Invoice Date" />
                </Form.Label>
                <Form.Control
                  type="date"
                  className=""
                  required
                  value={invoiceNextDate.state}
                  disabled={lastInvoiceDate.state ? true : false}
                  style={{
                    borderColor: invoiceNextDateError.state ? "red" : "",
                  }}
                  onBlur={(e) => {
                    if (
                      !isEmptyString(e.target.value) ||
                      isValidNewInvoiceDate(e.target.value)
                    ) {
                      invoiceNextDateError.update(true);
                      validationStatus.update(false);
                    } else {
                      updateQuickBookInfo("nextInvoiceDate", e.target.value);
                      // setValidationStatus();
                      setIsFinancialInfoChanged(!isFinancialInfoChanged);
                    }
                  }}
                  onChange={(e) => {
                    invoiceNextDateError.update(false);
                    invoiceNextDate.update(e.target.value);
                    setIsUpdated(true)
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group as={Col} controlId="formPlaintextInvoiceFrequency">
                <Form.Label column sm="8">
                  Invoice Frequency
                  <ToolTip message="Invoice Frequency" />
                </Form.Label>
                <Form.Select
                  disabled={lastInvoiceDate.state ? true : false}
                  value={invoiceFrequency.state}
                  style={{
                    borderColor: invoiceFrequencyError.state ? "red" : "",
                  }}
                  onChange={(e) => {
                    invoiceFrequencyError.update(false);
                    if (!isEmptyString(e.target.value)) {
                      invoiceFrequencyError.update(true);
                    } else {
                      updateQuickBookInfo("invoiceFrequency", e.target.value);
                      invoiceFrequency.update(e.target.value);
                      setIsFinancialInfoChanged(!isFinancialInfoChanged);
                    }
                    setIsUpdated(true)
                  }}
                >
                  <option value="">Select</option>
                  {FREQUENCY.map((obj) => {
                    return (
                      <option key={obj} value={obj}>
                        {obj}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Row>
        {lastInvoiceDate.state && (
          <span>
            Current invoice Period :{" "}
            <span style={{ fontWeight: "bold" }}>{getInvoicePeriod()}</span>
          </span>
        )}
        <div>
          <Row>
            <Col className="top-buttons-div">
              <Row
                className="series-button"
                style={{
                  width: "75%",
                  justifyContent: "right",
                  float: "right",
                }}
              >
                <Button
                  className="user-button custom-button-width"
                  disabled={updateBillingCycle}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsChangingBillingCycle(true);
                    setUpdateBillingCycle(true);
                  }}
                >
                  <label className="labels">Change Billing Cycle</label>
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
        {isChangingBillingCycle && changeBillingCycle()}
      </Row>
      <Link
        to={{
          pathname: "/invoices",
          search: "?tab=in-progress-invoices&accountId=" + props.accountId,
        }}
      >
        View-Invoices
      </Link>
    </div>
  );
}
function useFormUpdate(initialState) {
  const [state, setstate] = useState(initialState);

  function update(value) {
    setstate(value !== null ? value : "");
  }
  return {
    state,
    update,
  };
}
export default AccountFinancialInfo;
