import "./Toolbar.css";
import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import usericon from "../../assets/images/usericon.png";
import { useContext } from "react";
import { UserContext } from "../../AppContext";
import { useNavigate, Link } from "react-router-dom";
import { logout, useAuth } from "../../service/authProvider";
import sprayzIcon from "../../assets/images/sprayzicon.png";
import Image from "react-bootstrap/Image";
import { NavLink } from "react-router-dom";

function Toolbar(props) {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const [expanded, setExpanded] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    setIsPasswordReset(props.isPasswordReset);
    setSelectedTab(props.tabName);
  }, [props]);

  return (
    <Navbar
      className="toolbar"
      collapseOnSelect
      fixed="top"
      expand="sm"
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand href="/" className="brand">
          <Image src={sprayzIcon} className="icon" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            setExpanded(!expanded);
          }}
          onBlur={() => {
            setExpanded(!expanded);
          }}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="navbar-position">
            {useAuth() && !isPasswordReset ? (
              <>
                <Nav.Link
                  as={Link}
                  to="/"
                  href="/"
                  className={selectedTab == "home" ? "nav-link  activeNow" : " nav-link " }
                  // activeClassName="active"
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/accounts"
                  href="/accounts"
                  className={selectedTab == "accounts" ? "nav-link  activeNow" : " nav-link " }
                  // activeClassName="active"
                >
                  Accounts
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/washtype"
                  href="/washtype"
                  className={selectedTab == "washtype" ? "nav-link  activeNow" : " nav-link " }
                  // activeClassName="active"
                >
                  Wash Types
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/users"
                  href="/users"
                  className={selectedTab == "users" ? "nav-link  activeNow" : " nav-link " }
                  // activeClassName="active"
                >
                  Users
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/invoices"
                  href="/invoices"
                  className={selectedTab == "invoices" ? "nav-link  activeNow" : " nav-link " }
                  // activeClassName="active"
                >
                  Invoices
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/reports"
                  href="/reports"
                  className={selectedTab == "reports" ? "nav-link  activeNow" : " nav-link " }
                  // activeClassName="active"
                >
                  Reports
                </Nav.Link>{" "}
                <Nav.Link
                  as={Link}
                  to="/washinventory"
                  href="/washinventory"
                  className={selectedTab == "washinventory" ? "nav-link  activeNow" : " nav-link " }
                  // activeClassName="active"
                >
                  Wash Inventory
                </Nav.Link>
              </>
            ) : null}

            {useAuth() ? (
              <Link
                id="login_tool"
                className="nav-link login-button"
                to="/"
                onClick={() => logoutUser()}
              >
                <img src={usericon} className="user-icon"></img>
                Log out
              </Link>
            ) : (
              <Link
                className="nav-link login-button"
                id="login_tool"
                to="/login"
              >
                <img src={usericon} className="user-icon"></img>
                Log In
              </Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );

  function logoutUser() {
    localStorage.removeItem("user");
    logout();
    window.location.reload();
    navigate("/");
  }
}

export default Toolbar;
