import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  FormCheck,
  Image,
} from "react-bootstrap";
import asc from "../../assets/images/asc.png";
import desc from "../../assets/images/desc.png";
import { getAllAccountsSummaryV2 } from "../../service/accountService";
import { getWashDetails } from "../../service/washInventoryService";
import {getWashDetailsPdf } from "../../service/reportService"
import { listUsers } from "../../service/userService";
import { ACTIVEROWCOLOR, DELIMITER } from "../common/constant";
import Loader from "../common/Loader";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { customViewFormatDate, formatDate } from "../common/helper";
import { formatDateToUTC } from "../common/helper";
import Select from 'react-select';


function WashDetailsReport(props) {
  const filterByAccount = useFormUpdate("");
  const userList = useFormUpdate([]);
  const accountList = useFormUpdate([]);
  const selectedItems = useFormUpdate([]);
  const loading = useFormUpdate(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [startDate, endDate] = dateRange;
  const [accountLocationNumber, setAccountLocationNumber] = useState(0);
  const [selectedAccountName, setSelectedAccountName] = useState('');
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [accountLocations, setAccountLocations] = useState([]);
  const [accountSummary, setAccountSummary] = useState([]);
  const [currentSortingField, setCurrentSortingField] = useState("accDateField");
  const [sortingType, setSortingType] = useState("accDateASC");

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '36px',
      height: 'auto',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '36px',
      padding: '0 6px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: (state) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '36px',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#e5e5e5',
      borderRadius: '4px',
      padding: '2px 4px',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#333',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#999',
      ':hover': {
        backgroundColor: '#ccc',
        color: '#333',
      },
    }),
  };
  const [reports, setReports] = useState([]);
  const [downloadReports, setDownloadReports] = useState([]);

  useEffect(() => {
    fetchUserList();
    fetchAccountListSummary();
    sortField();
  }, []);
  
  useEffect(() => {
    setReportsList();
    let formattedReport = [];
    reports.forEach(item => {
      let obj = {
        "Date": customViewFormatDate(item.endTime),
        "Account Name": item.locationsCount > 1 ? item.accountName + ' - ' + item.locationName : item.accountName,
        "Lead" : item.leadName,
        "Wash Type": item.washTypeName,
        "Quantity": item.quantity,
        "Fleet Number" : item.vehicleNumber
      }
      formattedReport.push(obj);
    });
    setDownloadReports(formattedReport);
  }, [reports]);

  const fetchUserList = async () => {
    let response = await listUsers();
    if (response.status === 200) {
      userList.update(
        response.data
          ? response.data.response.filter(function (item) {
            return item.roleCode === "LEAD";
          })
          : []
      );
    }
  };

  function getAccountSelectionOptionsWithLocation(accountSummary){
    const options = []
    accountSummary.forEach((account) => {
          let option = {
              value: account.accountId + DELIMITER + account.name,
              label: account.name,
          };
          options.push(option);
    });
    return (options);
  }

  async function fetchWashDetailsPdf() {
    let id = props.toastLoader("Please wait...");
    let response = await getWashDetailsPdf(filterByAccount.state ? filterByAccount.state : 0,
      new Date(startDate.setHours(0,0,0)), new Date(endDate.setHours(23,59,59)));
    if (response.status === 200) {
      console.log("fetch wash details report");
      props.toastSuccess();
      props.toastLoaderUpdate(id, "Report successfully generated!", "success");
    }
    else if (response.status === 404) {
      props.toastLoaderUpdate(id, "Report not found!", "error");
    }
    else {
      props.toastLoaderUpdate(id, "Report generation failed", "error");
    }
  }

  const handleLocationChange = (selectedOptions) => {
    setSelectedLocations(selectedOptions);
    console.log(selectedLocations);
  };

  useEffect(() => {
    if(filterByAccount.state !== '0'){
      const locations = filterByAccount.state 
      ? accountSummary.find(account => account.accountId === Number(filterByAccount.state)).locations.map(location => ({
          value: location.locationNumber,
          label: location.name
        }))
      : [];
      setAccountLocations(locations);
    }
  }, [filterByAccount.state]);
  // TODO: delete after confirm the UI
  // const fetchAccountActivityReport = async () => {
  //   let id = props.toastLoader("Please wait...");
  //   let response = await getWashDetails(filterByAccount.state ? filterByAccount.state : 0, accountLocationNumber ? accountLocationNumber : 0, 0,
  //     new Date(startDate.setHours(0,0,0)), new Date(endDate.setHours(23,59,59)));
  //   if (response.status === 200) {
  //     console.log("fetch acc activity reposrt :", response.data);
  //     setReports(response.data.washInventory);
  //     props.toastSuccess();
  //     props.toastLoaderUpdate(id, "Report successfully generated!", "success");
  //   }
  //   else if (response.status === 404) {
  //     props.toastLoaderUpdate(id, "Report not found!", "error");
  //     setReports([]);
  //   }
  //   else {
  //     props.toastLoaderUpdate(id, "Report generation failed", "error");
  //     setReports([]);
  //   }
  //   console.log(reports)
  // };

  const fetchAccountActivityReport = async () => {
    let id = props.toastLoader("Please wait...");    
    try {
      let responses = [];
      if(selectedLocations.length > 0){
        responses = await Promise.all(
          selectedLocations.map(async (location) => {
            return await getWashDetails(
              filterByAccount.state ? filterByAccount.state : 0,
              location.value,
              0,
              new Date(startDate.setHours(0, 0, 0)),
              new Date(endDate.setHours(23, 59, 59))
            );
          })
        );
      }
      else{
        const response = await getWashDetails(
          filterByAccount.state ? filterByAccount.state : 0,
          0,
          0,
          new Date(startDate.setHours(0, 0, 0)),
          new Date(endDate.setHours(23, 59, 59))
        );
        responses.push(response);
      }
      let combinedData = [];
      let hasSuccess = false;
  
      responses.forEach((response) => {
        if (response.status === 200) {
          combinedData = combinedData.concat(response.data.washInventory);
          hasSuccess = true;
        }
      });
  
      if (hasSuccess) {
        console.log("fetch acc activity report:", combinedData);
        setReports(combinedData);
        props.toastSuccess();
        props.toastLoaderUpdate(id, "Report successfully generated!", "success");
      } else {
        const notFound = responses.some(response => response.status === 404);
        if (notFound) {
          props.toastLoaderUpdate(id, "Report not found!", "error");
        } else {
          props.toastLoaderUpdate(id, "Report generation failed", "error");
        }
        setReports([]);
      }
    } catch (error) {
      console.error("Error fetching account activity report:", error);
      props.toastLoaderUpdate(id, "Report generation failed", "error");
      setReports([]);
    }
    console.log(reports)
  };

  const fetchAccountListSummary = async () => {
    let response = await getAllAccountsSummaryV2();
    if (response.status === 200) {
      setAccountSummary(response.data.accountSummary);
      const options = getAccountSelectionOptionsWithLocation(response.data.accountSummary);
      accountList.update(options);
    }
  };

  function setReportsList() {
    return reports.map((obj, index) => {
      return (
        <tr
          key={index}
          className="acc-table-row"
          style={{
            backgroundColor: selectedItems.state.includes(index) ? ACTIVEROWCOLOR : ""
          }}
          onClick={() => {
            selectedItems.update([index]);
          }}
        >
          <td style={{ paddingTop: "15px" }}> {index + 1}</td>
          {/* <td style={{ paddingTop: "15px" }}>{obj.month + " " + obj.year}</td> */}
          <td style={{ paddingTop: "15px" }}>{customViewFormatDate(obj.endTime)}</td>
          <td style={{ paddingTop: "15px" }}>{obj.locationsCount > 1 ? obj.accountName + ' - ' + obj.locationName : obj.accountName}</td>
          <td style={{ paddingTop: "15px" }}>{obj.leadName}</td>
          <td style={{ paddingTop: "15px" }}>{obj.washTypeName}</td>
          <td style={{ paddingTop: "15px" }}>{obj.quantity}</td>
          <td style={{ paddingTop: "15px" }}>{obj.vehicleNumber}</td>

        </tr>
      );
    });
  }
  
  function sortField() {
    switch (currentSortingField) {
      case "accDateField":
        reports.sort(function (a, b) {
          var valueA = a.endTime.toUpperCase();
          var valueB = b.endTime.toUpperCase();
          if (sortingType === "accDateASC") {
            return valueA < valueB ? 1 : -1;
          } else {
            return valueA < valueB ? -1 : 1;
          }
        });
        break;
      case "accWashtypeField":
        reports.sort(function (a, b) {
          var nameA = a.washTypeName.toUpperCase(); // ignore upper and lowercase
          var nameB = b.washTypeName.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "accWashtypeASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "accQuantityField":
        reports.sort(function (a, b) {
          var nameA = a.quantity;// ignore upper and lowercase
          var nameB = b.quantity; // ignore upper and lowercase
          if (sortingType === "accQuantityASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "accNameField":
        reports.sort(function (a, b) {
          var nameA = a.accountName.toUpperCase();// ignore upper and lowercase
          var nameB = b.accountName.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "accNameASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
        case "accFleetNumberField":
          reports.sort(function (a, b) {
            var nameA = a.vehicleNumber.toUpperCase();// ignore upper and lowercase
            var nameB = b.vehicleNumber.toUpperCase(); // ignore upper and lowercase
            if (sortingType === "accFleetNumberASC") {
              return nameA < nameB ? 1 : -1;
            } else {
              return nameA < nameB ? -1 : 1;
            }
          });
          break;
          case "accLeadField":
            reports.sort(function (a, b) {
              var nameA = a.leadName.toUpperCase();// ignore upper and lowercase
              var nameB = b.leadName.toUpperCase(); // ignore upper and lowercase
              if (sortingType === "accLeadASC") {
                return nameA < nameB ? 1 : -1;
              } else {
                return nameA < nameB ? -1 : 1;
              }
            });
            break;
      default:
        break;
    }
  }

  function tableFieldsHeading(labelName, fieldName) {

    const toggleFilter = () =>{
    if(sortingType.endsWith("ASC")){
      setSortingType(fieldName + "DSC");
    }else{
      setSortingType(fieldName + "ASC");
    }
    setCurrentSortingField(fieldName + "Field");
    sortField(fieldName + "Field")
  }

  useEffect(() => {
    sortField(currentSortingField);
  }, [sortingType]);

    const filterIcon = () =>{
    if(fieldName+"Field" === currentSortingField){
      if(sortingType.endsWith("ASC")){
        return asc;
      }else{
        return desc;
      }
    }else{
      return null;
    }
  }

    return (
      <div className="table-fields-heading" onClick={()=>{toggleFilter()}}>
        <label className="table-fields-labels">{labelName}</label>
        {fieldName === null ? null : (
          <div className="sorting-div">
             {filterIcon() && (
            <Image
              src={filterIcon()}
              className="sorting-btns"
              style={{ cursor: "pointer" }}
            ></Image>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div style={{ position: "relative" , marginTop: "4%" }}>
      <Row>
        <Col sm={3} className="col-right-space ">
          {/* <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextdateRange"
          > */}
          <Row>
            <Form.Label column sm="6">
              Date Range
            </Form.Label>
          </Row>
          <Row>
            <DatePicker
              className="input-field date-field-width"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
            />
            {/* <Col sm={6}>
                <Form.Control
                  type="date"
                  className="input-field date-field-width"
                  value={dateFrom.state}
                  //placeholder="From"
                  onChange={(e) => {
                    dateFrom.update(e.target.value);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Form.Control
                  type="date"
                  className="input-field date-field-width"
                  value={dateTo.state}
                  //placeholder="To"
                  onChange={(e) => {
                    dateTo.update(e.target.value);
                  }}
                />
              </Col> */}

          </Row>
          {/* </Form.Group> */}
        </Col>
        <Col sm={3} className="col-right-space">
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextFilterByAccount"
          >
            <Row>
              <Form.Label column sm="6">
                Filter By
              </Form.Label>
            </Row>
            <Row>
              <Col sm="8">
                <Form.Select
                  className="custom-width"
                  value={selectedAccountName}
                  disabled={false}
                  onChange={(e) => {
                    setSelectedAccountName(e.target.value);
                    let accountId = e.target.value !== '0' ? e.target.value.split(DELIMITER)[0] : 0;
                    filterByAccount.update(accountId);
                    setReports([]);
                    setSelectedAccount(e.target.value == '' ? null : e.target.value);
                  }}
                >
                  <option value="0">Select account</option>
                  {accountList.state.map((obj,index) => {
                    return (
                      <option key={index} value={obj.value}>
                        {obj.label}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={4} className="col-right-space" style={{height: '10vh'}}>
          {accountLocations.length > 1 && (
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextFilterByLocations"
            >
              <Row>
                <Form.Label column sm="6">
                  Filter By Location
                </Form.Label>
              </Row>
              <Row>
                <Col sm="8">
                  <Select
                    isMulti
                    placeholder="Select Locations"
                    options={accountLocations}
                    value={selectedLocations}
                    onChange={handleLocationChange}
                    styles={customStyles}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                  />
                </Col>
              </Row>
            </Form.Group>
          )}
        </Col>
        <Col sm={2} className="col-right-space button-margin">
          <Col>
            <Button className="user-button"
              onClick={(e) => {
                setReports([]);
                setDateRange([null, null]);
                filterByAccount.update("");
                setSelectedAccount(null)
              }
              }
            >
              <label className="labels">Clear</label>
            </Button>
          </Col>
          <Col>
            <Button
              className="user-button"
              onClick={(e) => {
                fetchAccountActivityReport();
              }}
              disabled={!(startDate && endDate)}
            >
              <label className="labels">View</label>
            </Button>
          </Col>
        </Col>
      </Row>
      <Row className="right-download-button">
        {selectedAccount !== null ? (<Button
            className="user-button"
            onClick={() => {
              fetchWashDetailsPdf();
            }}
            style={{ width: "100px", display: downloadReports.length > 0 ? "block" : "none" }}
          >
            <label className="labels">Download</label>
        </Button>): <></>}
        <CSVLink
          data={downloadReports}
          className="user-button"
          style={{ width: "100px", display: downloadReports.length > 0 ? "block" : "none" }}
          onClick={() => {
            if (reports.length > 0) {
              props.toastSuccess("Data downlaoded successfully!");
              return true;
            }
            else {
              props.toastError("No data found!");
              return false;
            }
          }}
          filename={"account_activity_report_" + formatDate(startDate) + "_to_" + formatDate(endDate)}
        >
          <label className="labels">Download as CSV</label>
        </CSVLink>
      </Row>
      <Row style={{ marginTop: "2%",maxHeight: "60vh",overflowY: "scroll" }}>
        <Form>
          <Table className="table">
            <thead>
              <tr className="table-heading">
                <th>#</th>
                <th>{tableFieldsHeading("Date", "accDate")}</th>
                <th>{tableFieldsHeading("Account Name", "accName")}</th>
                <th>{tableFieldsHeading("Lead", "accLead")}</th>
                <th>{tableFieldsHeading("Wash Type", "accWashtype")}</th>
                <th>{tableFieldsHeading("Quantity", "accQuantity")}</th>
                <th>{tableFieldsHeading("Fleet Number", "accFleetNumber")}</th>
              </tr>
            </thead>
            <tbody>
              {reports.length > 0 ? (
                setReportsList()
              ) : (
                <tr>
                  <td colSpan="6" className="user_view no-data-found">
                    {loading.state ? <Loader loading={loading.state} /> : "No data found"}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Form>
      </Row>
    </div>
  );
}

function useFormUpdate(initialState) {
  const [state, setstate] = useState(initialState);

  function update(value) {
    setstate(value !== null ? value : "");
  }
  return {
    state,
    update,
  };
}
export default WashDetailsReport;
