import React, { useState, useEffect } from "react";
import "./Invoices.css";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import InvoicesList from "./InvoicesList";
import { toast } from "react-toastify";
import {
  getLoaderToastMessage,
  getLoaderToastSettings,
  getToastSettings,
} from "../common/ToastManager";
import { useLocation } from "react-router-dom";

function Invoices(props) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const search = useLocation().search;
  // const activeTab = new URLSearchParams(search).get("tab") ? new URLSearchParams(search).get("tab") : "pending-invoices";
  const activeTab = "pending-invoices";
  const acccontId = !isNaN(new URLSearchParams(search).get("accountId")) && new URLSearchParams(search).get("accountId") !== null ? 
    new URLSearchParams(search).get("accountId") : 0;
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  useEffect(() => {
    props.setTab('invoices');
  }, [props]);

  const [isRefreshInvoices, setIsRefreshInvoices] = useState(false);

  const toastSuccess = (toastMessage) => 
    toast.success(toastMessage, getToastSettings());
  const toastError = (toastMessage) =>
    toast.error(toastMessage, getToastSettings());
  const toastLoader = (toastMessage) =>
    toast.loading(toastMessage, getLoaderToastSettings());
  const toastLoaderUpdate = (id, toastMessage, type) =>
    toast.update(id, getLoaderToastMessage(toastMessage, type));

  return (
    <Container className="invoices_list_view">
      <Tab.Container id="left-user-tabs" defaultActiveKey={activeTab}>
        <Row className="page-top-margin">
          <Col sm={2}>
            <Nav
              variant="pills"
              className={
                isMobile
                  ? "flex-row left-side-layout"
                  : "flex-column left-side-layout"
              }
            >
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="pending-invoices">
                  <label className="label">Pending Invoices</label>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="in-progress-invoices">
                  <label className="label">In Progress Invoices</label>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="send-to-customer-invoices">
                  <label className="label">Sent To Customer</label>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="payment-received-invoices">
                  <label className="label">Payment Received</label>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="on-hold-invoices">
                  <label className="label">On Hold</label>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="cancelled-invoices">
                  <label className="label">Cancelled Invoices</label>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content>
              <Tab.Pane eventKey="pending-invoices">
                <InvoicesList
                  invoiceType="pending"
                  isRefreshInvoices={isRefreshInvoices}
                  setIsRefreshInvoices={setIsRefreshInvoices}
                  toastSuccess={toastSuccess}
                  toastError={toastError}
                  toastLoader={toastLoader}
                  toastLoaderUpdate={toastLoaderUpdate}
                  accountId = {acccontId}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="in-progress-invoices">
                <InvoicesList
                  invoiceType="in-progress"
                  isRefreshInvoices={isRefreshInvoices}
                  setIsRefreshInvoices={setIsRefreshInvoices}
                  toastSuccess={toastSuccess}
                  toastError={toastError}
                  toastLoader={toastLoader}
                  toastLoaderUpdate={toastLoaderUpdate}
                  accountId = {acccontId}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="send-to-customer-invoices">
                <InvoicesList
                  invoiceType="send-to-customer"
                  isRefreshInvoices={isRefreshInvoices}
                  setIsRefreshInvoices={setIsRefreshInvoices}
                  toastSuccess={toastSuccess}
                  toastError={toastError}
                  toastLoader={toastLoader}
                  toastLoaderUpdate={toastLoaderUpdate}
                  accountId = {acccontId}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="payment-received-invoices">
                <InvoicesList
                  invoiceType="payment-received"
                  isRefreshInvoices={isRefreshInvoices}
                  setIsRefreshInvoices={setIsRefreshInvoices}
                  toastSuccess={toastSuccess}
                  toastError={toastError}
                  toastLoader={toastLoader}
                  toastLoaderUpdate={toastLoaderUpdate}
                  accountId = {acccontId}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="on-hold-invoices">
                <InvoicesList
                  invoiceType="on-hold"
                  isRefreshInvoices={isRefreshInvoices}
                  setIsRefreshInvoices={setIsRefreshInvoices}
                  toastSuccess={toastSuccess}
                  toastError={toastError}
                  toastLoader={toastLoader}
                  toastLoaderUpdate={toastLoaderUpdate}
                  accountId = {acccontId}
                />
              </Tab.Pane>
              
              <Tab.Pane eventKey="cancelled-invoices">
                <InvoicesList
                  invoiceType="cancelled"
                  isRefreshInvoices={isRefreshInvoices}
                  setIsRefreshInvoices={setIsRefreshInvoices}
                  toastSuccess={toastSuccess}
                  toastError={toastError}
                  toastLoader={toastLoader}
                  toastLoaderUpdate={toastLoaderUpdate}
                  accountId = {acccontId}
                />
              </Tab.Pane>
              
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}

export default Invoices;
