import React, { useState, useEffect } from "react";
import { Row, Button, Form, Col, Table, FormCheck, Image } from "react-bootstrap";
import "./Account.css";
import "../common/Common.css";
import asc from "../../assets/images/asc.png";
import desc from "../../assets/images/desc.png";
import Pagination from "../common/Pagination";
import DialogBoxModal from "../modal/DialogBoxModal";
import AccountMiscTypeListTableRow from "./AccountMiscTypeListTableRow";
import AddOrEditAccountMiscTypeModal from "./AddOrEditAccountMiscTypeModal";
import { getMiscType } from "../../service/washTypeService";
import { toast } from "react-toastify";
import { getLoaderToastSettings, getLoaderToastMessage } from "../common/ToastManager";
import { createAccountMiscType, deleteAccountMiscTypes, updateAccountMiscType } from "../../service/accountService";

function AccountMiscTypeList(props) {
    const [accountMiscTypes, setAccountMiscTypes] = useState([]);
    const [miscTypes, setMiscTypes] = useState([]);
    const [paginatedAccountMiscTypes, setPaginatedAccountMiscTypes] = useState([]);
    const itemsPerpage = 25;
    const [itemOffset, setItemOffset] = useState(0);
    const [endOffset, setEndOffset] = useState(itemsPerpage);
    const [sortingType, setSortingType] = useState("");
    const [selectedMiscType, setSelectedMiscType] = useState({});
    const [selectedMiscTypes, setSelectedMiscTypes] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [dialogBox, setDialogBox] = useState({
        isDialogboxOpen: false,
        dialogBoxMessage: "",
        isConfirmationDialogBox: true,
        dialogBoxIcon: "delete",
        dialogboxName: "",
    });
    const toastLoader = (toastMessage) => toast.loading(toastMessage, getLoaderToastSettings());
    const toastLoaderUpdate = (id, toastMessage, type) => toast.update(id, getLoaderToastMessage(toastMessage, type));
    const [isAddOrEditAccountMiscTypeModalOpen, setIsAddOrEditAccountMiscTypeModalOpen] = useState(false);
    const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
    const [accountId , setAccountId] = useState(0);
    const [locationNumber , setLocationNumber] = useState(0);
    const [selectedMiscTypeId, setSelectedMiscTypeId] = useState(0);

    const fetchMiscTypeData = async () => {
        var result = await getMiscType();
        if (result.status === 200) {
            setMiscTypes(result.data.Washtypes ? result.data.Washtypes : []);
        }
    };

    useEffect(() => {
        setAccountMiscTypes(props.accountMiscTypes);
        fetchMiscTypeData();
        setLocationNumber(props.locationNumber);
        setAccountId(props.accountId);
    }, [props.accountId,props.tabTrigger, props.accountMiscTypes]);

    function openIsAddorEditAccountMiscTypeModal() {
        setIsAddOrEditAccountMiscTypeModalOpen(true);
    }

    function closeIsAddorEditAccountMiscTypeModal() {
        setIsAddOrEditAccountMiscTypeModalOpen(false);
    }

    const  handleEditMiscType = async(miscType) => {
        setIsEditModeEnabled(true);
        setSelectedMiscType(miscType);
        setSelectedMiscTypeId(miscType.washTypeId);
        openIsAddorEditAccountMiscTypeModal();
    }

    const handleAddMiscType = async()=>{
        setIsEditModeEnabled(false);
        setSelectedMiscType({});
        setSelectedMiscType(0);
        openIsAddorEditAccountMiscTypeModal();
    }    


    const handleDelete = () => {
        setDialogBox({
            isConfirmationDialogBox: true,
            dialogBoxMessage: "Are you sure you want to delete selected misc types?",
            dialogBoxIcon: "delete",
            isDialogboxOpen: true,
        });
    }

    const handleUpdateMiscType = async(miscTypeInfo) => {
        let toastId = toastLoader("Please wait...");
        const payload =
            {
                washTypeId: miscTypeInfo.washTypeId,
                specialRate: miscTypeInfo.specialRate,
            };
        const response = await updateAccountMiscType(accountId, locationNumber, selectedMiscTypeId, payload);
        if(response.status === 200){
            toastLoaderUpdate(toastId, "WashType updated successfully!", "success");
            props.fetchAccountWashInformation();
            closeIsAddorEditAccountMiscTypeModal();
        }
        else{
            toastLoaderUpdate(toastId, "WashType updation failed!", "error");
        }
    }

    const handleCreateAccountMiscType = async(miscTypeInfo) => {
        let toastId = toastLoader("Please wait...");
        const payload =
            {
                washTypeId: Number(miscTypeInfo.washTypeId),
                specialRate: Number(miscTypeInfo.specialRate),
            };
        const response = await createAccountMiscType(accountId, locationNumber, payload);
        if(response.status === 201){
            toastLoaderUpdate(toastId, "WashType created successfully!", "success");
            props.fetchAccountWashInformation();
            closeIsAddorEditAccountMiscTypeModal();
        }
        else{
            toastLoaderUpdate(toastId, "WashType creation failed!", "error");
        }
    }

    const deleteMiscTypes = async () => {
        setDialogBox({ isDialogboxOpen: false });
        let toastId = toastLoader("Please wait...");
        try {
                const response = await deleteAccountMiscTypes(accountId,locationNumber, selectedMiscTypes);
                if (response.status === 200) {
                    toastLoaderUpdate(toastId, "Miscellaneous charge deleted successfully!", "success");
                    props.fetchAccountWashInformation();
                } else {
                    toastLoaderUpdate(toastId, "Miscellaneous charge detetion failed!", "error");
                }
        } catch (error) {
            toastLoaderUpdate(toastId, "Miscellaneous charge delete failed!", "error");
            console.error("Error deleting Miscellaneous charge:", error);
        }
    };

    useEffect(() => {
        setSelectAll(paginatedAccountMiscTypes.length > 0 && 
            selectedMiscTypes.length > 0 && 
            paginatedAccountMiscTypes.length === selectedMiscTypes.length);
    }, [selectedMiscTypes]);

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setSelectedMiscTypes(!selectAll ? paginatedAccountMiscTypes.map(miscType => miscType.washTypeId) : []);
    };

    const handleSelectRow = (washTypeId, isChecked) => {
        if (isChecked) {
            setSelectedMiscTypes(prevSelected => [...prevSelected, washTypeId]);
        } else {
            setSelectedMiscTypes(prevSelected => prevSelected.filter(id => id !== washTypeId));
        }
    };

    function clearSelectedItems(){
        setSelectedMiscTypes([]);
    }

    function setMiscTypeInfoList() {
        return paginatedAccountMiscTypes.map((miscType, index) => {
            if(miscType.washTypeCode !== "TRIPMIN")
                return (
                    <AccountMiscTypeListTableRow
                        key={index}
                        miscType = {miscType}
                        index = {index}
                        isSelected={selectedMiscTypes.includes(miscType.washTypeId)}
                        onRowSelect={handleSelectRow}
                        handleUpdate={handleEditMiscType}
                    />)
        });
    }

    function sortField(sortingField) {
        switch (sortingField) {
            case "accMiscTypeField":
                accountMiscTypes.sort(function (a, b) {
                    var nameA = a.miscTypeId; // ignore upper and lowercase
                    var nameB = b.miscTypeId; // ignore upper and lowercase
                    if (sortingType === "accMiscTypeASC") {
                        return nameA < nameB ? 1 : -1;
                    } else {
                        return nameA < nameB ? -1 : 1;
                    }
                });
                break;
            case "accVehicleNumberField":
                accountMiscTypes.sort(function (a, b) {
                    var nameA = a.vehicleNumber.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.vehicleNumber.toUpperCase(); // ignore upper and lowercase
                    if (sortingType === "accVehicleNumberASC") {
                        return nameA < nameB ? 1 : -1;
                    } else {
                        return nameA < nameB ? -1 : 1;
                    }
                });
                break;
            case "accPreSelectedMiscTypeField":
                accountMiscTypes.sort(function (a, b) {
                    var nameA = a.isPreSelected; // ignore upper and lowercase
                    var nameB = b.isPreSelected; // ignore upper and lowercase
                    if (sortingType === "accPreSelectedMiscTypeASC") {
                        return nameA < nameB ? 1 : -1;
                    } else {
                        return nameA < nameB ? -1 : 1;
                    }
                });
                break;
            default:
                break;
        }
        setPaginatedAccountMiscTypes(accountMiscTypes.slice(itemOffset, endOffset))
    }

    function tableFieldsHeading(labelName, fieldName) {
        return (
            <div className="table-fields-heading">
                <label className="table-fields-labels">{labelName}</label>
                {fieldName === null ? null : (
                    <div className="sorting-div">
                        <Image
                            src={asc}
                            className="sorting-btns"
                            style={{ cursor: "pointer" }}
                            onClick={() => (
                                setSortingType(fieldName + "ASC"),
                                sortField(fieldName + "Field")
                            )}
                        ></Image>
                        <Image
                            src={desc}
                            className="sorting-btns"
                            style={{ cursor: "pointer" }}
                            onClick={() => (
                                setSortingType(fieldName + "DSC"),
                                sortField(fieldName + "Field")
                            )}
                        ></Image>
                    </div>
                )}
            </div>
        );
    }

    function closeConfirmationDialogBox() {
        setDialogBox({
            isDialogboxOpen: false,
        });
    }

    return (
        <div>
            <Row style={{padding: "0px 10px"}}>
                <Col sm="12" className="top-buttons-div">
                    <Button
                        className="user-button custom-button-width"
                        onClick={handleAddMiscType}
                    >
                        <label className="labels">Add</label>
                    </Button>

                    <Button
                        className="user-button custom-button-width"
                        onClick={handleDelete}
                        disabled = {selectedMiscTypes.length > 0 ? false : true}
                    >
                        <label className="labels">Delete</label>
                    </Button>
                </Col>
            </Row>
            <Row>
                <Row style={{ margin: "auto" }}>
                    <Form>
                        <Table className="table">
                            <thead>
                                <tr className="table-heading">
                                    <th style={{ width: '5%' }} className="table-fields-heading">
                                            <FormCheck
                                                checked={
                                                    selectAll
                                                }
                                                onChange={
                                                    handleSelectAll
                                                }
                                            />
                                    </th>
                                    <th style={{ width: '43%' }} >{tableFieldsHeading("Miscellaneous Type", "accMiscTypeASC")}</th>
                                    <th style={{ width: '42%' }}>{tableFieldsHeading("Miscellaneous Rate", "accWashRate")}</th>
                                    <th style={{ width: '10%' }}>{tableFieldsHeading("", null)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {accountMiscTypes.length > 0 ? (
                                    setMiscTypeInfoList()
                                ) :  (
                                    <tr>
                                        <td colSpan="4" className="user_view no-data-found">
                                            {"No data found"}
                                        </td>
                                    </tr>
                                ) }
                            </tbody>
                        </Table>
                    </Form>
                </Row>
            </Row>
            <Row style={{padding:"10px"}}>
                {accountMiscTypes.length > 0 ? (
                    <Pagination
                        items={accountMiscTypes}
                        currentItems={setPaginatedAccountMiscTypes}
                        itemsPerpage={itemsPerpage}
                        itemOffset={setItemOffset}
                        endOffset={setEndOffset}
                        clearSelectedItems={clearSelectedItems}
                    />
                ) : null}
            </Row>
            
            <AddOrEditAccountMiscTypeModal
                show={isAddOrEditAccountMiscTypeModalOpen}
                onHide={() => closeIsAddorEditAccountMiscTypeModal()}
                handleUpdateMiscType={handleUpdateMiscType}
                handleCreateAccountMiscType={handleCreateAccountMiscType}
                miscTypes = {miscTypes}
                selectedMiscType={selectedMiscType}
                locationNumber= {locationNumber} 
                isEditMode={isEditModeEnabled}
            />
            
            <DialogBoxModal
                show={dialogBox.isDialogboxOpen}
                name={dialogBox.dialogboxName}
                onHide={() => closeConfirmationDialogBox()}
                onEvent={() => {
                        deleteMiscTypes();
                }
                }
                message={dialogBox.dialogBoxMessage}
                icon={dialogBox.dialogBoxIcon}
                isConfirmationDialogBox={dialogBox.isConfirmationDialogBox}
                buttonName="Delete"
            />
        </div>
    )
}
export default AccountMiscTypeList
