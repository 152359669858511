import React, { useState, useEffect } from "react";
import { Row, Button, Form, Col, Table, FormCheck, Image } from "react-bootstrap";
import "./Account.css";
import "../common/Common.css";
import asc from "../../assets/images/asc.png";
import desc from "../../assets/images/desc.png";
import Pagination from "../common/Pagination";
import DialogBoxModal from "../modal/DialogBoxModal";
import { toast } from "react-toastify";
import { getLoaderToastSettings, getLoaderToastMessage } from "../common/ToastManager";
import AccountWashTypeListTableRow from "./AccountWashTypeListTableRow";
import AddOrEditAccountWashTypeModal from "./AddOrEditAccountWashTypeModal";
import { createAccountWashType, deleteAccountWashTypes, updateAccountWashType } from "../../service/accountService";

function AccountWashTypeList(props) {
    const [accountWashTypes, setAccountWashTypes] = useState([]);
    const [washTypes, setWashTypes] = useState([]);
    const [paginatedAccountWashTypes, setPaginatedAccountWashTypes] = useState([]);
    const itemsPerpage = 25;
    const [itemOffset, setItemOffset] = useState(0);
    const [endOffset, setEndOffset] = useState(itemsPerpage);
    const [sortingType, setSortingType] = useState("");
    const [selectedWashType, setSelectedWashType] = useState({});
    const [selectedWashTypeId, setSelectedWashTypeId] = useState(0);
    const [selectedWasheTypes, setSelectedWasheTypes] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [dialogBox, setDialogBox] = useState({
        isDialogboxOpen: false,
        dialogBoxMessage: "",
        isConfirmationDialogBox: true,
        dialogBoxIcon: "delete",
        dialogboxName: "",
    });
    const toastLoader = (toastMessage) => toast.loading(toastMessage, getLoaderToastSettings());
    const toastLoaderUpdate = (id, toastMessage, type) => toast.update(id, getLoaderToastMessage(toastMessage, type));
    const [isAddOrEditAccountWashTypeModalOpen, setIsAddOrEditAccountWashTypeModalOpen] = useState(false);
    const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
    const [accountId , setAccountId] = useState(0);
    const [locationNumber , setLocationNumber] = useState(0);

    useEffect(() => {
        setAccountWashTypes(props.accountWashTypes);
        setWashTypes(props.washTypes);
        setLocationNumber(props.locationNumber);
        setAccountId(props.accountId);
    }, [props]);

    function openIsAddorEditAccountWashTypeModal() {
        setIsAddOrEditAccountWashTypeModalOpen(true);
    }

    function closeIsAddorEditAccountWashTypeModal() {
        setIsAddOrEditAccountWashTypeModalOpen(false);
    }

    const  handleEditWashType = async(washType) => {
        setIsEditModeEnabled(true);
        setSelectedWashType(washType);
        setSelectedWashTypeId(washType.washTypeId);
        openIsAddorEditAccountWashTypeModal();
    }

    const handleAddWashType = async()=>{
        setIsEditModeEnabled(false);
        setSelectedWashType({});
        setSelectedWashTypeId(0);
        openIsAddorEditAccountWashTypeModal();
    }    


    const handleDelete = () => {
        setDialogBox({
            isConfirmationDialogBox: true,
            dialogBoxMessage: "Are you sure you want to delete selected wash types?",
            dialogBoxIcon: "delete",
            isDialogboxOpen: true,
        });
    }

    const handleCreateAccountWashType = async(washTypeInfo) => {
        let toastId = toastLoader("Please wait...");
        const payload =
            {
                washTypeId: washTypeInfo.washTypeId,
                specialRate: washTypeInfo.specialRate,
                washFrequency: washTypeInfo.washFrequency,
                instructions: washTypeInfo.instructions ? washTypeInfo.instructions : 'N/A'
            };
        const response = await createAccountWashType(accountId,locationNumber,payload);
        if(response.status === 201){
            toastLoaderUpdate(toastId, "WashType created successfully!", "success");
            props.fetchAccountWashInformation();
            closeIsAddorEditAccountWashTypeModal();
        }
        else{
            toastLoaderUpdate(toastId, "WashType creation failed!", "error");
        }
    }

    const handleUpdateWashType = async(washTypeInfo) => {
        let toastId = toastLoader("Please wait...");
        const payload =
            {
                washTypeId: washTypeInfo.washTypeId,
                specialRate: washTypeInfo.specialRate,
                washFrequency: washTypeInfo.washFrequency,
                instructions: washTypeInfo.instructions
            };
        const response = await updateAccountWashType(accountId, locationNumber, selectedWashTypeId, payload);
        if(response.status === 200){
            toastLoaderUpdate(toastId, "WashType updated successfully!", "success");
            closeIsAddorEditAccountWashTypeModal();
            props.fetchAccountWashInformation();
        }
        else{
            toastLoaderUpdate(toastId, "WashType creation failed!", "error");
        }
    }

    const deleteWashTypes = async () => {
        setDialogBox({ isDialogboxOpen: false });
        let toastId = toastLoader("Please wait...");
        try {
                console.log('selectedWasheTypes: ',selectedWasheTypes);
                const response = await deleteAccountWashTypes(accountId,locationNumber, selectedWasheTypes);
                if (response.status === 200) {
                    toastLoaderUpdate(toastId, "WashType deleted successfully!", "success");
                    props.fetchAccountWashInformation();
                } else {
                    toastLoaderUpdate(toastId, "WashType detetion failed!", "error");
                }
        } catch (error) {
            toastLoaderUpdate(toastId, "WashType delete failed!", "error");
            console.error("Error deleting washType:", error);
        }
    };

    useEffect(() => {
        setSelectAll(paginatedAccountWashTypes.length > 0 && 
            selectedWasheTypes.length > 0 && 
            paginatedAccountWashTypes.length === selectedWasheTypes.length);
    }, [selectedWasheTypes]);

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setSelectedWasheTypes(!selectAll ? paginatedAccountWashTypes.map(washType => washType.washTypeId) : []);
    };

    const handleSelectRow = (washTypeId, isChecked) => {
        if (isChecked) {
            setSelectedWasheTypes(prevSelected => [...prevSelected, washTypeId]);
        } else {
            setSelectedWasheTypes(prevSelected => prevSelected.filter(id => id !== washTypeId));
        }
    };

    function clearSelectedItems(){
        setSelectedWasheTypes([]);
    }

    function setWashTypeInfoList() {
        return paginatedAccountWashTypes.map((washType, index) => {
                return (<AccountWashTypeListTableRow
                            key={index}
                            washType = {washType}
                            index = {index}
                            isSelected={selectedWasheTypes.includes(washType.washTypeId)}
                            onRowSelect={handleSelectRow}
                            handleUpdate={handleEditWashType}
                        />)
        });
    }

    function sortField(sortingField) {
        switch (sortingField) {
            case "accWashTypetypeField":
                accountWashTypes.sort(function (a, b) {
                    var nameA = a.washtypeId; // ignore upper and lowercase
                    var nameB = b.washtypeId; // ignore upper and lowercase
                    if (sortingType === "accWashTypetypeASC") {
                        return nameA < nameB ? 1 : -1;
                    } else {
                        return nameA < nameB ? -1 : 1;
                    }
                });
                break;
            case "accVehicleNumberField":
                accountWashTypes.sort(function (a, b) {
                    var nameA = a.vehicleNumber.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.vehicleNumber.toUpperCase(); // ignore upper and lowercase
                    if (sortingType === "accVehicleNumberASC") {
                        return nameA < nameB ? 1 : -1;
                    } else {
                        return nameA < nameB ? -1 : 1;
                    }
                });
                break;
            case "accPreSelectedWashTypeField":
                accountWashTypes.sort(function (a, b) {
                    var nameA = a.isPreSelected; // ignore upper and lowercase
                    var nameB = b.isPreSelected; // ignore upper and lowercase
                    if (sortingType === "accPreSelectedWashTypeASC") {
                        return nameA < nameB ? 1 : -1;
                    } else {
                        return nameA < nameB ? -1 : 1;
                    }
                });
                break;
            default:
                break;
        }
        setPaginatedAccountWashTypes(accountWashTypes.slice(itemOffset, endOffset))
    }

    function tableFieldsHeading(labelName, fieldName) {
        return (
            <div className="table-fields-heading">
                <label className="table-fields-labels">{labelName}</label>
                {fieldName === null ? null : (
                    <div className="sorting-div">
                        <Image
                            src={asc}
                            className="sorting-btns"
                            style={{ cursor: "pointer" }}
                            onClick={() => (
                                setSortingType(fieldName + "ASC"),
                                sortField(fieldName + "Field")
                            )}
                        ></Image>
                        <Image
                            src={desc}
                            className="sorting-btns"
                            style={{ cursor: "pointer" }}
                            onClick={() => (
                                setSortingType(fieldName + "DSC"),
                                sortField(fieldName + "Field")
                            )}
                        ></Image>
                    </div>
                )}
            </div>
        );
    }

    function closeConfirmationDialogBox() {
        setDialogBox({
            isDialogboxOpen: false,
        });
    }

    return (
        <div>
            <Row style={{padding: "0px 10px"}}>
                <Col sm="12" className="top-buttons-div">
                    <Button
                        className="user-button custom-button-width"
                        onClick={handleAddWashType}
                    >
                        <label className="labels">Add</label>
                    </Button>

                    <Button
                        className="user-button custom-button-width"
                        onClick={handleDelete}
                        disabled = {selectedWasheTypes.length > 0 ? false : true}
                    >
                        <label className="labels">Delete</label>
                    </Button>
                </Col>
            </Row>
            <Row>
                <Row style={{ margin: "auto" }}>
                    <Form>
                        <Table className="table">
                            <thead>
                                <tr className="table-heading">
                                    <th style={{ width: '5%' }} className="table-fields-heading">
                                            <FormCheck
                                                checked={
                                                    selectAll
                                                }
                                                onChange={
                                                    handleSelectAll
                                                }
                                            />
                                    </th>
                                    <th style={{ width: '30%' }} >{tableFieldsHeading("Wash Type", "accWashtype")}</th>
                                    <th style={{ width: '30%' }}>{tableFieldsHeading("Wash Rate", "accWashRate")}</th>
                                    <th style={{ width: '30%' }}>{tableFieldsHeading("Frequency", "accFrequency")}</th>
                                    <th style={{ width: '30%' }}>{tableFieldsHeading("Instructions", "accInstructions")}</th>
                                    <th style={{ width: '10%' }}>{tableFieldsHeading("", null)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {accountWashTypes.length > 0 ? (
                                    setWashTypeInfoList()
                                ) :  (
                                    <tr>
                                        <td colSpan="4" className="user_view no-data-found">
                                            {"No data found"}
                                        </td>
                                    </tr>
                                ) }
                            </tbody>
                        </Table>
                    </Form>
                </Row>
            </Row>
            <Row style={{padding:"10px"}}>
                {accountWashTypes.length > 0 ? (
                    <Pagination
                        items={accountWashTypes}
                        currentItems={setPaginatedAccountWashTypes}
                        itemsPerpage={itemsPerpage}
                        itemOffset={setItemOffset}
                        endOffset={setEndOffset}
                        clearSelectedItems={clearSelectedItems}
                    />
                ) : null}
            </Row>
            
            <AddOrEditAccountWashTypeModal
                show={isAddOrEditAccountWashTypeModalOpen}
                onHide={() => closeIsAddorEditAccountWashTypeModal()}
                handleUpdateWashType={handleUpdateWashType}
                handleCreateAccountWashType={handleCreateAccountWashType}
                washTypes = {washTypes}
                selectedWashType={selectedWashType}
                locationNumber= {locationNumber} 
                isEditMode={isEditModeEnabled}
            />
            
            <DialogBoxModal
                show={dialogBox.isDialogboxOpen}
                name={dialogBox.dialogboxName}
                onHide={() => closeConfirmationDialogBox()}
                onEvent={() => {
                        deleteWashTypes();
                }
                }
                message={dialogBox.dialogBoxMessage}
                icon={dialogBox.dialogBoxIcon}
                isConfirmationDialogBox={dialogBox.isConfirmationDialogBox}
                buttonName="Delete"
            />
        </div>
    )
}
export default AccountWashTypeList
