import React, { useEffect, useState } from "react";
import { Row, Form } from "react-bootstrap";
import "../users/AddNewUser";
import tick from "../../assets/images/tick.png";
import cancel from "../../assets/images/cancel.png";
import Image from "react-bootstrap/Image";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getFleetNumbers,
} from "../../service/accountService";
import { listAllLead } from "../../service/userService";
import { getWashType } from "../../service/washTypeService";
import Moment from "moment";
import moment from "moment";
import { formatDate } from "../common/helper";
import {v4 as uuidv4} from 'uuid'
import { DELIMITER } from "../common/constant";

function AddNewWashInventory(props) {
  const [isInsertEvent] = useState(props.event === "insert" ? true : false);
  let selectedWashDetails = props.washDetail ? props.washDetail : {};
  const [leadList, setLeadList] = useState([]);
  const [washtypeList, setWashtypeList] = useState([]);
  const [vehicleNumberList, setVehicleNumberList] = useState([]);
  const [isAddNewFleetnumber, setIsAddNewFleetnumber] = useState(false);
  const [accountOptions, setAccountOptions] = useState(props.accountOptions);
  
  const filterByAccount = useFormUpdate(
    selectedWashDetails ? selectedWashDetails.accountId + DELIMITER + selectedWashDetails.accountName + DELIMITER + selectedWashDetails.locationNumber : ""
  );
  const accountLocationNumber = useFormUpdate(selectedWashDetails.locationNumber ? selectedWashDetails.locationNumber : 0);
  const id = useFormUpdate(
    selectedWashDetails.id ? selectedWashDetails.id : ""
  );
  const accountId = useFormUpdate(
    selectedWashDetails.accountId ? selectedWashDetails.accountId : ""
  );
  const washtypeId = useFormUpdate(
    selectedWashDetails.washTypeId ? selectedWashDetails.washTypeId : ""
  );
  const quantity = useFormUpdate(
    selectedWashDetails.quantity ? selectedWashDetails.quantity : ""
  );
  const leadId = useFormUpdate(
    selectedWashDetails.leadId ? selectedWashDetails.leadId : ""
  );
  const vehicleNumber = useFormUpdate(
    selectedWashDetails.vehicleNumber ? selectedWashDetails.vehicleNumber : ""
  );
  const comments = useFormUpdate(
    selectedWashDetails.comments ? selectedWashDetails.comments : ""
  );
  const unitOfMeasurement = useFormUpdate("quantity");

  const currentEndDate = new Date(selectedWashDetails.endTime);

  const [washDate, setWashDate] = useState(
    selectedWashDetails.endTime ? currentEndDate : new Date()
  );

  const accountIdError = useFormUpdate(false);
  const washtypeIdError = useFormUpdate(false);
  const quantityError = useFormUpdate(false);
  const leadIdError = useFormUpdate(false);
  const vehicleNumberError = useFormUpdate(false);
  const commentsError = useFormUpdate(false);
  const washDateError = useFormUpdate(false);

  useEffect(() => {
    setVehicleNumberList(vehicleNumberList);
    fetchListAllLead();
    fetchWashtypeData();
  }, []);

  useEffect(() => {
    accountId.state === "" ? console.log() : fetchFleetNumbers();
  }, [accountId.state]);

  useEffect(() => {
    washtypeId.state === ""
      ? console.log()
      : washtypeList.forEach((obj) =>
        obj.id.toString() === washtypeId.state.toString()
          ? unitOfMeasurement.update(obj.unitOfMeasurement)
          : null
      );
  }, [washtypeId.state]);

  const fetchListAllLead = async () => {
    let response = await listAllLead();
    if (response.status === 200) {
      setLeadList(response.data ? response.data.response : []);
    }
  };

  const fetchWashtypeData = async () => {
    var result = await getWashType();
    if (result.status === 200) {
      setWashtypeList(result.data.Washtypes ? result.data.Washtypes : []);
    }
  };

  const fetchFleetNumbers = async () => {
    var result = await getFleetNumbers(accountId.state);
    if (result.status === 200) {
      const uniqueResponse = [];
      const fleetNumbers = new Set();
      result.data.response.forEach((item) => {
          if (!fleetNumbers.has(item.fleetNumber)) {
              uniqueResponse.push(item);
              fleetNumbers.add(item.fleetNumber);
          }
      });
      setVehicleNumberList(uniqueResponse);
    }
  };

  function validateForm() {
    let validationStatus = true;
    accountIdError.update(false);
    washtypeIdError.update(false);
    quantityError.update(false);
    leadIdError.update(false);
    vehicleNumberError.update(false);
    commentsError.update(false);
    washDateError.update(false);

    if (washDate == null) {
      validationStatus = false;
      washDateError.update(true);
    }

    if (accountId.state === "") {
      validationStatus = false;
      accountIdError.update(true);
    }
    if (washtypeId.state === "") {
      validationStatus = false;
      washtypeIdError.update(true);
    }
    if (leadId.state === "") {
      validationStatus = false;
      leadIdError.update(true);
    }
    if (unitOfMeasurement.state === "quantity") {
      if (vehicleNumber.state === "") {
        validationStatus = false;
        vehicleNumberError.update(true);
      }
    }
    else {
      if (quantity.state === "") {
        validationStatus = false;
        quantityError.update(true);
      }
    }
    return validationStatus;
  }

  function getWashInventoryData() {
    let teamId = "";
    leadList.forEach((obj) =>
      obj.leadId.toString() === leadId.state.toString()
        ? (teamId = obj.teamId)
        : null
    );
    var washDetailsData = {
      accountId: parseInt(accountId.state),
      washTypeId: parseInt(washtypeId.state),
      teamId: parseInt(teamId),
      leadId: parseInt(leadId.state),
      uuid:[uuidv4()],
      comments: comments.state ? comments.state : "N/A",
      startTime: washDate,
      endTime: washDate,
      quantity: unitOfMeasurement.state === "quantity" ? "1" : quantity.state,
      locationNumber: parseInt(accountLocationNumber.state)
    };
    isInsertEvent ? washDetailsData.vehicles = [{"vehicleNumber": unitOfMeasurement.state === "quantity" ? vehicleNumber.state : "N/A", comments: "Admin App" }]
      : washDetailsData.vehicleNumber = unitOfMeasurement.state === "quantity" ? vehicleNumber.state : "N/A";
    return washDetailsData;
  }

  async function createOrUpdateWashInventory(e) {
    e.preventDefault();
    let validationResult = true;
    validationResult = await validateForm();
    if (!validationResult) {} 
    else {
      let washInventoryData = getWashInventoryData();
      if (!isInsertEvent) {
        washInventoryData["id"] = id.state;
      }
      props.onEvent(washInventoryData);
    }
  }

  return (
    <React.Fragment>
      <td></td>
      <td>
        <DatePicker
          className={washDateError.state ? "dateRangeError" : "dateRange"}
          value={washDate === null ? "" : washDate}
          selected={washDate}
          onChange={(date) => setWashDate(date)}
        />
      </td>
      <td>
        <Form.Group as={Row} controlId="formPlaintextAccountId">
          <Form.Select
                value={filterByAccount.state}
                disabled={isInsertEvent ? false : true}
                style={{
                  borderColor: accountIdError.state ? "red" : "",
                  width: "90%",
                }}
                onChange={(e) => {
                  filterByAccount.update(e.target.value);
                  let data = e.target.value.split(DELIMITER);
                  accountIdError.update(false);
                  accountId.update(data[0]);
                  accountLocationNumber.update(data[2]);
                }}
              >
                <option value="0">Select account</option>
                {accountOptions.map((obj,index) => {
                  return (
                    <option key={index} value={obj.value}>
                      {obj.label}
                    </option>
                  );
                })}
              </Form.Select>
        </Form.Group>
      </td>

      <td>
        <Form.Group as={Row} controlId="formPlaintextLead">
          <Form.Select
            value={leadId.state}
            style={{
              borderColor: leadIdError.state ? "red" : "",
              width: "90%",
            }}
            onChange={(e) => {
              leadIdError.update(false);
              leadId.update(e.target.value);
            }}
          >
            <option value="">Select lead</option>
            {leadList.map((obj) => {
              return (
                <option key={obj.leadId} value={obj.leadId}>
                  {obj.leadName}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </td>

      <td>
        <Form.Group as={Row} controlId="formPlaintextWashType">
          <Form.Select
            value={washtypeId.state}
            style={{
              borderColor: washtypeIdError.state ? "red" : "",
              width: "90%",
            }}
            onChange={(e) => {
              washtypeIdError.update(false);
              washtypeId.update(e.target.value);
            }}
          >
            <option value="">Select washtype</option>
            {washtypeList.map((obj) => {
              return (
                <option key={obj.id} value={obj.id}>
                  {obj.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </td>

      <td>
        <Form.Group as={Row} controlId="formPlaintextQuantity">
          <Form.Control
            disabled={
              unitOfMeasurement.state === "quantity" ? true : false
            }
            type="text"
            placeholder={
              unitOfMeasurement.state === "quantity"
                ? "quantity"
                : "in hours"
            }
            value={
              unitOfMeasurement.state === "quantity" ? "1" : quantity.state
            }
            style={{
              borderColor: quantityError.state ? "red" : "",
              width: "90%",
            }}
            onChange={(e) => {
              quantityError.update(false);
              if (!isNaN(+e.target.value)) {
                quantity.update(e.target.value);
              }
            }}
          />
        </Form.Group>
      </td>

      <td>
        <Form.Group as={Row} controlId="formPlaintextVehicleNumber">
          {isAddNewFleetnumber ? (
            <Form.Control
              type="text"
              placeholder="Fleet Number"
              value={vehicleNumber.state}
              style={{
                borderColor: vehicleNumberError.state ? "red" : "",
                width: "90%",
              }}
              onChange={(e) => {
                vehicleNumberError.update(false);
                vehicleNumber.update(e.target.value);
              }}
              onBlur={(e) => {
                let newFleetNumber = {
                  fleetNumber: e.target.value,
                };
                vehicleNumberList.push(newFleetNumber);
                setVehicleNumberList(vehicleNumberList);
                setIsAddNewFleetnumber(false);
              }}
            />
          ) : (
            <Form.Select
              disabled={
                unitOfMeasurement.state === "quantity" ? false : true
              }
              value={vehicleNumber.state}
              style={{
                borderColor: vehicleNumberError.state ? "red" : "",
                width: "90%",
              }}
              onChange={(e) => {
                vehicleNumberError.update(false);
                e.target.value === "add-new-fleet"
                  ? setIsAddNewFleetnumber(true)
                  : vehicleNumber.update(e.target.value);
              }}
            >
              <option value="">Select Fleet Number</option>
              <option value="add-new-fleet">Add New</option>
              {vehicleNumberList.map((obj, index) => {
                return (
                  <option key={index} value={obj.fleetNumber}>
                    {obj.fleetNumber}
                  </option>
                );
              })}
            </Form.Select>
          )}
        </Form.Group>
      </td>

      <td>
        <div className="button-cell">
          <Image
            className="buttons"
            style={{ cursor: "pointer" }}
            src={tick}
            onClick={(e) => createOrUpdateWashInventory(e)}
          />
          <Image
            className="buttons"
            style={{ cursor: "pointer" }}
            src={cancel}
            onClick={props.onCancelNewWashInventory}
          />
        </div>
      </td>
    </React.Fragment>
  );
}
function useFormUpdate(initialState) {
  const [state, setstate] = useState(initialState);

  function update(value) {
    setstate(value);
  }
  return {
    state,
    update,
  };
}
export default AddNewWashInventory;
