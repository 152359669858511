import {
  setToken,
  getToken,
  isLoggedIn,
  subscribe,
  unsubscribe,
} from "./tokenProvider";
import axios from "../components/common/axios";
import { useState, useEffect } from "react";

export const login = (newTokens) => {
  setToken(newTokens);
};

export const logout = () => {
  setToken(null);
};

export const authFetch = async (methodName, apiUrl, body = null, headers = {"Content-Type": "application/json"}, responseType = 'json', responseEncoding = 'utf8') => {
  const token = await getToken();
  var config = {
    method: methodName,
    url: apiUrl,
    headers,
    data: body,
    responseType,
    responseEncoding
  };
  const servicesWithBearerToken = ["auth-service","wash-type-service","user-service","wash-inventory-service","report-service", "account-service-new", "invoice-service", "quickbook-integration-service/v2"];
  const serviceRegexes = servicesWithBearerToken.map(service => new RegExp(`(^|/)${service}(/|$)`));
  const serviceRequiresBearerToken = serviceRegexes.some(regex => regex.test(apiUrl));
  if (serviceRequiresBearerToken) {
    config.headers["Authorization"] = "Bearer " + token;
  } else {
    config.headers["Authorization"] = token;
  }
  return await axios(config);
};


export const authFetchPDF = async (methodName, apiUrl, fileNmae) => {  
  const token = await getToken();
  const servicesWithBearerToken = ["wash-type-service","user-service","wash-inventory-service","report-service"];
  const serviceRequiresBearerToken = servicesWithBearerToken.some(service => apiUrl.includes(service));
  return axios({
    url: apiUrl, //your url
    method: methodName,
    responseType: 'blob', // important
    headers: {
      Authorization: serviceRequiresBearerToken ? "Bearer " + token : token,
    }
}).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileNmae); //or any other extension
    document.body.appendChild(link);
    link.click();
    return response
}).catch(function(error) {
  console.log("error : ",error.response);
  return error.response ? error.response : {};
});;
};


export const useAuth = () => {
  const [isLogged, setIsLogged] = useState(isLoggedIn());

  useEffect(() => {
    const listener = (newIsLogged) => {
      setIsLogged(newIsLogged);
    };

    subscribe(listener);
    return () => {
      unsubscribe(listener);
    };
  }, []);

  return isLogged;
};
