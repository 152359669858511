import React, { useState, useEffect } from "react";
import "./Invoices.css";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { toast } from "react-toastify";
import {
    getLoaderToastMessage,
    getLoaderToastSettings,
    getToastSettings,
  } from "../common/ToastManager";
import CreateInvoiceNow from "./CreateInvoiceNow";
  
function InvoiceNow(props) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
    useEffect(() => {
        props.setTab('invoices');
    }, [props]);
    
    useEffect(() => {
        window.addEventListener(
        "resize",
        () => {
            const ismobile = window.innerWidth < 1200;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        },
        false
        );
    }, [isMobile]);
    const toastError = (toastMessage) =>
        toast.error(toastMessage, getToastSettings());
    const toastLoader = (toastMessage) =>
        toast.loading(toastMessage, getLoaderToastSettings());
    const toastLoaderUpdate = (id, toastMessage, type) =>
        toast.update(id, getLoaderToastMessage(toastMessage, type));

    return (
        <Container className="users_list_view">
        <Tab.Container id="left-user-tabs" defaultActiveKey="invoiceNow">
            <Row className="page-top-margin">
            <Col sm={2}>
                <Nav
                variant="pills"
                className={
                    isMobile
                    ? "flex-row left-side-layout"
                    : "flex-column left-side-layout"
                }
                >
                <Nav.Item>
                    <Nav.Link className="nav-icon" eventKey="invoiceNow">
                    <label className="label">Invoice Now</label>
                    </Nav.Link>
                </Nav.Item>
                </Nav>
            </Col>
            <Col sm={10}>
                <h1 className="title-card">Invoice Now</h1>
                <Tab.Content>
                <Tab.Pane eventKey="invoiceNow">
                    <CreateInvoiceNow
                        toastError={toastError}
                        toastLoader={toastLoader}
                        toastLoaderUpdate={toastLoaderUpdate}
                    />
                </Tab.Pane>
                </Tab.Content>
            </Col>
            </Row>
        </Tab.Container>
        </Container>
    );
}

export default InvoiceNow;
