import React from "react";
import "./Users.css";
import { FormCheck, Table, Button, Form, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import { listUsers } from "../../service/userService";
import Loader from "../common/Loader";
import DialogBoxModal from "../modal/DialogBoxModal";
import {
  setUser,
  updateUser,
  deleteUsers,
  resetPassword,
} from "../../service/userService";
import AddNewUser from "./AddNewUser";
import "react-toastify/dist/ReactToastify.css";
import "../common/Common.css";
import Pagination from "../common/Pagination";
import TableHeading from "../common/TableHeading";
import { ACTIVEROWCOLOR } from "../common/constant";
import { toast } from "react-toastify";
import { getToastSettings } from "../common/ToastManager";

function UsersList(props) {
  //for pagination
  const itemsPerpage = 25;
  const [itemOffset, setItemOffset] = useState(0);
  const [endOffset, setEndOffset] = useState(itemsPerpage);
  const [isLoadPagination, setIsLoadPagination] = useState(true);

  const [currentSortingField, setCurrentSortingField] = useState("");
  const [sortingType, setSortingType] = useState("");

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userList, setCompleteUserList] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  // const [eventName, setEventName] = useState("");
  const [event, setEvent] = useState("");
  const [selectedUsers, setSelectedUsers] = useState({});
  const [isAddNewUser, setIsAddNewUser] = useState(false);
  const [isUpdateUser, setIsUpdateUser] = useState(false);

  const fieldNamesAndLabels = {
    employeeId: "Employee Id",
    name: "Name",
    userName: "Username",
    emailId: "Email Address",
    phone: "Phone",
    noSorting: "Status",
  };

  const [dialogBox, setDialogBox] = useState({
    isDialogboxOpen: false,
    dialogBoxMessage: "",
    isConfirmationDialogBox: true,
    dialogBoxIcon: "delete",
    dialogboxName: "",
  });

  useEffect(() => {
    fetchUserList();
  }, []);

  useEffect(() => {
    sortField(currentSortingField);
    setIsLoadPagination(false);
  }, [users]);

  useEffect(() => {
    setIsLoadPagination(true);
  }, [currentUsers]);

  function showToastMessage(message,type){
    if(type == 'success'){
      toast.success(message, getToastSettings());
    }
    else{
      toast.error(message, getToastSettings());
    }
  }

  function sortField(sortingField) {
    switch (sortingField) {
      case "employeeIdField":
        users.sort(function (a, b) {
          var nameA = a.employeeId.toUpperCase(); // ignore upper and lowercase
          var nameB = b.employeeId.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "employeeIdASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "userNameField":
        users.sort(function (a, b) {
          var nameA = a.userName.toUpperCase(); // ignore upper and lowercase
          var nameB = b.userName.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "userNameASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "emailIdField":
        users.sort(function (a, b) {
          var nameA = a.emailId.toUpperCase(); // ignore upper and lowercase
          var nameB = b.emailId.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "emailIdASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "phoneField":
        users.sort(function (a, b) {
          var nameA = a.phoneNumber.toUpperCase(); // ignore upper and lowercase
          var nameB = b.phoneNumber.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "phoneASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "nameField":
        users.sort(function (a, b) {
          var nameA = a.firstName.toUpperCase() + a.lastName.toUpperCase(); // ignore upper and lowercase
          var nameB = b.firstName.toUpperCase() + b.lastName.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "nameASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      default:
        break;
    }
    setSelectedItems([]);
    setCurrentUsers(users.slice(itemOffset, endOffset));
  }

  const fetchUserList = async () => {
    setLoading(true);

    setCurrentSortingField("employeeIdField");
    setSortingType("employeeIdASC");

    let response = await listUsers();
    if (response.status === 200) {
      setCompleteUserList(response.data.response);
      setUsers(
        response.data
          ? response.data.response.filter(function (item) {
            return item.roleCode === props.roleCode;
          })
          : []
      );
    }
    sortField(currentSortingField);
    setLoading(false);
  };

  async function deleteUserData() {
    let toastId = props.toastLoader("Please wait...");
    setDialogBox({ isDialogboxOpen: false });
    var response = await deleteUsers(selectedUsers);
    if (response.status === 200) {
      props.toastLoaderUpdate(
        toastId,
        "User was successfully deleted!",
        "success"
      );
      fetchUserList();
    } else {
      props.toastLoaderUpdate(toastId, "User deletion failed!", "error");
    }
  }

  async function postUserData(data) {
    let toastId = props.toastLoader("Please wait...");
    var response = await setUser(data);
    console.log("response ", response);
    // setResponseViewer(true);
    if (response.status === 201) {
      props.toastLoaderUpdate(
        toastId,
        "User was successfully saved!",
        "success"
      );
      updateIsAddNewUser(false, null);
      // setUsers([]);
      fetchUserList();
    }
    else if (response.status == 400) {
      props.toastLoaderUpdate(toastId, response.data.response[0], "error");
    }
    else {
      props.toastLoaderUpdate(toastId, "User creation failed!", "error");
    }
  }

  async function updateUserData(data) {
    let toastId = props.toastLoader("Please wait...");
    var response = await updateUser(data);
    if (response.status === 200) {
      props.toastLoaderUpdate(
        toastId,
        "User was successfully edited!",
        "success"
      );
      setSelectedItems([]);
      updateIsAddNewUser(false, null);
      fetchUserList();
    } else {
      props.toastLoaderUpdate(toastId, "User update failed!", "error");
    }
  }

  function upsertUser(data) {
    if (event === "insert") {
      postUserData(data);
    } else if (event === "update") {
      updateUserData(data);
    }
  }

  async function callResetPassword() {
    let toastId = props.toastLoader("Please wait...");
    var data = {
      userName: selectedItems[0],
      password: selectedItems[0],
      newPassword: selectedItems[0],
    };
    console.log(data);
    var response = await resetPassword(data);
    if (response.status === 200) {
      props.toastLoaderUpdate(
        toastId,
        "User password successfully reset!",
        "success"
      );
    } else {
      props.toastLoaderUpdate(toastId, "User password reset failed!", "error");
    }
    setSelectedItems([]);
  }

  function activeOrInactiveUser(userDataIs, activeOrInactive) {
    if (activeOrInactive === "active") {
      userDataIs.active = 0;
      updateUserData(userDataIs);
    } else {
      userDataIs.active = 1;
      updateUserData(userDataIs);
    }
  }

  function openConfirmationModal() {
    let userData = [];
    users.forEach((user) => {
      selectedItems.forEach((item) => {
        if (user.userName === item) {
          userData.push(user);
        }
      });
    });
    setSelectedUsers(userData);

    setDialogBox({
      isConfirmationDialogBox: true,
      dialogBoxMessage: "Are you sure you want to delete this record?",
      dialogBoxIcon: "delete",
      isDialogboxOpen: true,
    });
  }

  function closeConfirmationDialogBox() {
    setDialogBox({
      isDialogboxOpen: false,
    });
    setSelectedItems([]);
    setUsers([]);
    fetchUserList();
  }

  function addNewUser() {
    return (
      <AddNewUser
        roleCode={props.roleCode}
        onEvent={(data) => upsertUser(data)}
        event={event}
        user={selectedUsers}
        userList={userList}
        onCancelNewUser={() => updateIsAddNewUser(false, null)}
        toastMessage = {showToastMessage}
      ></AddNewUser>
    );
  }

  function updateIsAddNewUser(trueOrFalse, event) {
    if (event === "insert") {
      setSelectedUsers({});
      setSelectedItems([]);
      setIsAddNewUser(trueOrFalse);
    } else if (event === "update") {
      users.forEach((user, index) => {
        if (user.userName === selectedItems[0]) {
          setSelectedUsers(user);
        }
      });
      setIsUpdateUser(trueOrFalse);
    } else {
      setIsAddNewUser(trueOrFalse);
      setIsUpdateUser(trueOrFalse);
    }
    setEvent(event);
  }

  function setUsersList() {
    return currentUsers.map((obj) => {
      return isUpdateUser && selectedItems[0] === obj.userName ? (
        <tr className="user_view" key={obj.id}>
          {addNewUser()}
        </tr>
      ) : (
        <tr
          key={obj.id}
          className="user-table-row"
          style={{
            backgroundColor: selectedItems.includes(obj.userName)
              ? ACTIVEROWCOLOR
              : "",
          }}
          onClick={() => {
            updateIsAddNewUser(false, null);
            selectedItems.length > 1
              ? setSelectedItems([obj.userName])
              : selectedItems[0] === obj.userName
                ? setSelectedItems([])
                : setSelectedItems([obj.userName]);
          }}
        >
          <td>
            <FormCheck
              name={obj.userName}
              checked={selectedItems.includes(obj.userName)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedItems([...selectedItems, obj.userName]);
                  updateIsAddNewUser(false, null);
                } else {
                  let updatedList = selectedItems.filter(function (item) {
                    return item !== obj.userName;
                  });
                  setSelectedItems(updatedList);
                  updateIsAddNewUser(false, null);
                }
              }}
            />
          </td>
          <td className="table-cells-content">{obj.employeeId}</td>
          <td className="table-cells-content">
            {obj.firstName + " " + obj.lastName}
          </td>
          <td className="table-cells-content">{obj.userName}</td>
          <td className="table-cells-content">{obj.emailId}</td>
          <td className="table-cells-content">{obj.phoneNumber}</td>
          <td className="table-cells-content">
            {obj.active ? (
              <Button
                className="active-button"
                onClick={() => activeOrInactiveUser(obj, "active")}
              >
                <label className="labels">Active</label>
              </Button>
            ) : (
              <Button
                className="inactive-button"
                onClick={() => activeOrInactiveUser(obj, "inactive")}
              >
                <label className="labels">Inactive</label>
              </Button>
            )}
          </td>
        </tr>
      );
    });
  }

  return (
    <div style={{ position: "relative" }}>
      <h1 className="title-card">User Management</h1>
      <div className="top-buttons-div">
        <Button
          className="user-button"
          onClick={() => updateIsAddNewUser(true, "insert")}
        >
          <label className="labels">Add</label>
        </Button>
        <Button
          className="user-button"
          disabled={selectedItems.length === 1 ? false : true}
          onClick={() => updateIsAddNewUser(true, "update")}
        >
          <label className="labels">Edit</label>
        </Button>
        <Button
          className="user-button"
          disabled={selectedItems.length > 0 ? false : true}
          onClick={() => openConfirmationModal()}
        >
          <label className="labels">Delete</label>
        </Button>
        <Button
          className="user-button"
          disabled={selectedItems.length === 1 ? false : true}
          onClick={() => callResetPassword()}
        >
          <label className="labels">Reset Password</label>
        </Button>
      </div>
      <Row style={{maxHeight: "60vh",overflowY: "scroll"}}>
        <Form>
          <Table className="table">
            <thead>
              <tr className="table-heading">
                <th style={{ width: "10vh" }}>
                  <div className="table-fields-heading">
                    <FormCheck
                      checked={
                        selectedItems.length === currentUsers.length &&
                        currentUsers.length > 0
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          let newList = [];
                          currentUsers.forEach((item, index) =>
                            newList.push(item.userName)
                          );
                          setSelectedItems(newList);
                        } else {
                          setSelectedItems([]);
                        }
                      }}
                    />
                  </div>
                </th>
                {Object.entries(fieldNamesAndLabels).map(
                  ([fieldName, labelIs]) => {
                    return (
                      <th key={fieldName}>
                        <TableHeading
                          labelIs={labelIs}
                          fieldName={fieldName}
                          setSortingType={setSortingType}
                          setCurrentSortingField={setCurrentSortingField}
                          sortField={sortField}
                          sortingType={sortingType}
                          currentSortingField={currentSortingField}
                        />
                      </th>
                    );
                  }
                )}
                {isAddNewUser || isUpdateUser ? (
                  <th style={{ width: "10vh" }}></th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {users.length > 0 ? (
                setUsersList()
              ) : (
                <tr>
                  <td colSpan="6" className="user_view no-data-found">
                    {loading ? (
                      <Loader loading={loading} />
                    ) : isAddNewUser ? null : (
                      "No data found"
                    )}
                  </td>
                </tr>
              )}
              {isAddNewUser ? <tr>{addNewUser()}</tr> : null}
            </tbody>
          </Table>
        </Form>
      </Row>
      <DialogBoxModal
        show={dialogBox.isDialogboxOpen}
        name={dialogBox.dialogboxName}
        onHide={() => closeConfirmationDialogBox()}
        onEvent={() => deleteUserData()}
        message={dialogBox.dialogBoxMessage}
        icon={dialogBox.dialogBoxIcon}
        isConfirmationDialogBox={dialogBox.isConfirmationDialogBox}
        buttonName="Delete"
      />
      {users.length > 0 && isLoadPagination ? (
        <Pagination
          items={users}
          currentItems={setCurrentUsers}
          itemsPerpage={itemsPerpage}
          itemOffset={setItemOffset}
          endOffset={setEndOffset}
          clearSelectedItems={setSelectedItems}
        />
      ) : null}
    </div>
  );
}

export default UsersList;
