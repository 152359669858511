import React, { useState } from 'react'
import { Tooltip, OverlayTrigger, Button, Image } from 'react-bootstrap'
import tooltipIcon from "../../assets/images/tooltip.png";

function ToolTip(props) {
    return (
        <OverlayTrigger
            placement={'right'}
            overlay={
                <Tooltip 
                    bsPrefix
                    style={{
                        backgroundColor: "black",
                        color: "#fff",
                        textAlign: "center",
                        borderRadius: "6px",
                        padding: "3px 10px",
                        position: "absolute",
                        zIndex: "1"
                    }}
                >
                    {props.message}
                </Tooltip>
            }
        >

            <Image
                src={tooltipIcon}
                style={{ width: "15px", height: "15px", verticalAlign: "top", marginLeft: "5px" }}
            />
        </OverlayTrigger>
    )
}

export default ToolTip
