import React, { useEffect, useState } from "react";
import { Modal, Row, Button, Form, FormCheck, Col } from "react-bootstrap";
import Loader from "../common/Loader";
import './AddOrEditModal.css';

function AddOrEditAccountFleetModal(props) {
    const [fleetInfo, setFleetInfo] = useState({
        accountFleetId: 0,
        washTypeId: 0,
        vehicleNumber: '',
        locationNumber: 0,
        isPreSelected: false,
    });
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

    useEffect(() => {
        if (props.isEditMode && props.fleet) {
            setFleetInfo({
                accountFleetId: props.fleet.accountFleetId || 0,
                washTypeId: props.fleet.washTypeId || 0,
                vehicleNumber: props.fleet.vehicleNumber || '',
                locationNumber: props.locationNumber || 0,
                isPreSelected: props.fleet.isPreSelected || false,
            });
        } else {
            setFleetInfo({
                accountFleetId: 0,
                washTypeId: 0,
                vehicleNumber: '',
                locationNumber: props.locationNumber || 0,
                isPreSelected: false,
            });
        }
        setIsSaveButtonDisabled(false);
    }, [props]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFleetInfo((prev) => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!fleetInfo.washTypeId) {
            newErrors.washTypeId = 'Fleet Type is required';
        }
        if (!fleetInfo.vehicleNumber) {
            newErrors.vehicleNumber = 'Vehicle Number is required';
        }
        setErrors(newErrors);
        const result = Object.keys(newErrors).length === 0;
        setIsFormValid(result);
        return result;
    };

    const handleSave = () => {
        console.log("Saving fleet info:", fleetInfo);
        const isValidForm = validateForm();
        if (isValidForm) {
            if(props.isEditMode){
                props.handleUpdateFleet(fleetInfo);
            } else {
                props.handleCreateFleet(fleetInfo);
            }
        }
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop="static"
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Loader className="loader-custom" loading={props.loading} />
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.isEditMode ? "Update Account Fleet" : "Add New Fleet"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group as={Row} className="mb-3 align-items-center">
                        <Form.Label column sm="4">Fleet Type</Form.Label>
                        <Col sm="8">
                            <Form.Select
                                value={fleetInfo.washTypeId}
                                name="washTypeId"
                                onChange={handleChange}
                                isInvalid={!!errors.washTypeId && !fleetInfo.washTypeId}
                            >
                                <option>Select</option>
                                {props.washTypes.map((washType) => (
                                    <option key={washType.id} value={washType.id}>
                                        {washType.name}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {errors.washTypeId}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 align-items-center">
                        <Form.Label column sm="4">Vehicle Number</Form.Label>
                        <Col sm="8">
                            <Form.Control
                                type="text"
                                name="vehicleNumber"
                                value={fleetInfo.vehicleNumber}
                                onChange={handleChange}
                                onBlur={validateForm}
                                isInvalid={!!errors.vehicleNumber && !fleetInfo.vehicleNumber}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.vehicleNumber}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 align-items-center">
                        <Form.Label column sm="4">Pre-Selected</Form.Label>
                        <Col sm="8">
                            <FormCheck
                                name="isPreSelected"
                                onChange={handleChange}
                                checked={fleetInfo.isPreSelected}
                            />
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer style={{ display: !props.responseViewer ? "" : "none" }}>
                <Row className="w-100 justify-content-center">
                    <Row className="buttons-style row-style">
                        <Button
                            variant="primary"
                            className="input_button"
                            size="lg"
                            active
                            disabled={isSaveButtonDisabled}
                            onClick={(e) => {
                                e.preventDefault();
                                setIsSaveButtonDisabled(true);
                                handleSave();
                            }}
                        >
                            {props.isEditMode ? "Update" : "Save"}
                        </Button>{" "}
                        <Button
                            variant="primary"
                            className="input_button"
                            size="lg"
                            active
                            onClick={()=>{
                                setErrors({});
                                props.onHide()
                            }
                            }
                        >
                            Cancel
                        </Button>{" "}
                    </Row>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}

export default AddOrEditAccountFleetModal;