import { Modal, Col, Row, Button } from "react-bootstrap";
import deleteLogo from "../../assets/images/remove.png";
import failed from "../../assets/images/failed.png";
import success from "../../assets/images/success.png";
import save from "../../assets/images/save.png";
import React, { Component } from "react";
import "./DialogBoxModal.css";

function DialogBoxModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      backdrop="static"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Loader className='loader-custom' loading={loading}/> */}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="row-style">
          <Col sm={3}></Col>
          <Col sm={6} className="delete_icon">
            <img
              alt="demo"
              src={
                props.icon === "delete"
                  ? deleteLogo
                  : props.icon === "save"
                  ? save
                  : props.icon === "failed"
                  ? failed
                  : success
              }
              width="100%"
              height="100%"
              className="d-inline-block align-top left_home_page"
            />{" "}
          </Col>
          <Col sm={3}></Col>
        </Row>
        <Row className="row-style">
          <h6 className="message">{props.message}</h6>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row
          style={{
            display: props.isConfirmationDialogBox ? "" : "none",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Row className="buttons-style row-style">
            <Button
              variant="primary"
              className="input_button"
              size="lg"
              active
              onClick={props.onEvent}
            >
              {props.buttonName}
            </Button>{" "}
            <Button
              variant="primary"
              className="input_button"
              size="lg"
              active
              onClick={props.onHide}
            >
              {props.secondaryButtonName ? props.secondaryButtonName : "Cancel"}
            </Button>{" "}
          </Row>
        </Row>
        <Row style={{ display: !props.isConfirmationDialogBox ? "" : "none" }}>
          <Row className="buttons-style row-style">
            <Button
              variant="primary"
              className="input_button"
              size="lg"
              active
              onClick={props.onHide}
            >
              OK
            </Button>{" "}
          </Row>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export default DialogBoxModal;
