import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import stateList from '../common/states.json';
import { isEmptyString, isValidZipcode } from '../common/formValidator';

function AccountLocationModal(props) {
    const locationAddress = {
        accountId: "",
        locationNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: "",
        locationName: "",
        instruction: "",
        accountLocationId: ""
    };
    const [accountLocationAddress, setAccountLocationAddress] = useState(locationAddress);
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid]= useState(false);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

    useEffect(() => {
        if (props.isEditModeEnabled && props.accountLocationAddress) {
            setAccountLocationAddress({
                accountId: props.accountLocationAddress.accountId,
                accountLocationId: props.accountLocationAddress.accountLocationId,
                addressLine1: props.accountLocationAddress.addressLine1 || "",
                addressLine2: props.accountLocationAddress.addressLine2 || "",
                city: props.accountLocationAddress.city || "",
                state: props.accountLocationAddress.state || "",
                zip: props.accountLocationAddress.zip || "",
                locationName: props.accountLocationAddress.locationName || "",
                instruction: props.accountLocationAddress.instruction || "",
                locationNumber: props.accountLocationAddress.locationNumber
            });
        } else {
            setAccountLocationAddress(locationAddress);
        }
        setIsSaveButtonDisabled(false);
    }, [props.accountLocationAddress,props.isEditModeEnabled]);

    function validateForm(){
        const newErrors = {};

        if (!isEmptyString(accountLocationAddress.addressLine1)) {
            newErrors.addressLine1 = "Address Line 1 is required";
        }
        if (!isEmptyString(accountLocationAddress.city)) {
            newErrors.city = "City is required";
        }
        if (!isEmptyString(accountLocationAddress.state)) {
            newErrors.state = "State is required";
        }
        if (!isEmptyString(accountLocationAddress.locationName)) {
            newErrors.locationName = "Location Name is required";
        }
        if (!isValidZipcode(accountLocationAddress.zip)) {
            newErrors.zip = "Zip code is invalid";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setIsFormValid(false);
            return false;
        } else {
            setErrors({});
            setIsFormValid(true);
            return true;
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAccountLocationAddress((prev) => ({ ...prev, [name]: value }));
    };

    const handleSave = () => {
        const isValidForm = validateForm();
        console.log("Saving accountLocationAddress: ", accountLocationAddress);
        if (isValidForm) {
            setAccountLocationAddress(locationAddress);
            props.handleSave(accountLocationAddress);
            props.onHide();
        }
    };

    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{props.isEditModeEnabled ? "Update Location" : "Add New Location"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row style={{padding: '10px'}}>
                        <Form.Group as={Col} controlId="formPlaintextAddressLine1">
                            <Form.Label>Address Line 1</Form.Label>
                            <Form.Control
                                type="text"
                                name="addressLine1"
                                value={accountLocationAddress.addressLine1 || ''}
                                onChange={handleChange}
                                isInvalid={!!errors.addressLine1 && !accountLocationAddress.addressLine1}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.addressLine1}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row style={{padding: '10px'}}>
                        <Form.Group as={Col} controlId="formPlaintextAddressLine2">
                            <Form.Label>Address Line 2 (optional)</Form.Label>
                            <Form.Control
                                type="text"
                                name="addressLine2"
                                value={accountLocationAddress.addressLine2 || ''}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                    <Row style={{padding: '10px'}}>
                        <Form.Group as={Col} controlId="formGridCity">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type="text"
                                name="city"
                                value={accountLocationAddress.city || ''}
                                onChange={handleChange}
                                isInvalid={!!errors.city && !accountLocationAddress.city}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.city}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>State</Form.Label>
                            <Form.Select
                                name="state"
                                value={accountLocationAddress.state || ''}
                                onChange={handleChange}
                                isInvalid={!!errors.state && !accountLocationAddress.state}
                            >
                                <option value="">Select</option>
                                {stateList.map((obj) => (
                                    <option key={obj.stateCode} value={obj.stateCode}>{obj.name}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {errors.state}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridZip">
                            <Form.Label>Zip</Form.Label>
                            <Form.Control
                                type="text"
                                name="zip"
                                value={accountLocationAddress.zip || ''}
                                onChange={handleChange}
                                isInvalid={!!errors.zip && !accountLocationAddress.zip}
                            />
                             <Form.Control.Feedback type="invalid">
                                {errors.zip}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row style={{padding: '10px'}}>
                        <Form.Group as={Col} controlId="formPlaintextLocationName">
                            <Form.Label>Location Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="locationName"
                                value={accountLocationAddress.locationName || ''}
                                onChange={handleChange}
                                isInvalid={!!errors.locationName && !accountLocationAddress.locationName}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.locationName}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row style={{padding: '10px'}}>
                        <Form.Group as={Col} controlId="formPlaintextInstruction">
                            <Form.Label>Instructions</Form.Label>
                            <Form.Control
                                type="text"
                                name="instruction"
                                value={accountLocationAddress.instruction || ''}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Row className="w-100 justify-content-center">
                    <Row className="buttons-style row-style">
                        <Button
                            variant="primary"
                            className="input_button"
                            size="lg"
                            active
                            disabled={isSaveButtonDisabled}
                            onClick={(e) => {
                                e.preventDefault();
                                setIsSaveButtonDisabled(false);
                                handleSave(accountLocationAddress)
                            }}
                        >
                            {props.isEditMode ? "Update" : "Save"}
                        </Button>{" "}
                        <Button
                            variant="primary"
                            className="input_button"
                            size="lg"
                            active
                            onClick={()=>{
                                setAccountLocationAddress(locationAddress);
                                setErrors({});
                                props.onHide()
                            }
                            }
                        >
                            Cancel
                        </Button>{" "}
                    </Row>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}

export default AccountLocationModal;