import React, { useState, useEffect } from "react";
import { FormCheck, Image } from "react-bootstrap";
import "./Account.css";
import "../common/Common.css";
import editIcon from "../../assets/images/edit.png";

function AccountMiscTypeListTableRow(props) {
    const [miscTypeInfo, setMiscTypeInfo] = useState({});
    const [index, setIndex] = useState(0);
    const [isItemChecked, setIsItemChecked] = useState(false);

    useEffect(() => {
        setMiscTypeInfo(props.miscType);
        setIndex(props.index);
        setIsItemChecked(props.isSelected);
    }, [props]);

    return (
            <tr key={'row_'+miscTypeInfo.miscTypeId}
                className="acc-table-row"
            >
                <td>
                    <FormCheck
                        name={index}
                        checked={isItemChecked}
                        onChange={(e) => {
                            setIsItemChecked(!isItemChecked);
                            props.onRowSelect(miscTypeInfo.washTypeId, !isItemChecked);
                        }}
                    />
                </td>
                <td>{miscTypeInfo.washTypeName}</td>
                <td>
                    {miscTypeInfo.specialRate}
                </td>
                <td>
                    <Image 
                        src={editIcon}
                        onClick={(e) => {props.handleUpdate(miscTypeInfo)}}
                        style={{width:"25px", margin: "0px 1px",cursor: "pointer"}}
                    />
                </td>
            </tr >
    )
}

export default AccountMiscTypeListTableRow
