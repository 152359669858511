import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { toast } from "react-toastify";
import "./WashInventory.css";
import WashDetails from "./WashDetails";
import {
  getLoaderToastMessage,
  getLoaderToastSettings,
} from "../common/ToastManager";

function WashInventory(props) {
  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    props.setTab('washinventory');
  }, [props]);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  const toastLoader = (toastMessage) =>
    toast.loading(toastMessage, getLoaderToastSettings());
  const toastLoaderUpdate = (id, toastMessage, type) =>
    toast.update(id, getLoaderToastMessage(toastMessage, type));

  return (
    <Container className="users_list_view">
      <Tab.Container id="left-user-tabs" defaultActiveKey="washDetails">
        <Row className="page-top-margin">
          <Col sm={2}>
            <Nav
              variant="pills"
              className={
                isMobile
                  ? "flex-row left-side-layout"
                  : "flex-column left-side-layout"
              }
            >
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="washDetails">
                  <label className="label">Wash Details</label>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={10}>
            <h1 className="title-card">Wash Inventory</h1>
            <Tab.Content>
              <Tab.Pane eventKey="washDetails">
                <WashDetails
                  toastLoader={toastLoader}
                  toastLoaderUpdate={toastLoaderUpdate}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}

export default WashInventory;
