import React, { useEffect } from "react";
import asc from "../../assets/images/asc.png";
import desc from "../../assets/images/desc.png";
import filter from "../../assets/images/filter.png";
import Image from "react-bootstrap/Image";
import "../common/Common.css";
import { Prev } from "react-bootstrap/esm/PageItem";

function TableHeading(props) {
  const toggleFilter = () => {
    if (props.sortingType.endsWith("ASC")) {
      props.setSortingType(props.fieldName + "DSC");
    } else {
      props.setSortingType(props.fieldName + "ASC");
    }
    props.setCurrentSortingField(props.fieldName + "Field");
  };

  useEffect(() => {
    props.sortField(props.currentSortingField);
  }, [props.sortingType]);

  const filterIcon = () => {
    if (props.fieldName + "Field" === props.currentSortingField) {
      if (props.sortingType.endsWith("ASC")) {
        return desc;
      } else {
        return asc;
      }
    } else {
      return null;
    }
  };
  return (
    <div
      className="table-fields-heading"
      onClick={() => {
        toggleFilter();
      }}
      style={{ cursor: "pointer" }}
    >
      <label className="table-fields-labels">{props.labelIs}</label>
      {props.fieldName === "noSorting" ? null : (
        <div className="sorting-div">
          {filterIcon() && (
            <Image
              src={filterIcon()}
              className="sorting-btns"
              style={{ cursor: "pointer" }}
            ></Image>
          )}
        </div>
      )}
    </div>
  );
}

export default TableHeading;
