import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import React, { useEffect, useState } from "react";
//css
import "./Account.css";
import "react-toastify/dist/ReactToastify.css";
import BasicInfo from "./BasicInfo";
import AccountList from "./AccountList";
import { updateAccount, addBasicAccount, getAllAccountsSummary } from "../../service/accountService";
import AddressInfo from "./AddressInfo";
import AccountFinancialInfo from "./FinancialInfo";
import { isEmpty } from "../common/helper";
import { toast } from "react-toastify";
import { getLoaderToastSettings, getLoaderToastMessage, getToastSettings } from "../common/ToastManager";
import Loader from "../common/Loader";
import AccountContactInfo from "./ContactInfo";
import AccountLocationsInfo from "./AccountLocationsInfo";
import AccountFleetsInfo from "./AccountFleetsInfo";
import AccountWashesInfo from "./AccountWashesInfo";


export default function Accounts(props) {
  const accountTemplate = {
    account: {},
    accountAddress: {},
    accountFinancial: {},
    accountWashtypeSplInstruction: [],
    fleetInformation: [],
  };
  const toastSuccess = (toastMessage) => toast.success(toastMessage, getToastSettings());
  const toastError = (toastMessage) => toast.error(toastMessage, getToastSettings());
  const toastLoader = (toastMessage) => toast.loading(toastMessage, getLoaderToastSettings());
  const toastLoaderUpdate = (id, toastMessage, type) => toast.update(id, getLoaderToastMessage(toastMessage, type));

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [loading, setLoading] = useState(false);
  const [isLoadingAccount, setIsLoadingAccount] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedAccountMiscellaneous, setSelectedAccountMiscellaneous] = useState([]);

  const [tabKey, setTabKey] = useState("basicinfo");
  const [softAddressInfo, setSoftAddressInfo] = useState({});

  const [isAddingNewAccount, setIsAddingNewAccount] = useState(false)

  const [isBasicInfoValidated, setIsBasicInfoValidated] = useState(false);
  const [isAddressInfoValidated, setIsAddressInfoValidated] = useState(false);
  const [isWashInfoValidated, setIsWashInfoValidated] = useState(false);
  const [isFleetInfoValidated, setIsFleetInfoValidated] = useState(false);
  const [isUpdateAccount, setIsUpdateAccount] = useState(false);
  const [tabTrigger, setTabTrigger]=useState(false);
  useEffect(() => {
    props.setTab('accounts');
  }, [props]);

  useEffect(() => {
    fetchAccountListSummary();
  }, []);

  // useEffect(() => {
  //  isLoadingAccount ? 
  // }, [isLoadingAccount]);

  function setFormFieldsToAdd() {
    setTabKey("basicinfo");
  }

  function getSelectedAccount(selectedAccountInfo, selectedMiscellaneousChargesInfo) {
    setSelectedAccountMiscellaneous(selectedMiscellaneousChargesInfo ? selectedMiscellaneousChargesInfo : []);
    setSelectedAccount(
      !isEmpty(selectedAccountInfo) ? selectedAccountInfo : selectedAccountInfo);
    // setIsAddingNewAccount(false)
  }

  function getAccount(accountInfo, addressInfo) {
    setSelectedAccount((prevState) => ({
      ...prevState,
      account: accountInfo !== undefined ? accountInfo : {},
    }));
    setSoftAddressInfo(accountInfo);
    setSoftAddressInfoToSelectedAccount({}, addressInfo);
    setIsBasicInfoValidated(true);
  }

  function setSoftAddressInfoToSelectedAccount(accountAddressInfo, softAddress) {
    let accountAddress = {}
    if (!isEmpty(accountAddressInfo)) {
      let selectedSoftAccountAddress = softAddressInfo ? softAddressInfo : {}
      accountAddress = { ...accountAddressInfo, ...selectedSoftAccountAddress }
    }
    else if (!isEmpty(softAddress)) {
      let selectedAccountAddress = selectedAccount["accountAddress"] ? selectedAccount["accountAddress"] : {};
      accountAddress = { ...selectedAccountAddress, ...softAddress }
    }
    setSelectedAccount((prevState) => ({
      ...prevState,
      accountAddress: accountAddress,
    }));
    return accountAddress
  }

  function getAccountAddress(accountAddressInfo) {
    setSoftAddressInfoToSelectedAccount(accountAddressInfo, {});
    setIsAddressInfoValidated(true);
  }

  function getWashTypeInfo(washtypes) {
    setSelectedAccount((prevState) => ({
      ...prevState,
      accountWashtypeSplInstruction: washtypes,
    }));
    // setSelectedAccount((prevState) => ({
    //   ...prevState,
    //   accountFinancial: financialInfo,
    // }));
    setIsWashInfoValidated(true);
  }

  function getFinancialInfo(financialInfo) {
    console.log("financialInfo",financialInfo);
    setSelectedAccount((prevState) => ({
      ...prevState,
      accountFinancial: financialInfo,
    }));
    setIsWashInfoValidated(true);
  }
  function getFleetInfo(fleetInfoData) {
    setSelectedAccount((prevState) => ({
      ...prevState,
      fleetInformation: fleetInfoData,
    }));
    setIsFleetInfoValidated(true);
  }

  function getContactInfo(contactInfoData,accountData) {
    console.log("accountData", accountData)
    setSelectedAccount((prevState) => ({
      ...prevState,
      accountContact: contactInfoData,
      account:accountData
    }));
    console.log("sel",selectedAccount)
    setIsFleetInfoValidated(true);
  }

  function getAccountData() {
    return selectedAccount;
  }


  async function updateAccountInfo(data) {

    const currentUpdatingData = {
      "basicinfo": {"accountId":data.accountId, "account":data.account, "accountAddress": data.accountAddress},
      "address":{"accountId":data.accountId, "accountAddress": data.accountAddress},
      "washInfo":{"accountId":data.accountId,"accountWashtypeSplInstruction": data.accountWashtypeSplInstruction},
      "financial" : {"accountId":data.accountId, "accountFinancial": data.accountFinancial},
      "fleet":{"accountId":data.accountId, "fleetInformation": data.fleetInformation},
      "contact":{"accountId":data.accountId, "contactInformation": data.accountContact},
      }
    const nextTabKey = {
      "basicinfo": "address",
      "address": "contact",
      "contact":"financial",
      "financial": "accountLocation",
      "accountLocation": "accountFleet",
      "accountFleet": "washInfo",
      "washInfo" : "basicinfo",
    }

    let updatingAccountData = currentUpdatingData[tabKey];
  
    setIsUpdateAccount(false);
    let toastId = toastLoader("Please wait...");
    console.log("updatingAccountData",updatingAccountData)
    var response = await updateAccount(updatingAccountData);
    if (response.status === 200) {
      setIsUpdateAccount(true);
      toastLoaderUpdate(toastId, "Account updated successfully!", "success");
      if(isAddingNewAccount){
        let nextKey = nextTabKey[tabKey]
        setTabKey(nextKey);
        nextKey === "basicinfo" ? setIsAddingNewAccount(false) : setIsAddingNewAccount(true)
      }
      
    } else {
      toastLoaderUpdate(toastId, "Account update failed!", "error");
    }
  }

  async function addBasicAccountInfo(data) {
    let toastId = toast.loading("Please wait...", getLoaderToastSettings());
    var response = await addBasicAccount(data);
    if (response.status === 200) {
      
      setIsBasicInfoValidated(false);
      setIsAddressInfoValidated(false);
      setIsFleetInfoValidated(false);
      setIsWashInfoValidated(false);
      // setSelectedAccount({});
      fetchAccountListSummary();
      selectedAccount["accountId"] = response.data.response
      getSelectedAccount(selectedAccount,[])
      toastLoaderUpdate(toastId, "Account created successfully!", "success");
      return true
    } else {
      toastLoaderUpdate(toastId, "Account creation failed!", "error");
      return false
    }
  }
  // async function addAccountInfo(data) {
  //   let toastId = toast.loading("Please wait...", getLoaderToastSettings());
  //   var response = await addAccount(data);
  //   if (response.status === 200) {
  //     // let accountId = response.data.response.id;
  //     // let newAccount = {...selectedAccount,accountId: accountId}
  //     // setAccounts([newAccount,...accounts]);
  //     // setSelectedAccount((prevState) => ({
  //     //   ...prevState,
  //     //   accountId: accountId,
  //     // }));
  //     setIsBasicInfoValidated(false);
  //     setIsAddressInfoValidated(false);
  //     setIsFleetInfoValidated(false);
  //     setIsWashInfoValidated(false);
  //     setSelectedAccount({});
  //     fetchAccountListSummary();
  //     toastLoaderUpdate(toastId, "Account created successfully!", "success");
  //   } else {
  //     toastLoaderUpdate(toastId, "Account creation failed!", "error");
  //   }
  // }

  async function saveAccount(nextTabKey) {
    if (selectedAccount.accountId) {
      await updateAccountInfo(selectedAccount);
      // fetchAccountListSummary();
    }
    else {
      // if ((isBasicInfoValidated && isAddressInfoValidated && isWashInfoValidated && isFleetInfoValidated)) {
      //   await addAccountInfo(getAccountData());
      // }
      if((isBasicInfoValidated && isAddressInfoValidated)){
        let isSuccess = await addBasicAccountInfo(getAccountData())
        setIsAddingNewAccount(true)
        if(isSuccess) setTabKey(nextTabKey);
      }
      else {
        setTabKey(nextTabKey);
      }
    }
  }

  const fetchAccountListSummary = async () => {
    setLoading(true);
    let response = await getAllAccountsSummary();
    if (response.status === 200) {
      setAccounts(response.data ? response.data.response : []);
    }
    setLoading(false);
  };

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  return (
    <Container className="users_list_view">
      <Tab.Container id="left-user-tabs" activeKey={tabKey}>
        <Row className="page-top-margin">
          <Col sm={2}>
            <Nav
              variant="pills"
              className={
                isMobile
                  ? "flex-row left-side-layout"
                  : "flex-column left-side-layout"
              }
              onSelect={(selectedKey) => {
                setTabTrigger(!tabTrigger);
                setTabKey(`${selectedKey}`)
              }}
            >
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="basicinfo">
                  <label className="label">Basic Profile</label>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="address">
                  <label className="label">Address Details</label>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="contact">
                  <label className="label">Contact Details</label>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="financial">
                  <label className="label">Financial Information</label>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="accountLocation">
                  <label className="label">Location</label>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="accountFleet">
                  <label className="label">Fleet Information</label>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="washInfo" >
                  <label className="label">Wash Information</label>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={7}>
            <h1 className="title-card">Account Management</h1>
            {isLoadingAccount ? <Loader loading={isLoadingAccount} /> : ""}
            <Tab.Content>
              <Tab.Pane eventKey={"basicinfo"}>
                <BasicInfo
                  basicInfo={selectedAccount["account"] !== undefined ? selectedAccount["account"] : {}}
                  accountAddress={
                    selectedAccount["accountAddress"] !== undefined ? selectedAccount["accountAddress"] : {}
                  }
                  onUpdate={getAccount}
                  onSave={saveAccount}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="address">
                <AddressInfo
                  accountAddress={
                    selectedAccount["accountAddress"] !== undefined ? selectedAccount["accountAddress"] : {}
                  }
                  onUpdate={getAccountAddress}
                  onSave={saveAccount}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="contact">
                <AccountContactInfo
                  contactInfo={
                    selectedAccount["accountContact"] !== undefined ? selectedAccount["accountContact"] : []
                  }
                  basicInfo={selectedAccount["account"] !== undefined ? selectedAccount["account"] : {}}
                  onUpdate={getContactInfo}
                  onSave={saveAccount}
                />
              </Tab.Pane>

              <Tab.Pane eventKey="financial">
                <AccountFinancialInfo
                  accountId = {selectedAccount.accountId}
                  quickBookInfo={
                    selectedAccount["accountFinancial"] !== undefined ? selectedAccount["accountFinancial"] : {}
                  }
                  onUpdate={getFinancialInfo}
                  onSave={saveAccount}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="accountLocation">
                <AccountLocationsInfo
                  accountId = {selectedAccount.accountId}
                  tabTrigger={tabTrigger}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="accountFleet">
                <AccountFleetsInfo
                  toastSuccess={toastSuccess}
                  accountId = {selectedAccount.accountId}
                  tabTrigger={tabTrigger}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="washInfo">
                <AccountWashesInfo
                  accountId = {selectedAccount.accountId}
                  tabTrigger={tabTrigger}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
          <Col sm={3} className="account-list-section-border" style={{height: "100%"}}>
            <Row>
              <AccountList
                accountList={accounts !== undefined ? accounts : []}
                onSelect={getSelectedAccount}
                isLoading={loading}
                onSetLoading = {setIsLoadingAccount}
                onDelete={fetchAccountListSummary}
                toastSuccess={toastSuccess}
                toastError={toastError}
                toastLoader={toastLoader}
                toastLoaderUpdate={toastLoaderUpdate}
                addNewAccount={setFormFieldsToAdd}
                onSave={saveAccount}
                createdData = {selectedAccount}
                onUpdate={isUpdateAccount}
              />
            </Row>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}
