import React, { useState } from "react";
import { Modal, Row, Button, Form } from "react-bootstrap";
import Loader from "../common/Loader";
import ResponseViewer from "./ResponseViewer";
import sampleCSV from '../accounts/sample.csv'
function ImportModal(props) {
  const [fileState, setFileState] = useState({});
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      backdrop="static"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        style={{ display: !props.responseViewer ? "" : "none" }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          {props.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { props.loading ? (<Loader className="loader-custom" loading={props.loading} />)
          : ( <Row style={{ display: !props.responseViewer ? "" : "none" }}>
              <Row>
                <Form>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>{props.message}</Form.Label>
                    <Form.Control
                      type="file"
                      accept={props.fileType}
                      onChange={(e) => {
                        const files = e.target.files;
                        if (files && files.length > 0) {
                          const file = files[0];
                          setFileState({ file });
                        }
                      }}
                    />
                  </Form.Group>
                </Form>
              </Row>
              <Row>
                <a href={sampleCSV} download="samplefile"><span style={{display: "flex",flexDirection: "row-reverse",fontSize: "medium"}}>download sample csv file.</span></a>
              </Row>
            </Row>)
        }
        <ResponseViewer
          responseViewer={props.responseViewer}
          icon={props.icon}
          message={props.responseMessage}
          onHide={props.onHide}
        />
      </Modal.Body>
      <Modal.Footer style={{ display: !props.responseViewer ? "" : "none" }}>
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Row className="buttons-style row-style">
            <Button
              variant="primary"
              className="input_button"
              size="lg"
              active
              onClick={(e) => {
                e.preventDefault();
                fileState.file == undefined
                  ? console.log("No File ")
                  : props.onEvent(fileState.file);
                setFileState({});
              }}
            >
              Import
            </Button>{" "}
            <Button
              variant="primary"
              className="input_button"
              size="lg"
              active
              onClick={props.onHide}
            >
              Cancel
            </Button>{" "}
          </Row>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export default ImportModal;
