import React, { useState,useEffect } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { toast } from "react-toastify";
import "./Report.css";
import AccountActivityReport from "./AccountActivityReport";
import { getLoaderToastMessage, getLoaderToastSettings, getToastSettings } from "../common/ToastManager";
import LeadReport from "./LeadReport";
import WashDetailsReport from "./WashDetailsReport";

function report(props) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
    const toastSuccess = (toastMessage) => toast.success(toastMessage, getToastSettings());
    const toastError = (toastMessage) => toast.error(toastMessage, getToastSettings());
    const toastLoader = (toastMessage) => toast.loading(toastMessage, getLoaderToastSettings());
    const toastLoaderUpdate = (id, toastMessage, type) => toast.update(id, getLoaderToastMessage(toastMessage, type));
    
    useEffect(() => {
        props.setTab('reports');
      }, [props]);

    return (
        <Container className="users_list_view">
            <Tab.Container id="left-user-tabs" defaultActiveKey="accountActivityReport">
                <Row className="page-top-margin">
                    <Col sm={2}>
                        <Nav
                            variant="pills"
                            className={
                                isMobile
                                    ? "flex-row left-side-layout"
                                    : "flex-column left-side-layout"
                            }
                        >
                            <Nav.Item>
                                <Nav.Link className="nav-icon" eventKey="accountActivityReport">
                                    <label className="label">Account Activity Report</label>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="nav-icon" eventKey="leadReport">
                                    <label className="label">Lead Report</label>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="nav-icon" eventKey="washDetailsReport">
                                    <label className="label">Wash Details Report</label>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={10}>
                        <h1 className="title-card">Report Management</h1>
                        <Tab.Content>
                            <Tab.Pane eventKey="accountActivityReport">
                                <AccountActivityReport
                                    toastSuccess={toastSuccess}
                                    toastError={toastError}
                                    toastLoader={toastLoader}
                                    toastLoaderUpdate={toastLoaderUpdate}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="leadReport">
                                <LeadReport
                                    toastSuccess={toastSuccess}
                                    toastError={toastError}
                                    toastLoader={toastLoader}
                                    toastLoaderUpdate={toastLoaderUpdate}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="washDetailsReport">
                                <WashDetailsReport
                                    toastSuccess={toastSuccess}
                                    toastError={toastError}
                                    toastLoader={toastLoader}
                                    toastLoaderUpdate={toastLoaderUpdate}
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Container>
    )
}

export default report
