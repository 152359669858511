import Toolbar from "./components/common/Toolbar";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
//Screens
import Home from "./components/home/Home";
import Login from "./components/login/Login";
import React from "react";
import ProtectedRoute from "./components/common/ProtectedRoute";
import { UserContext } from "./AppContext";
import Users from "./components/users/Users";
import Washtype from "./components/washtype/Washtype";
import Accounts from "./components/accounts/Accounts";
import Invoices from "./components/invoice/Invoices";
import ForgotPassword from "./components/forgotpassword/Forgotpassword";
import Report from "./components/report/Report";
import WashInventory from "./components/washinventory/WashInventory";
import ResetPassword from "./components/login/ResetPassword";
import InvoiceNow from "./components/invoice/InvoiceNow";
import { ToastContainer } from "react-toastify";

function App() {
  const [user, setUser] = useState("");
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [tabName, setTabName] = useState("home");

  let navigate = useNavigate();

  useEffect(() => {
    var localUser = JSON.parse(localStorage.getItem("user"));
    if (localUser) {
      if(localUser.isPasswordReset){
        setIsPasswordReset(true);
        navigate("/reset/password");
      }
      setUser(localUser);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  function setTab(tabName) {
    setTabName(tabName);
  }

  function onResetPassword(){
    setIsPasswordReset(false);
    setUser((prevState) => ({
      ...prevState,
      isPasswordReset: 0,
    }));
    window.location.reload();
    navigate('/');
  }
  
  function setAdminUser(result) {
    if (result) {
      setUser(result);
      if (result.isPasswordReset) {
        setIsPasswordReset(true);
        navigate("/reset/password");
      } else {
        navigate(-1) ? navigate(-1) : navigate('/');
      }
    } else {
      setUser("");
    }
    window.location.reload();
  }

  return (
    <UserContext.Provider value={user ? user : ''}>
      <Toolbar isPasswordReset={isPasswordReset} tabName={tabName}/>
      <Routes>
        <Route path="/" element={<Home setTab={setTab}/>} />
        <Route path="/login" element={user.token ? <Navigate to="/" /> : <Login onLogin={setAdminUser} />} />
        <Route path="/reset/password" element={!user.isPasswordReset ? <Navigate to="/" /> : <ProtectedRoute><ResetPassword onReset={onResetPassword} /></ProtectedRoute>} />
        <Route path="/forgotpassword" element={user.token ? <Navigate to="/" /> : <ForgotPassword />} />
        <Route path="/invoice-now" element={<InvoiceNow setTab={setTab}/>} />
        <Route
          path="/accounts"
          element={
            <ProtectedRoute>
              <Accounts setTab={setTab}/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <Users setTab={setTab}/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/washtype"
          element={
            <ProtectedRoute>
              <Washtype setTab={setTab}/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/invoices"
          element={
            <ProtectedRoute>
              <Invoices setTab={setTab}/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports"
          element={
            <ProtectedRoute>
              <Report setTab={setTab}/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/washinventory"
          element={
            <ProtectedRoute>
              <WashInventory setTab={setTab}/>
            </ProtectedRoute>
          }
        />
      </Routes>
      <ToastContainer />
    </UserContext.Provider>
  );
}

export default App;
