import React, { useState, useEffect } from "react";
import "./Users.css";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import Admin from "./UsersList";
import { toast } from "react-toastify";
import {
  getLoaderToastMessage,
  getLoaderToastSettings,
} from "../common/ToastManager";
function Users(props) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  
  useEffect(() => {
    props.setTab('users');
  }, [props]);
  
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  const toastLoader = (toastMessage) =>
    toast.loading(toastMessage, getLoaderToastSettings());
  const toastLoaderUpdate = (id, toastMessage, type) =>
    toast.update(id, getLoaderToastMessage(toastMessage, type));

  return (
    <Container className="users_list_view">
      <Tab.Container id="left-user-tabs" defaultActiveKey="admin">
        <Row className="page-top-margin">
          <Col sm={2}>
            <Nav
              variant="pills"
              className={
                isMobile
                  ? "flex-row left-side-layout"
                  : "flex-column left-side-layout"
              }
            >
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="admin">
                  <label className="label">Admin</label>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="lead">
                  <label className="label">Lead</label>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-icon" eventKey="technicians">
                  <label className="label">Technicians</label>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content>
              <Tab.Pane eventKey="admin">
                <Admin
                  roleCode="ADM"
                  toastLoader={toastLoader}
                  toastLoaderUpdate={toastLoaderUpdate}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="lead">
                <Admin
                  roleCode="LEAD"
                  toastLoader={toastLoader}
                  toastLoaderUpdate={toastLoaderUpdate}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="technicians">
                <Admin
                  roleCode="USR"
                  toastLoader={toastLoader}
                  toastLoaderUpdate={toastLoaderUpdate}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}

export default Users;
