import Moment from "moment";

export function isEmpty(obj) {

    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
}
export function searchObjects(arrayOfObjects, excludeColumns, searchKey) {
    return arrayOfObjects.filter(item => {
        return Object.keys(item).some(key => {
            if (excludeColumns.includes(key)) return false;
            const value = item[key];
            return value !== null && value !== undefined && value.toString().toLowerCase().includes(searchKey.toLowerCase().trim());
        });
    });
}

export function getDataFieldValue(data, fieldName) {
    if (data) {
        if (data[fieldName] !== null && data[fieldName] !== "" && data[fieldName] !== undefined) {
            return data[fieldName];
        }
        else {
            return "";
        }
    }
}

export function isAllFormFieldsFilled(fields){
    let isAllFieldsFilled = true;
    fields.forEach(field => {
        let result = field.state.toString() ? true : false;
        if (!result) {
            isAllFieldsFilled = result;
        }
    });
    return isAllFieldsFilled;
}

export function isAllFormFieldsFilledArray(fieldsArray){
    let isAllFieldsFilled = true;
    fieldsArray.forEach(fields => {
        fields.forEach(field=>{
            let result = field ? true : false;
            if (!result) {
                isAllFieldsFilled = result;
            }
        })
    });
    return isAllFieldsFilled;
}

export function formatDate(date){
    return Moment(date).format("YYYY-MM-DD")
}

export function formatDateToUTC(date){
    return Moment(date).utc().format("YYYY-MM-DD HH:mm:ss");
}

export function customViewFormatDate(date){
    return Moment(date).format("dddd - MMMM DD,YYYY")
}