import React from "react";
import { useState, useEffect } from "react";
import Loader from "../common/Loader";
import { FormCheck, Row, Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  getWashType,
  addWashType,
  deleteWashTypes,
  updateWashType,
  uploadFile,
} from "../../service/washTypeService";
import DialogBoxModal from "../modal/DialogBoxModal";
import ImportModal from "../modal/ImportModal";
import "../common/Common.css";
import "./Washtype.css";
import AddNewWashType from "./AddNewWashType";
import Pagination from "../common/Pagination";
import TableHeading from "../common/TableHeading";
import {
  getLoaderToastMessage,
  getLoaderToastSettings,
} from "../common/ToastManager";
import { ACTIVEROWCOLOR } from "../common/constant";
import { getToastSettings } from "../common/ToastManager";

const fieldNamesAndLabels = {
  code: "Code",
  name: "Name",
  description: "Description",
  unitOfMeasurement: "Unit of measurement",
  defaultRate: "Default Rate",
  quickbookReferenceId : "Quickbook reference Id"
};

function Washtypelist() {
  //for pagination
  const itemsPerpage = 25;
  const [itemOffset, setItemOffset] = useState(0);
  const [endOffset, setEndOffset] = useState(itemsPerpage);
  const [isLoadPagination, setIsLoadPagination] = useState(true);

  const [currentSortingField, setCurrentSortingField] = useState("");
  const [sortingType, setSortingType] = useState("");

  const [loading, setLoading] = useState(false);
  const [washtypes, setWashTypes] = useState([]);
  const [currentWashTypes, setCurrentWashTypes] = useState([]);

  const [event, setEvent] = useState("");
  const [selectedWashtype, setSelectedWashtype] = useState([]);

  //modal response viewer
  const [responseViewer, setResponseViewer] = useState(false);
  const [icon, setIcon] = useState("");

  //checkbox for list
  const [selectedItems, setSelectedItems] = useState("");

  //dialog box
  const [isDialogboxOpen, setIsDialogboxOpen] = useState(false);
  const [dialogBoxMessage, setDialogBoxMessage] = useState("");
  const [isConfirmationDialogBox, setIsConfirmationDialogBox] = useState(true);
  const [dialogBoxIcon, setDialogBoxIcon] = useState("delete");

  //import modal
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [importModalMessage] = useState("");
  const [importModalName] = useState("Import");
  const [importModalLoading, setImportModalLoading] = useState(false);
  const [importResponseMessage, setImportResponseMessage] = useState("");

  const [isAddNewWashType, setIsAddNewWashType] = useState(false);
  const [isUpdateWashType, setIsUpdateWashType] = useState(false);

  const fetchWashtypeData = async () => {
    setLoading(true);
    setCurrentSortingField("codeField");
    setSortingType("codeASC");
    var result = await getWashType();
    if (result.status === 200) {
      setWashTypes(result.data.Washtypes ? result.data.Washtypes : []);
    }
    sortField(currentSortingField);
    setLoading(false);
  };

  const toastLoader = (toastMessage) =>
    toast.loading(toastMessage, getLoaderToastSettings());
  const toastLoaderUpdate = (id, toastMessage, type) =>
    toast.update(id, getLoaderToastMessage(toastMessage, type));

  async function deleteWashTypesFromList() {
    let toastId = toastLoader("Please wait...");
    setLoading(true);
    setIsDialogboxOpen(false);
    const selectedItemStrings = selectedItems.map(item => item.toString());
    var response = await deleteWashTypes({ ids: selectedItemStrings });
    if (response.status === 200) {
      toastLoaderUpdate(toastId, "Wash Types deleted successfully", "success");
      fetchWashtypeData();
    } else {
      toastLoaderUpdate(toastId, "Delete failed!", "error");
    }
    setLoading(false);
  }

  function showToastMessage(message,type){
    if(type == 'success'){
      toast.success(message, getToastSettings());
    }
    else{
      toast.error(message, getToastSettings());
    }
  }

  async function insertWashType(data) {
    let toastId = toastLoader("Please wait...");
    var response = await addWashType(data);
    setResponseViewer(true);
    if (response.status === 201) {
      toastLoaderUpdate(toastId, "Washtype created successfully!", "success");
      updateIsAddNewWashType(false, null);
      // setWashTypes([]);
      fetchWashtypeData();
    } else {
      toastLoaderUpdate(toastId, "Washtype creation failed!", "error");
    }
  }

  async function updateWashTypeFromList(data) {
    let toastId = toastLoader("Please wait...");
    var response = await updateWashType(data);
    setResponseViewer(true);
    if (response.status === 200) {
      toastLoaderUpdate(toastId, "Washtype updated successfully!", "success");
      updateIsAddNewWashType(false, null);
      fetchWashtypeData();
    } else {
      toastLoaderUpdate(toastId, "Washtype update failed!", "error");
    }
  }

  useEffect(() => {
    fetchWashtypeData();
  }, []);

  useEffect(() => {
    sortField(currentSortingField);
    setIsLoadPagination(false);
  }, [washtypes]);

  useEffect(() => {
    setIsLoadPagination(true);
  }, [currentWashTypes]);

  async function initializeUploadFile(file) {
    setImportModalLoading(true);
    var response = await uploadFile(file);
    if (response.status === 200) {
      setImportResponseMessage("File upload successfull");
      setResponseViewer(true);
      setIcon("success");
    } else {
      setImportResponseMessage("Error Occured while uploading the file");
      setResponseViewer(true);
      setIcon("failed");
    }
    setImportModalLoading(false);
  }

  function sortField(sortingField) {
    switch (sortingField) {
      case "codeField":
        washtypes.sort(function (a, b) {
          var nameA = a.code.toUpperCase(); // ignore upper and lowercase
          var nameB = b.code.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "codeASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "nameField":
        washtypes.sort(function (a, b) {
          var nameA = a.name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "nameASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "descriptionField":
        washtypes.sort(function (a, b) {
          var nameA = a.description.toUpperCase(); // ignore upper and lowercase
          var nameB = b.description.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "descriptionASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "unitOfMeasurementField":
        washtypes.sort(function (a, b) {
          var nameA = a.unitOfMeasurement.toUpperCase(); // ignore upper and lowercase
          var nameB = b.unitOfMeasurement.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "unitOfMeasurementASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "defaultRateField":
        washtypes.sort(function (a, b) {
          var nameA = a.rate; // ignore upper and lowercase
          var nameB = b.rate; // ignore upper and lowercase
          if (sortingType === "defaultRateASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      default:
        break;
    }
    setSelectedItems([]);
    setCurrentWashTypes(washtypes.slice(itemOffset, endOffset));
  }

  function addNewWashType() {
    return (
      <AddNewWashType
        onEvent={(data) => upsertWashType(data)}
        event={event}
        isMiscType= {false}
        washtypeList={washtypes}
        washtype={selectedWashtype}
        toastMessage={showToastMessage}
        onCancelNewWashType={() => updateIsAddNewWashType(false, null)}
      ></AddNewWashType>
    );
  }

  function updateIsAddNewWashType(trueOrFalse, event) {
    if (event === "insert") {
      setSelectedWashtype({});
      setSelectedItems([]);
      setIsAddNewWashType(trueOrFalse);
    } else if (event === "update") {
      washtypes.forEach((washtype) => {
        if (washtype.id === selectedItems[0]) {
          setSelectedWashtype(washtype);
        }
      });
      setIsUpdateWashType(trueOrFalse);
    } else {
      setIsAddNewWashType(trueOrFalse);
      setIsUpdateWashType(trueOrFalse);
    }
    setEvent(event);
  }

  function listWashTypes() {
    return currentWashTypes.map((obj) => {
      return isUpdateWashType && selectedItems[0] === obj.id ? (
        <tr className="user_view" key={obj.id}>
          {addNewWashType()}
        </tr>
      ) : (
        <tr
          key={obj.id}
          className="washType-table-row"
          style={{
            backgroundColor: selectedItems.includes(obj.id)
              ? ACTIVEROWCOLOR
              : "",
          }}
          onClick={() => {
            updateIsAddNewWashType(false, null);
            selectedItems.length > 1
              ? setSelectedItems([obj.id])
              : selectedItems[0] === obj.id
                ? setSelectedItems([])
                : setSelectedItems([obj.id]);
          }}
        >
          <td>
            <FormCheck
              name={obj.id}
              checked={selectedItems.includes(obj.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedItems([...selectedItems, obj.id]);
                  updateIsAddNewWashType(false, null);
                } else {
                  let updatedList = selectedItems.filter(function (item) {
                    return item !== obj.id;
                  });
                  setSelectedItems(updatedList);
                  updateIsAddNewWashType(false, null);
                }
              }}
            />
          </td>
          <td className="table-cells-content">{obj.code}</td>
          <td className="table-cells-content">{obj.name}</td>
          <td className="table-cells-content">{obj.description}</td>
          <td className="table-cells-content">{obj.unitOfMeasurement}</td>
          <td className="table-cells-content">{"$" + obj.rate.toFixed(2)}</td>
          <td className="table-cells-content">{obj.quickbookReferenceId}</td>
        </tr>
      );
    });
  }

  function openConfirmationDialogBox() {
    setIsConfirmationDialogBox(true);
    setDialogBoxMessage("Are you sure you want to delete this record?");
    setDialogBoxIcon("delete");
    setIsDialogboxOpen(true);
  }

  function closeConfirmationDialogBox() {
    setIsDialogboxOpen(false);
    setSelectedItems([]);
    // setWashTypes([]);
    fetchWashtypeData();
  }

  function openImportModal() {
    setIsImportModalOpen(true);
    setResponseViewer(false);
  }
  function closeImportModal() {
    setIsImportModalOpen(false);
    // setWashTypes([]);
    fetchWashtypeData();
  }

  function upsertWashType(data) {
    if (event === "insert") {
      insertWashType(data);
    } else if (event === "update") {
      updateWashTypeFromList(data);
    }
  }

  return (
    <div style={{ position: "relative" }}>
      <h1 className="title-card">Wash Type Management</h1>
      <div className="top-buttons-div">
        <Button
          className="user-button"
          onClick={() => updateIsAddNewWashType(true, "insert")}
        >
          <label className="labels">Add</label>
        </Button>
        <Button
          className="user-button"
          disabled={selectedItems.length === 1 ? false : true}
          onClick={() => updateIsAddNewWashType(true, "update")}
        >
          <label className="labels">Edit</label>
        </Button>
        {/* <Button className="user-button" onClick={() => openImportModal()}>
          <label className="labels">Import</label>
        </Button> */}
        <Button
          className="user-button"
          disabled={selectedItems.length > 0 ? false : true}
          onClick={() => openConfirmationDialogBox()}
        >
          <label className="labels">Delete</label>
        </Button>
      </div>
      <Row style={{maxHeight: "60vh",overflowY: "scroll"}}>
        <Table className="table">
          <thead>
            <tr className="table-heading">
              <th style={{ width: "10vh" }}>
                <div className="table-fields-heading">
                  <FormCheck
                    style={{ float: "right", paddingRight: "20%" }}
                    checked={
                      selectedItems.length === currentWashTypes.length &&
                      currentWashTypes.length > 0
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        let updatedSelectedItems = [];
                        currentWashTypes.forEach((item) =>
                          updatedSelectedItems.push(item.id)
                        );
                        setSelectedItems(updatedSelectedItems);
                      } else {
                        setSelectedItems([]);
                      }
                    }}
                  />
                </div>
              </th>
              {Object.entries(fieldNamesAndLabels).map(([fieldName, labelIs]) => {
                return (
                  <th key={fieldName}>
                    <TableHeading
                      labelIs={labelIs}
                      fieldName={fieldName}
                      setSortingType={setSortingType}
                      setCurrentSortingField={setCurrentSortingField}
                      sortField={sortField}
                      sortingType={sortingType}
                      currentSortingField={currentSortingField}
                    />
                  </th>
                );
              })}
              {isAddNewWashType || isUpdateWashType ? (
                <th style={{ width: "10vh" }}></th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {washtypes.length > 0 ? (
              listWashTypes()
            ) : (
              <tr>
                <td colSpan="6" className="user_view no-data-found">
                  {loading ? <Loader loading={loading} /> : "No data found"}
                </td>
              </tr>
            )}
            {isAddNewWashType ? <tr>{addNewWashType()}</tr> : null}
          </tbody>
        </Table>
      </Row>
      <DialogBoxModal
        show={isDialogboxOpen}
        onHide={() => closeConfirmationDialogBox()}
        onEvent={() => deleteWashTypesFromList()}
        message={dialogBoxMessage}
        icon={dialogBoxIcon}
        isConfirmationDialogBox={isConfirmationDialogBox}
        buttonName="Delete"
      />
      <ImportModal
        show={isImportModalOpen}
        name={importModalName}
        onHide={() => closeImportModal()}
        message={importModalMessage}
        onEvent={(file) => initializeUploadFile(file)}
        responseViewer={responseViewer}
        responseMessage={importResponseMessage}
        icon={icon}
        fileType=".csv"
        loading={importModalLoading}
      />
      {washtypes.length > 0 && isLoadPagination ? (
        <Pagination
          items={washtypes}
          currentItems={setCurrentWashTypes}
          itemsPerpage={itemsPerpage}
          itemOffset={setItemOffset}
          endOffset={setEndOffset}
          clearSelectedItems={setSelectedItems}
        />
      ) : null}
    </div>
  );
}

export default Washtypelist;
