import { ORG_ID } from "../components/common/constant";
import { authFetch } from "./authProvider";
import axios from "axios";

const SERVICE_URL = "wash-type-service/v2/organizations/" + ORG_ID;

export const getWashType = async () => {
  let url = SERVICE_URL + "/wash-types";
  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const getMiscType = async () => {
  let url = SERVICE_URL + "/miscellaneous-wash-types";
  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const addWashType = async (data) => {
  let url = SERVICE_URL + "/wash-type";
  return await authFetch("post", url, data)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const updateWashType = async (data) => {
  let url = SERVICE_URL + "/wash-types/" + data.id;
  return await authFetch("patch", url, data)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const deleteWashTypes = async (payload) => {
  let url = SERVICE_URL + "/wash-types";
  return await authFetch("delete", url, payload)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const uploadFile = async (file) => {
  let getSignedurlApiUrl =
    SERVICE_URL + "/wash-types/upload_url?fileName=" + file.name;
  return await authFetch("get", getSignedurlApiUrl, {})
    .then(async function(response) {
      if (response.status === 200) {
        return await axios({
          method: "PUT",
          url: response.data.response,
          data: file,
          headers: { "Content-Type": "multipart/form-data" },
        });
      } else {
        return {};
      }
    })
    .then(async function(response) {
      if (response.status === 200) {
        let postUploadUrl = SERVICE_URL + "/wash-types/upload";
        let data = { fileName: file.name };
        return await authFetch("post", postUploadUrl, data);
      } else {
        return {};
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};
