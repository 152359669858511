import { ORG_ID } from "../components/common/constant";
import { formatDateToUTC } from "../components/common/helper";
import { authFetch, authFetchPDF } from "./authProvider";

const SERVICE_URL = "report-service/v2/organizations/" + ORG_ID;

export const getAccountActivityReport = async (accountId,startDate,endDate) => {
  let url = SERVICE_URL + "/accounts/" +accountId+ "/startDate/" +formatDateToUTC(startDate)+ "/endDate/" +formatDateToUTC(endDate);
  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log("error :",error.response);
      return error.response ? error.response : {};
    });
};

export const getLeadReport = async (accountId,startDate,endDate) => {
    let url = SERVICE_URL + "/accounts/" +accountId+ "/startDate/" +formatDateToUTC(startDate)+ "/endDate/" +formatDateToUTC(endDate)+ "/lead";
    return await authFetch("get", url, {})
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        console.log("error : ",error.response);
        return error.response ? error.response : {};
      });
  };


  export const getWashDetailsPdf = async (accountId,startDate,endDate) => {
    let fileNmae = "wash-details-" + accountId + '-' + startDate + "-to-" + endDate + ".pdf";
    let url = SERVICE_URL + "/accounts/" +accountId+ "/startDate/" +formatDateToUTC(startDate)+ "/endDate/" +formatDateToUTC(endDate)+ "/washDetails";
    return await authFetchPDF("get", url, fileNmae)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        console.log("error : ",error.response);
        return error.response ? error.response : {};
      });
  };