import axios from "axios";
import { BASEPATH,QB_URL } from './constant';


console.log("BASEPATH : ", BASEPATH);
console.log("REACT_APP_STAGE : ", process.env.REACT_APP_STAGE);
console.log("QB_URL : ", QB_URL);

const instance = axios.create({
    baseURL : BASEPATH
})
export default instance;