//css
import "./Forgotpassword.css";
//components
import React from "react";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { sendMail } from "../../service/userService";
import { toast } from "react-toastify";
import {
  getLoaderToastMessage,
  getLoaderToastSettings,
} from "../common/ToastManager";

function Forgotpassword() {
  const [username, setUsername] = useState("");
  const [userNotFound, setUserNotFound] = useState(false);
  const [isRequestSend, setIsRequestSend] = useState(false);

  const toastLoader = (toastMessage) =>
    toast.loading(toastMessage, getLoaderToastSettings());
  const toastLoaderUpdate = (id, toastMessage, type) =>
    toast.update(id, getLoaderToastMessage(toastMessage, type));

  async function sendMailTo() {
    let toastId = toastLoader("Please wait...");
    var data = {
      userName: username,
    };
    var response = await sendMail(data);
    if (response.status === 200) {
      setIsRequestSend(true);
      toastLoaderUpdate(toastId, "Request send successfully!", "success");
    } else if (response.status === 404) {
      setUserNotFound(true);
      toastLoaderUpdate(toastId, "User not found!", "error");
    } else {
      toastLoaderUpdate(toastId, "Request send failed!", "error");
    }
  }

  return (
    <div className="forgotpassword-window">
      <div className="forgotpassword-head">
        <div className="forgotpassword-heading">SPRAYZ</div>
      </div>
      <div className="forgotpassword-form">
        <div className="heading">Reset Password</div>
        <p className="message">
          Please enter the usename associated with your account and we’ll send
          an email to admin with username to reset your password.
        </p>
        {isRequestSend ? (
          <>
            <p className="message">
              Request send to admin, he will inform you after reset the password
            </p>{" "}
            <a href="/home" className="cancel-btn-label">
              Home
            </a>
          </>
        ) : (
          <>
            <Form.Label className="forgotpassword-headline">
              Username
            </Form.Label>
            <InputGroup className="mb-3">
              <FormControl
                placeholder=""
                id="username"
                className="input_item"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                style={{ borderColor: userNotFound ? "red" : "" }}
                value={username}
                onChange={(e) => (
                  setUserNotFound(false), setUsername(e.target.value)
                )}
              />
            </InputGroup>
            <Button
              disabled={username.length > 0 ? false : true}
              className="forgotpassword-btn"
              size="lg"
              active
              onClick={() => sendMailTo()}
            >
              <div className="forgotpassword-btn-label">Send Request</div>
            </Button>
            <a href="/login" className="cancel-btn-label">
              Cancel
            </a>
          </>
        )}
      </div>
    </div>
  );
}

export default Forgotpassword;
