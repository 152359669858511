import { ORG_ID } from "../components/common/constant";
import { authFetch } from "./authProvider";
import { formatDateToUTC } from "../components/common/helper";


const SERVICE_URL = "wash-inventory-service/v2/organizations/" + ORG_ID;

export const getWashDetails = async (accountId, locationNumber, leadId, startDate, EndDate) => {
  let url =
    SERVICE_URL +
    "/wash-inventories/accounts/" +
    accountId +
    "/locations/" +
    locationNumber +
    "/leads/" +
    leadId +
    "/startTime/" +
    formatDateToUTC(startDate) +
    "/endTime/" +
    formatDateToUTC(EndDate);
  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getUnbilledWashDetails = async (accountId, locationNumber, startDate, EndDate) => {
  let url =
    SERVICE_URL +
    "/unbilled-wash-inventories/accounts/" +
    accountId +
    "/locations/" +locationNumber+ "/startTime/" +
    formatDateToUTC(startDate) +
    "/endTime/" +
    formatDateToUTC(EndDate);
  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const postWashDetail = async (washDetailData) => {
  let url = SERVICE_URL + "/accounts/" + washDetailData.accountId + "/locations/" + washDetailData.locationNumber + "/wash-inventory";
  return await authFetch("post", url, washDetailData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateWashDetail = async (washDetailData) => {
  let url = SERVICE_URL + "/accounts/"+washDetailData.accountId+"/locations/"+washDetailData.locationNumber+"/wash-inventories/" + washDetailData.id;
  console.log(url,washDetailData);
  return await authFetch("patch", url, washDetailData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return error.response;
    });
};

export const bulkUpdateWashDetail = async (washDetailData) => {
  let url = SERVICE_URL + "/accounts/0/wash-inventories";

  return await authFetch("patch", url, washDetailData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error.response);
      return error.response;
    });
};

export const deleteWashDetails = async (idsForDelete) => {
  let url = SERVICE_URL + "/wash-inventories";
  return await authFetch("delete", url, { ids: idsForDelete })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};


export const validateBulkEdit = async (data) => {
  let url =
    SERVICE_URL +
    "/wash-inventories/validate-bulk-edit"
  console.log("the hitting URL: ",url);
  return await authFetch("post", url, data)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};
