import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  FormCheck,
} from "react-bootstrap";
import Loader from "../common/Loader";
import { getAllAccountsSummary } from "../../service/accountService";
import {
  getUnbilledWashDetails,
} from "../../service/washInventoryService";
import TableHeading from "../common/TableHeading";
import Pagination from "../common/Pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ACTIVEROWCOLOR } from "../common/constant";
import { customViewFormatDate } from "../common/helper";
import {invoiceOndemand} from "../../service/invoiceService";
import { useNavigate } from 'react-router-dom';

function CreateInvoiceNow(props) {
    const navigate = useNavigate();
    const itemsPerpage = 25;
    const [itemOffset, setItemOffset] = useState(0);
    const [endOffset, setEndOffset] = useState(itemsPerpage);
    const [dummyList, setDummyList] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [dateFrom, dateTo] = dateRange;
    const [filterByAccount, setFilterByAccount] = useState("0");
    const [accountList, setAccountList] = useState([]);
    const [washDetails, setWashDetails] = useState([]);
    const [currentWashDetails, setCurrentWashDetails] = useState([]);
    const [dateError, setDateError] = useState(false);
    const [filterByAccountError, setFilterByAccountError] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoadPagination, setIsLoadPagination] = useState(true);

    const [currentSortingField, setCurrentSortingField] = useState("");
    const [sortingType, setSortingType] = useState("");

    const fieldNamesAndLabels = {
        date: "Date",
        lead: "Lead",
        washType: "Wash Type",
        quantity: "Quantity",
        fleetNumber: "Fleet Number",
    };

    const [dialogBox, setDialogBox] = useState({
        isDialogboxOpen: false,
        dialogBoxMessage: "",
        isConfirmationDialogBox: true,
        dialogBoxIcon: "delete",
        dialogboxName: "",
    });

    function validateForm() {
        let validationStatus = true;
        setDateError(false);

        if (dateFrom == null || dateTo == null) {
        validationStatus = false;
        setDateError(true);
        }
        if (filterByAccount <= 0){
            validationStatus = false;
            setFilterByAccountError(true);
        }
        return validationStatus;
    }

    async function createInvoiceOndemand(){
        let toastId = props.toastLoader("Please wait...");
        try {
            let washInventoryData = {
                "transactionsFrom" : new Date(dateFrom.setHours(0,0,0)),
                "transactionsTo" : new Date(dateTo.setHours(23,59,59)),
                "washInventoryIds": selectedItems
            }
            let response = await invoiceOndemand(
                filterByAccount,
                washInventoryData
            );
            console.log("response:", response);
            if (response.status === 201) {
                props.toastLoaderUpdate(
                toastId,
                "Invoice created successfully!",
                "success"
                );
                setWashDetails(washDetails.filter(item => !selectedItems.includes(item.id)));
                setSelectedItems([]);
            }        
            else {
            props.toastLoaderUpdate(toastId, "Something went wrong", "error");
            }
        } catch (error) {
            props.toastLoaderUpdate(toastId, "Internal Server Error", "error");
        }
    }

    async function fetchWashDetails() {
        setWashDetails([]);
        setLoading(true);

        setCurrentSortingField("dateField");
        setSortingType("dateASC");
        
        let validationResult = true;
        validationResult = await validateForm();
        if (!validationResult) {
        } else {
        let response = await getUnbilledWashDetails(
            filterByAccount,
            0,
            new Date(dateFrom.setHours(0,0,0)),
            new Date(dateTo.setHours(23,59,59))
        );
        if (response.status === 200) {
            setWashDetails(response.data.washInventory ? response.data.washInventory : []);
        } else if (response.status === 404) {
            setWashDetails([]);
        }
        }
        sortField(currentSortingField);
        setLoading(false);
    }

    useEffect(() => {
        fetchAccountListSummary();
    }, []);

    useEffect(() => {
        sortField(currentSortingField);
        setIsLoadPagination(false);
    }, [washDetails]);

    useEffect(() => {
        setIsLoadPagination(true);
    }, [currentWashDetails]);

    function sortField(sortingField) {
        console.log("flag 111 : ",sortingType)
        switch (sortingField) {
            case "dateField":
                washDetails.sort(function(a, b) {
                var valueA = a.endTime.toUpperCase();
                var valueB = b.endTime.toUpperCase();
                if (sortingType === "dateASC") {
                    return valueA < valueB ? -1 : 1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
                });
                break;
            
            case "accountField":
                washDetails.sort(function(a, b) {
                var valueA = a.accountName.toUpperCase();
                var valueB = b.accountName.toUpperCase();
                if (sortingType === "accountASC") {
                    return valueA < valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? -1 : 1;
                }
                });
                break;

            case "washTypeField":
                washDetails.sort(function(a, b) {
                var valueA = a.washTypeName.toUpperCase();
                var valueB = b.washTypeName.toUpperCase();
                if (sortingType === "washTypeASC") {
                    return valueA < valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? -1 : 1;
                }
                });
                break;
                
            case "quantityField":
                washDetails.sort(function(a, b) {
                var valueA = a.quantity;
                var valueB = b.quantity;
                if (sortingType === "quantityASC") {
                    return valueA < valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? -1 : 1;
                }
                });
                break;

            case "fleetNumberField":
                washDetails.sort(function(a, b) {
                var valueA = a.vehicleNumber.toUpperCase();
                var valueB = b.vehicleNumber.toUpperCase();
                if (sortingType === "fleetNumberASC") {
                    return valueA < valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? -1 : 1;
                }
                });
                break;
                
            default:
                break;
        }
        setSelectedItems([]);
        setCurrentWashDetails(washDetails.slice(itemOffset, endOffset));
    }
    
    const fetchAccountListSummary = async () => {
        let response = await getAllAccountsSummary();
        if (response.status === 200) {
        setAccountList(response.data ? response.data.response : []);
        }
    };

    function listWashDetailsInTable() {
        return currentWashDetails.map((obj) => {
            return (
                <tr
                key={obj.id}
                className="washinventory-table-row"
                style={{
                    backgroundColor: selectedItems.includes(obj.id)
                    ? ACTIVEROWCOLOR
                    : "",
                }}
                onClick={() => {
                    selectedItems.length > 1
                    ? setSelectedItems([obj.id])
                    : selectedItems[0] === obj.id
                    ? setSelectedItems([])
                    : setSelectedItems([obj.id]);
                }}
                >
                <td>
                    <FormCheck
                    name={obj.id}
                    checked={selectedItems.includes(obj.id)}
                    onChange={(e) => {
                        if (e.target.checked) {
                        setSelectedItems([...selectedItems, obj.id]);
                        } else {
                        let updatedList = selectedItems.filter(function(item) {
                            return item !== obj.id;
                        });
                        setSelectedItems(updatedList);
                        }
                    }}
                    />
                </td>
                <td className="table-cells-content">
                    {customViewFormatDate(obj.endTime)}
                </td>
                <td className="table-cells-content">{obj.leadName}</td>
                <td className="table-cells-content">{obj.washTypeName}</td>
                <td className="table-cells-content">{obj.quantity}</td>
                <td className="table-cells-content">{obj.vehicleNumber}</td>
                </tr>
            )
        });
    }

    return (
        <div style={{ position: "relative", marginTop: "4%" }}>
            <Row>
                <Col sm={3}>
                <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextdateRange"
                >
                    <Row>
                    <Form.Label column sm="12">
                        Date Range
                    </Form.Label>
                    </Row>
                    <Row>
                    <DatePicker
                        className={dateError ? "dateRangeError" : "dateRange"}
                        value={dateFrom === null ? "From - To" : dateRange}
                        selectsRange={true}
                        startDate={dateFrom}
                        endDate={dateTo}
                        onChange={(update) => {
                        setDateError(false);
                        setDateRange(update);
                        }}
                    />
                    </Row>
                </Form.Group>
                </Col>
                <Col sm={3}>
                    <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextFilterByAccount"
                    >
                        <Row>
                        <Form.Label column sm="12">
                            Filter By
                        </Form.Label>
                        </Row>
                        <Row>
                            <Form.Select
                                className={filterByAccountError ? "emptyFieldError" : ''}
                                value={filterByAccount}
                                disabled={false}
                                onChange={(e) => {
                                    setFilterByAccountError(false);
                                    setFilterByAccount(e.target.value);
                                }}
                            >
                                <option value="0">Select account</option>
                                {accountList.map((obj) => {
                                return (
                                    <option key={obj.id} value={obj.id}>
                                    {obj.name}
                                    </option>
                                );
                                })}
                            </Form.Select>
                        </Row>
                    </Form.Group>
                </Col>
                <Col sm={6} style={{ paddingTop: "3%" }}>
                    <div className="washInventory-buttons-div" style={{marginTop: "0px !important"}}>
                        <Button className="user-button" onClick={fetchWashDetails}>
                            <label className="labels">View Washes</label>
                        </Button>
                        <Button 
                            className="user-button" 
                            onClick={() =>{
                            navigate("/invoices");
                        }}>
                            <label className="labels">View Invoice</label>
                        </Button>
                        <Button className="user-button" onClick={() => createInvoiceOndemand()} disabled={selectedItems.length === 0}>
                            <label className="labels">Create Invoice</label>
                        </Button>
                    </div>
                </Col>
            </Row>

            <Row style={{ marginTop: "2%",maxHeight: "45vh",overflowY: "scroll" }}>
                <Form>
                    <Table className="table">
                        <thead>
                        <tr className="table-heading">
                            <th>
                            <div className="table-fields-heading">
                                <FormCheck
                                checked={
                                    selectedItems.length === washDetails.length &&
                                    washDetails.length > 0
                                }
                                onChange={(e) => {
                                    if (e.target.checked) {
                                    let newList = [];
                                    washDetails.forEach((item) =>
                                        newList.push(item.id)
                                    );
                                    setSelectedItems(newList);
                                    } else {
                                    setSelectedItems([]);
                                    }
                                }}
                                />
                            </div>
                            </th>
                            {Object.entries(fieldNamesAndLabels).map(
                            ([fieldName, labelIs]) => {
                                return (
                                <th key={fieldName}>
                                    <TableHeading
                                    labelIs={labelIs}
                                    fieldName={fieldName}
                                    setSortingType={setSortingType}
                                    setCurrentSortingField={setCurrentSortingField}
                                    sortField={sortField}
                                    sortingType={sortingType}
                                    currentSortingField={currentSortingField}
                                    />
                                </th>
                                );
                            }
                            )}
                        </tr>
                        </thead>
                        <tbody>
                        {washDetails.length > 0 ? (
                            listWashDetailsInTable()
                        ) : (
                            <tr>
                            <td colSpan="7" className="user_view no-data-found">
                                {loading ? (
                                    <Loader loading={loading} />
                                    ) : "No data found"
                                }
                            </td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </Form>
            </Row>

            {washDetails.length > 0 && isLoadPagination ? (
                <Pagination
                    items={washDetails}
                    currentItems={setCurrentWashDetails}
                    itemsPerpage={itemsPerpage}
                    itemOffset={setItemOffset}
                    endOffset={setEndOffset}
                    clearSelectedItems={setDummyList}
                />
            ) : null}
        </div>
    );
}

export default CreateInvoiceNow;
