
export function getLoaderToastMessage(message, toastType) {
    return {
        render: message,
        type: toastType,
        isLoading: false,
        autoClose: 3000,
        position: "bottom-center",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined
    }
}

export function getLoaderToastSettings() {
    return {
        position: "bottom-center",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        autoClose: 3000,
        draggable: false,
        progress: undefined
    }
}

export function getToastSettings() {
    return {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined
    }
}