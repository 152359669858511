import { ORG_ID } from "../components/common/constant";
import { authFetch } from "./authProvider";

const SERVICE_URL = "user-service/v2/organizations/" + ORG_ID;

export const listUsers = async () => {
  let url = SERVICE_URL + "/users";
  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const setUser = async (userData) => {
  let url = SERVICE_URL + "/user";
  return await authFetch("post", url, userData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return error.response;
    });
};

export const updateUser = async (userData) => {
  let url = SERVICE_URL + "/users/" + userData.id;
  return await authFetch("patch", url, userData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const deleteUser = async (userData) => {
  let url = SERVICE_URL + "/users/" + userData.employeeId;
  return await authFetch("delete", url, userData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const userRoles = async () => {
  let url = SERVICE_URL + "/roles";
  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const deleteUsers = async (userData) => {
  let url = SERVICE_URL + "/users";
  let idsForDelete = [];
  userData.forEach((users) => {
    idsForDelete.push(users.id);
  });
  return await authFetch("delete", url, { ids: idsForDelete })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const listAllLead = async () => {
  let url = SERVICE_URL + "/leads";
  return await authFetch("get", url, {})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error);
      return {};
    });
};

export const resetPassword = async (data) => {
  let url = SERVICE_URL + "/reset";
  return await authFetch("post", url, data)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error.response);
      return error.response;
    });
};

export const sendMail = async (data) => {
  let url = SERVICE_URL + "/forgotPassword";
  return await authFetch("post", url, data)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      console.log(error.response);
      return error.response;
    });
};
