import React from "react";
import { Row, Button, Col } from "react-bootstrap";
import failed from "../../assets/images/failed.png";
import success from "../../assets/images/success.png";
function ResponseViewer(props) {
  return (
    <Row style={{ display: props.responseViewer ? "" : "none" }}>
      <Row className="row-style">
        <Col sm={3}></Col>
        <Col sm={6} className="delete_icon">
          <img
            alt="demo"
            src={props.icon === "failed" ? failed : success}
            width="100%"
            height="100%"
            className="d-inline-block align-top left_home_page"
          />{" "}
        </Col>
        <Col sm={3}></Col>
      </Row>
      <Row className="row-style">
        <h6 className="message">{props.message}</h6>
      </Row>
      <Row className="buttons-style row-style">
        <Button
          variant="primary"
          className="input_button"
          size="lg"
          active
          onClick={props.onHide}
        >
          OK
        </Button>{" "}
      </Row>
    </Row>
  );
}

export default ResponseViewer;
