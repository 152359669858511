import React, { useEffect, useState } from "react";
import { Row, Form, Image, Button, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { getLoaderToastSettings, getLoaderToastMessage } from "../common/ToastManager";
import { updateAccountMiscType } from "../../service/accountService";

function AccountTripMinimum(props) {
    const toastLoader = (toastMessage) => toast.loading(toastMessage, getLoaderToastSettings());
    const toastLoaderUpdate = (id, toastMessage, type) => toast.update(id, getLoaderToastMessage(toastMessage, type));
    const [tripMin, setTripMin] = useState(0);
    const [accountId, setAccountId] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [accountMiscType, setAccountMiscType] = useState({});
    const [locationNumber, setLocationNumber] = useState(0);
    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        setIsEditing(false); 
        handleUpdateMiscType();
    };
    const handleUpdateMiscType = async() => {
        let toastId = toastLoader("Please wait...");
        const payload =
            {
                washTypeId: accountMiscType.washTypeId,
                specialRate: tripMin,
            };
        const response = await updateAccountMiscType(accountId, locationNumber, accountMiscType.washTypeId, payload);
        if(response.status === 200){
            toastLoaderUpdate(toastId, "Trip Minimum updated successfully!", "success");
        }
        else{
            setTripMin(accountMiscType.specialRate);
            toastLoaderUpdate(toastId, "Trip Minimum updation failed!", "error");
        }
    }
    useEffect(() => {
        props.accountMiscTypes.map((miscType, index) => {
            if(miscType.washTypeCode === "TRIPMIN"){
                setTripMin(miscType.specialRate);
                setAccountMiscType(miscType);
            }
        });
        setLocationNumber(props.locationNumber);
        setAccountId(props.accountId);
    }, [props.accountId,props.tabTrigger, props.accountMiscTypes]);
    
    return (
        <Row className="mb-3" style={{ margin: "auto" }}>
            <Form.Group as={Row} className="mb-12" controlId="formPlaintextQuickbooksID" style={{ margin: "auto" }}>
                <Form.Label>Trip Minimum</Form.Label>
                <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col sm="10">
                        <Form.Control
                            type="text"
                            required
                            disabled={!isEditing} 
                            autoComplete="nope"
                            value={tripMin}
                            onChange={(e) => setTripMin(e.target.value)}
                        />
                    </Col>
                    <Col sm="2" style={{display: "flex", flexDirection: "column"}}>
                        <Button className="user-button"
                            onClick={isEditing ? handleSaveClick : handleEditClick}
                        >
                            {isEditing ? (<label className="labels">Save</label>) : (<label className="labels">Edit</label>)}
                        </Button>

                    </Col>
                </Row>
            </Form.Group>
        </Row>
    );
}

export default AccountTripMinimum;
