//css
import "./Loader.css";
import React, { Component } from "react";
export default function Loader(props) {
  return props.loading ? (
    <div className="loader_background" style={{ backgroundColor: props.color }}>
      <div className={props.loader ? props.loader : 'loader'} style={{ fontSize: props.size, color: props.loaderColor ? props.loaderColor : '#3289bb'}}></div>
    </div>
  ) : (
    ""
  );
}
