import React, { useState, useEffect } from "react";
import { FormCheck, Image } from "react-bootstrap";
import "./Account.css";
import "../common/Common.css";
import editIcon from "../../assets/images/edit.png";
import { WASH_FREQUENCY } from "../common/constant";

function AccountWashTypeListTableRow(props) {
    const [washTypeInfo, setWashTypeInfo] = useState({});
    const [index, setIndex] = useState(0);
    const [isItemChecked, setIsItemChecked] = useState(false);
    const [frequencyLabel, setFrequencyLabel] = useState('');

    useEffect(() => {
        setWashTypeInfo(props.washType);
        setIndex(props.index);
        setIsItemChecked(props.isSelected);

        const frequency = WASH_FREQUENCY.find(freq => freq.value === props.washType.washFrequency);
        setFrequencyLabel(frequency ? frequency.label : '');

    }, [props]);

    return (
            <tr key={'row_'+washTypeInfo.washTypeId}
                className="acc-table-row"
            >
                <td>
                    <FormCheck
                        name={index}
                        checked={isItemChecked}
                        onChange={(e) => {
                            setIsItemChecked(!isItemChecked);
                            props.onRowSelect(washTypeInfo.washTypeId, !isItemChecked);
                        }}
                    />
                </td>
                <td>{washTypeInfo.washTypeName}</td>
                <td>
                    {washTypeInfo.specialRate}
                </td>
                <td>
                    {frequencyLabel}
                </td>
                <td>
                    {washTypeInfo.instructions}
                </td>
                <td>
                    <Image 
                        src={editIcon}
                        onClick={(e) => {props.handleUpdate(washTypeInfo)}}
                        style={{width:"25px", margin: "0px 1px",cursor: "pointer"}}
                    />
                </td>
            </tr >
    )
}

export default AccountWashTypeListTableRow
