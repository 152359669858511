import React, { useState,useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./AddNewUser.css";
import { ORG_ID } from "../common/constant";
import tick from "../../assets/images/tick.png";
import cancel from "../../assets/images/cancel.png";
import Image from "react-bootstrap/Image";
import { isValidEmail } from "../common/formValidator";

function AddNewUser(props) {
  const [isInsertEvent] = useState(props.event === "insert" ? true : false);

  let selectedUser = props.user ? props.user : {};
  //const [userRoleList, setUserRoleList] = useState([]);
  const id = useFormUpdate(selectedUser.id ? selectedUser.id : "");
  const employeeId = useFormUpdate(
    selectedUser.employeeId ? selectedUser.employeeId : ""
  );
  const firstName = useFormUpdate(
    selectedUser.firstName ? selectedUser.firstName : ""
  );
  const lastName = useFormUpdate(
    selectedUser.lastName ? selectedUser.lastName : ""
  );
  const emailId = useFormUpdate(
    selectedUser.emailId ? selectedUser.emailId : ""
  );
  const phoneNumber = useFormUpdate(
    selectedUser.phoneNumber ? selectedUser.phoneNumber : ""
  );
  const userName = useFormUpdate(
    selectedUser.userName ? selectedUser.userName : ""
  );
  const password = useFormUpdate(
    selectedUser.password ? selectedUser.password : ""
  );
  const roleCode = useFormUpdate(
    selectedUser.roleCode ? selectedUser.roleCode : props.roleCode
  );
  const isActive = useFormUpdate(
    selectedUser.active ? selectedUser.active : ""
  );
  const userList = useFormUpdate([]);
  const isUserNameExist = useFormUpdate(true);
  const employeeIdError = useFormUpdate(false);
  const firstNameError = useFormUpdate(false);
  const lastNameError = useFormUpdate(false);
  const emailError = useFormUpdate(false);
  const phoneNumberError = useFormUpdate(false);
  const userNameError = useFormUpdate(false);
  const passwordError = useFormUpdate(false);
  const roleCodeError = useFormUpdate(false);

  useEffect(() => {
    userList.update(props.userList);
  }, []);

  // const fetchUserRoles = async () => {
  //   var response = await userRoles();
  //   if (response.status === 200) {
  //     setUserRoleList(response.data ? response.data.response : []);
  //   }
  // };

  function validateForm() {
    let validationStatus = true;
    employeeIdError.update(false);
    firstNameError.update(false);
    lastNameError.update(false);
    emailError.update(false);
    phoneNumberError.update(false);
    userNameError.update(false);
    passwordError.update(false);
    roleCodeError.update(false);

    if (!employeeId.state) {
      validationStatus = false;
      employeeIdError.update(true);
    }
    if (!firstName.state) {
      validationStatus = false;
      firstNameError.update(true);
    }
    if (!lastName.state) {
      validationStatus = false;
      lastNameError.update(true);
    }
    if(roleCode.state !== "LEAD"){
      if (!isValidEmail(emailId.state)) {
        validationStatus = false;
        emailError.update(true);
      }
    }
    if (!phoneNumber.state || phoneNumber.state.length !== 10) {
      validationStatus = false;
      phoneNumberError.update(true);
    }
    if (isInsertEvent) {
      // if (!password.state) {
      //   validationStatus = false;
      //   passwordError.update(true);
      // }

      if (!userName.state) {
        validationStatus = false;
        userNameError.update(true);
      }
    }
    if (!roleCode.state) {
      validationStatus = false;
      roleCodeError.update(true);
    }
    return validationStatus;
  }

  function isUserNameAlreadyExist(username) {
    return userList.state.some(function(item) {
      return item.userName === username;
    }); 
  }

  function getUserFormData() {
    var userData = {
      employeeId: employeeId.state,
      orgId: ORG_ID,
      firstName: firstName.state,
      lastName: lastName.state,
      emailId: emailId.state,
      phoneNumber: phoneNumber.state,
      roleCode: roleCode.state,
      active: isActive.state ? 1 : 0,
    };
    return userData;
  }

  async function upsertUser(e) {
    e.preventDefault();
    let validationResult = true;
    validationResult = await validateForm();
    console.log("validationResult : ",validationResult);
    if (!validationResult) {
    } else {
      let userData = getUserFormData();
      if (props.event === "insert") {
        userData["password"] = userName.state;
        userData["userName"] = userName.state;
      } else if (props.event === "update") {
        userData["id"] = id.state;
        userData["userName"] = userName.state;
      }
      props.onEvent(userData);
    }
  }

  return (
    <React.Fragment>
      <td></td>
      <td>
        <Form.Group as={Row} controlId="formPlaintextEmpId">
          <Col>
            <Form.Control
              type="text"
              placeholder="employee id"
              value={employeeId.state}
              style={{ borderColor: employeeIdError.state ? "red" : "" }}
              onChange={(e) => employeeId.update(e.target.value)}
            />
          </Col>
        </Form.Group>
      </td>

      <td>
        <Form.Group as={Row} controlId="formPlaintextFirstname">
          <Col sm="6">
            <Form.Control
              type="text"
              required
              value={firstName.state}
              placeholder="firstname"
              style={{ borderColor: firstNameError.state ? "red" : "" }}
              onChange={(e) => firstName.update(e.target.value)}
            />
          </Col>
          <Col sm="6">
            <Form.Control
              type="text"
              value={lastName.state}
              placeholder="lastname"
              style={{ borderColor: lastNameError.state ? "red" : "" }}
              onChange={(e) => lastName.update(e.target.value)}
            />
          </Col>
        </Form.Group>
      </td>

      <td>
        <Form.Group as={Row} controlId="formPlaintextUserName">
          <Col sm="9">
            <Form.Control
              type="text"
              value={userName.state}
              placeholder="Username"
              style={{
                borderColor: userNameError.state ? "red" : "",
              }}
              disabled={isInsertEvent ? false : true}
              onChange={(e) => {
                userNameError.update(false);
                userName.update(e.target.value);
              }}
              onBlur={(e) => {
                if (isUserNameAlreadyExist(e.target.value)) {
                  props.toastMessage('Username already exist.','error');
                  isUserNameExist.update(true)
                  userNameError.update(true);
                }
                else{
                  isUserNameExist.update(false);
                }
              }}
            />
          </Col>
        </Form.Group>
      </td>

      <td>
        <Form.Group as={Row} controlId="formPlaintextEmail">
          <Col sm="9">
            <Form.Control
              type="text"
              value={emailId.state}
              style={{ borderColor: emailError.state ? "red" : "" }}
              placeholder="example@mail.com"
              onChange={(e) => emailId.update(e.target.value)}
            />
          </Col>
        </Form.Group>
      </td>

      <td>
        <Form.Group as={Row} controlId="formPlaintextPhoneNumber">
          <Col sm="9">
            <Form.Control
              type="text"
              value={phoneNumber.state}
              placeholder="999 999 9999"
              style={{
                borderColor: phoneNumberError.state ? "red" : "",
              }}
              onChange={(e) => {
                phoneNumberError.update(false);
                if (e.target.value.length <= 10 && !isNaN(+e.target.value)) {
                  phoneNumber.update(e.target.value);
                }
              }}
            />
          </Col>
        </Form.Group>
      </td>

      <td>
        <div className="active-btn-and-checkbox-cell">
          <Form.Group as={Row} controlId="formPlaintextIsactive">
            <Form.Check
              checked={
                props.event === "insert" ? true : isActive.state ? true : false
              }
              label=""
              onChange={(e) => isActive.update(e.target.checked)}
            />
          </Form.Group>
          <label className="label">Active</label>
        </div>
      </td>

      <td>
        <div className="button-cell">
          <Image
            className="buttons"
            style={{ cursor: "pointer",display: isInsertEvent ? isUserNameExist.state ? 'none' : 'block' : 'block'}}
            src={tick}
            onClick={(e) => {
              upsertUser(e);
            }}
          />
          <Image
            className="buttons"
            style={{ cursor: "pointer" }}
            src={cancel}
            onClick={props.onCancelNewUser}
          />
        </div>
      </td>
    </React.Fragment>
  );
}
function useFormUpdate(initialState) {
  const [state, setstate] = useState(initialState);

  function update(value) {
    setstate(value);
  }
  return {
    state,
    update,
  };
}
export default AddNewUser;
